import {
  TagsActionType
} from "./TagsActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getTags,
  getTagAssociation,
  removeTag
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

export const tagsFetchEpic = createEpic(
  TagsActionType.FETCH_TAGS,
  ErrorType.INLINE,
  () => getTags(apiConfig)
);

export const tagsAssociationFetchEpic = createEpic(
  TagsActionType.FETCH_TAG_ASSOCIATED_PODCAST,
  ErrorType.INLINE,
  (action) => getTagAssociation(action.tag, apiConfig)
);

export const removeTagEpic = createEpic(
  TagsActionType.REMOVE_TAG,
  ErrorType.INLINE,
  (action) => removeTag(action.name, apiConfig)
)