import { map } from "rxjs/operators";
import { getUser } from "@rewindit/data";
import { UserSessionInvalid } from "./UserSessionInvalid";
export const getToken = () => {
    return getUser().pipe(map((userEntity) => {
        if (userEntity) {
            return userEntity.token;
        }
        else {
            throw new UserSessionInvalid();
        }
    }));
};
export const getUserId = () => {
    return getUser().pipe(map((userEntity) => {
        if (userEntity) {
            return userEntity.id;
        }
        else {
            throw new UserSessionInvalid();
        }
    }));
};
