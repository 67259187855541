import React from "react";
import {
  Typography,
  WithWidth,
  isWidthDown,
  withWidth
} from "@material-ui/core";
import {
  Route
} from "react-router-dom";
import {
  Breakpoint
} from "@material-ui/core/styles/createBreakpoints";
import {
  PrimaryButton
} from "../../../uikit/button/PrimaryButton";

const variant = (width: Breakpoint) => {
  return isWidthDown("md", width) ? "subtitle1" : "h6";
};

interface SectionContextViewProps {
  navigateToAddSchedule: () => void;
  navigateToAddPlaylist: () => void;
  navigateToAddArtist: () => void;
  navigateToAddCategory: () => void;
  navigateToUploadCoverImage: () => void;
  navigateToUploadAudio: () => void;
}

const SectionContextView = (props: WithWidth & SectionContextViewProps) => (
  <Typography variant={variant(props.width)}>
    <Route exact path={"/schedule"}>
      <PrimaryButton onClick={props.navigateToAddSchedule}>
        Add Schedule
      </PrimaryButton>
    </Route>
    <Route exact path={"/playlists"}>
      <PrimaryButton onClick={props.navigateToAddPlaylist}>
        Add Playlist
      </PrimaryButton>
    </Route>
    <Route exact path={"/artists"}>
      <PrimaryButton onClick={props.navigateToAddArtist}>
        Add Host
      </PrimaryButton>
    </Route>
    <Route exact path={"/categories"}>
      <PrimaryButton onClick={props.navigateToAddCategory}>
        Add Category
      </PrimaryButton>
    </Route>
  </Typography>
);

export default withWidth()(SectionContextView);
