import {
  Optional
} from "./Optional";

export default function unwrapValue<T>(
  use: (value: T) => Optional<JSX.Element>,
  value: Optional<T>
) {
  if (value == null) return undefined;
  return use(value);
}
