import {
  connect
} from "react-redux";
import {
  AppRootComponent
} from "./AppRootComponent";
import {
  AppRootActionType
} from "./model/AppRootActions";
import {
  RootState
} from "../rootReducer";
import {
  Dispatch
} from "react";
import {
  AnyAction
} from "redux";
import {
  push
} from "connected-react-router";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.appRoot
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    closeErrorDialog: () => {
      dispatch({
        type: AppRootActionType.CLOSE_ERROR_DIALOG
      });
    },
    closeSessionExpiredDialog: () => {
      dispatch(push("/"));
      dispatch({
        type: AppRootActionType.CLEAR_SESSION
      });
    },
    navigateToUnauthenticated: () => {
      dispatch(push("/"));
      dispatch({
        type: AppRootActionType.CLEAR_SESSION
      });
    },
    navigateToSchedule: () => {
      dispatch(push("/schedule"));
    },
    navigateToAddSchedule: () => {
      dispatch(push("/schedule/add"));
    },
    navigateToAddPlaylist: () => {
      dispatch(push("/playlists/add"));
    },
    navigateToAddNews: () => {
      dispatch(push("/news/add"));
    },
    navigateToAddArtist: () => {
      dispatch(push("/artists/add"));
    },
    navigateToAddCategory: () => {
      dispatch(push("/categories/add"));
    },
    navigateToUploadCoverImage: () => {
      dispatch(push("/assets/upload/cover"));
    },
    navigateToUploadAudio: () => {
      dispatch(push("/assets/upload/audio"));
    },
    closeAdminRequiredDialog: () => {
      dispatch({
        type: AppRootActionType.CLOSE_ADMIN_REQUIRED_DIALOG
      });
    },
    menuScheduleSelected: () => {
      dispatch(push("/schedule"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuPlaylistsSelected: () => {
      dispatch(push("/playlists"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuNewsSelected: () => {
      dispatch(push("/news"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuArtistsSelected: () => {
      dispatch(push("/artists"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuCategoriesSelected: () => {
      dispatch(push("/categories"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuCommentSelected: () => {
      dispatch(push("/comment"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuTagsSelected: () => {
      dispatch(push("/tags"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuCommentsSelected: () => {
      dispatch(push("/comments"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuUsersSelected: () => {
      dispatch(push("/users"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuAssetsSelected: () => {
      dispatch(push("/assets"));
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    menuLogoutSelected: () => {
      dispatch({
        type: AppRootActionType.LOGOUT
      });
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    openMenu: () => {
      dispatch({
        type: AppRootActionType.SHOW_MENU_DIALOG
      });
    },
    closeMenu: () => {
      dispatch({
        type: AppRootActionType.CLOSE_MENU_DIALOG
      });
    },
    navigateToRoot: () => {
      dispatch(push("/"));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRootComponent);
