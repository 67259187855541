enum ArtistsActionType {
  FETCH_ARTISTS = "FETCH_ARTISTS",
  FETCH_ARTISTS_FOR_TAGS = "FETCH_ARTISTS_FOR_TAGS",
  FETCH_ARTIST_COVER_IMAGES = "FETCH_ARTIST_COVER_IMAGES",
  FETCH_ARTIST_SIGNING_URL = "FETCH_ARTIST_SIGNING_URL",
  CREATE_ARTIST = "CREATE_ARTIST",
  UPDATE_ARTIST = "UPDATE_ARTIST",
}

export {
  ArtistsActionType
};
