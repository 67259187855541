import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError
} from "../../../../shared/Actions";
import {
  UsersActionType
} from "./UsersActions";
import {
  User
} from "@rewindit/domain";

export interface UsersState {
  users: Optional<User[]>;
  count: string;
  isLoading: boolean;
  error: Optional<string>;
  isLoadingMore: boolean;
}

const initialState: UsersState = {
  users: undefined,
  count: "-",
  isLoading: false,
  error: undefined,
  isLoadingMore: false
};

const usersReducer = (
  state: UsersState = initialState,
  action: AnyAction
): UsersState => {
  switch (action.type) {

  case asStarted(UsersActionType.FETCH_INITIAL_USERS):
    return startLoading(state, action.type);
  case asSuccess(UsersActionType.FETCH_INITIAL_USERS):
    return stopLoading(
      withUsers(withCount(state, action.result.entity.count),
        action.result.entity.users
      )
    );
  case asError(UsersActionType.FETCH_INITIAL_USERS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(UsersActionType.FETCH_USERS):
    return startLoading(state, action.type);
  case asSuccess(UsersActionType.FETCH_USERS):
    return stopLoading(withUsers(state, action.result.entity));
  case asError(UsersActionType.FETCH_USERS):
    return stopLoading(withError(state, action.result.error));

  default:
    return {
      ...state
    };
  }
};

const withUsers = (state: UsersState, users: User[]) => {
  return state.users != null
    ? {
      ...state,
      users: state.users.concat(users)
    }
    : {
      ...state,
      users: users
    };
};

const withCount = (state: UsersState, count: string) => {
  return {
    ...state,
    count: count
  }
}

export {
  usersReducer
};
