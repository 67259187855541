import {
  Observable, of
} from "rxjs";
import {
  AnyAction
} from "redux";
import {
  DomainResponse,
  DomainResponseType,
  DomainErrorValue,
  DomainErrorType
} from "@rewindit/domain";

export enum ErrorType {
  INLINE,
  MODAL,
}

export const startedAction = (actionType: string): AnyAction => {
  return {
    type: asStarted(actionType)
  };
};

export function responseAction<T>(
  actionType: string,
  response: DomainResponse<T>,
  errorType: ErrorType
) {
  switch (response.type) {
  case DomainResponseType.success:
    return {
      type: asSuccess(actionType),
      result: response.value()
    };
  case DomainResponseType.error:
    if (
      response.error != null &&
        response.error.type === DomainErrorType.sessionExpired
    ) {
      return {
        type: asSessionExpired(actionType)
      };
    } else {
      switch (errorType) {
      case ErrorType.MODAL:
        return {
          type: asModalError(actionType),
          result: response.value()
        };
      case ErrorType.INLINE:
      default:
        return {
          type: asError(actionType),
          result: response.value()
        };
      }
    }
  }
}

export const genericErrorAction = (
  actionType: string,
  errorType: ErrorType
): Observable<AnyAction> => {
  return of({
    type: errorType === ErrorType.INLINE ? asError(actionType) : asModalError(actionType),
    result: {
      error: {
        body: "Please check your internet connection and try again."
      }
    }
  });
};

export const asStarted = (prefix: string): string => {
  return `${prefix.toUpperCase()}_STARTED`;
};

export const asSuccess = (prefix: string): string => {
  return `${prefix.toUpperCase()}_SUCCESS`;
};

export const asError = (prefix: string): string => {
  return `${prefix.toUpperCase()}_ERROR`;
};

export const asModalError = (prefix: string): string => {
  return `${prefix.toUpperCase()}_MODAL_ERROR`;
};

export const asSessionExpired = (prefix: string): string => {
  return `${prefix.toUpperCase()}_SESSION_EXPIRED`;
};

export const asAdminRequired = (prefix: string) => {
  return `${prefix.toUpperCase()}_ADMIN_REQUIRED`;
};

export function startLoading<T>(state: T, skip?: number): T {
  return skip === undefined
    ? {
      ...state, isLoading: true, error: undefined
    }
    : {
      ...state, isLoadingMore: true, error: undefined
    };
}


export function stopLoading<T>(state: T): T {
  return {
    ...state, isLoading: false
  };
}

export function withError<T>(state: T, error: DomainErrorValue): T {
  return {
    ...state, error: error.body
  };
}

export function withDeleteError<T>(state: T, error: DomainErrorValue): T {
  return {
    ...state, deleteError: error.body
  };
}

export function withErrorModal<T>(state: T, error: DomainErrorValue): T {
  return {
    ...state, errorModal: error.body
  };
}

export function clearError<T>(state: T): T {
  return {
    ...state, error: undefined
  };
}

export function withSuccess<T>(state: T, success: string): T {
  return {
    ...state, success: success
  };
}

export function startFormLoading<T>(state: T): T {
  return {
    ...state,
    isFormLoading: true,
    isFormError: undefined,
    formUpdated: false
  };
}

export function stopFormLoading<T>(state: T): T {
  return {
    ...state,
    isFormLoading: false
  };
}

export function withFormUpdated<T>(state: T): T {
  return {
    ...state,
    formUpdated: true
  };
}

export function withClearForm<T>(state: T): T {
  return {
    ...state,
    formUpdated: false,
    isFormLoading: false,
    isFormError: undefined
  };
}

export function startLoadingMore<T>(state: T): T {
  return {
    ...state,
    isLoadingMore: true
  };
}

export function stopLoadingMore<T>(state: T): T {
  return {
    ...state,
    isLoadingMore: false
  };
}

export function startLoadingId<T>(state: T, id: string): T {
  return {
    ...state,
    loadingId: id
  };
}

export function startDeleteLoading<T>(state: T): T {
  return {
    ...state,
    isDeleteLoading: true
  }
}

export function stopDeleteLoading<T>(state: T): T {
  return {
    ...state,
    isDeleteLoading: false
  }
}

export function stopLoadingId<T>(state: T): T {
  return {
    ...state,
    loadingId: undefined
  };
}

export function withScrollPosition<T>(state: T, scrollPosition: number): T {
  return {
    ...state,
    scrollPosition: scrollPosition
  };
}

export function resetScrollPosition<T>(state: T): T {
  return {
    ...state,
    scrollPosition: 0
  };
}