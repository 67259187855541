import React from "react";
import {
  HasChildren
} from "../../../shared/Props";
import {
  Grid,
  Toolbar,
  Hidden,
  IconButton,
  Icon,
  WithWidth,
  withWidth
} from "@material-ui/core";
import LeftDivMinor from "../../../uikit/div/LeftDivMinor";
import RightDivFill from "../../../uikit/div/RightDivFull";
import RootMenu from "./RootMenu";
import {
  ContentDiv
} from "../../../uikit/div/ContentDiv";
import {
  Selected
} from "../../../shared/Events";
import {
  LogoButton
} from "../../../uikit/button/LogoButton";
import AppMenuProps from "./AppMenuProps";
import TitleView from "./TitleView";
import SectionContextView from "./SectionContextView";

interface DashboardViewProps {
  openMenu: Selected;
  navigateToAddSchedule: () => void;
  navigateToAddPlaylist: () => void;
  navigateToAddArtist: () => void;
  navigateToAddCategory: () => void;
  navigateToUploadCoverImage: () => void;
  navigateToUploadAudio: () => void;
}

const DashboardView = (
  props: DashboardViewProps & AppMenuProps & HasChildren & WithWidth
) => (
  <div style={{
    position: "absolute", width: "100%"
  }}>
    <Hidden smDown>
      <div style={{
        backgroundColor: "#2F444D"
      }}>
        <LogoButton />
      </div>
    </Hidden>
    <Grid container justify="space-between">
      <Hidden smDown>
        <LeftDivMinor>
          <RootMenu {...props} />
        </LeftDivMinor>
      </Hidden>
      <RightDivFill>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.openMenu}
            >
              <Icon>menu</Icon>
            </IconButton>
          </Hidden>
          <Grid container justify="space-between" alignItems="center">
            <Grid>
              <TitleView {...props} />
            </Grid>
            <Grid>
              <SectionContextView {...props} />
            </Grid>
          </Grid>
        </Toolbar>
        <ContentDiv>{props.children}</ContentDiv>
      </RightDivFill>
    </Grid>
  </div>
);

export default withWidth()(DashboardView);
