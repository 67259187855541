import React, {
  useState
  ,
  useEffect
} from "react";
import {
  Optional
} from "../../../../shared/Optional";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../../shared/UnwrapValue";
import {
  ListItem,
  ListItemText,
  withWidth,
  WithWidth,
  isWidthDown
} from "@material-ui/core";
import {
  Asset
} from "@rewindit/domain";

import {
  FixedSizeList as List
} from "react-window";
import {
  Breakpoint
} from "@material-ui/core/styles/createBreakpoints";
import {
  InfoDiv
} from "../../../../uikit/div/InfoDiv";

interface AudioFileGridViewProps {
  groupName: string;
  assets: Optional<Asset[]>;
  error: Optional<string>;
  isLoading: boolean;
  itemSelected: (asset: Asset) => void;
  loadAssets?: (name: string) => void;
}

interface RowData {
  itemSelected: (asset: Asset) => void;
  items: Asset[];
}

interface RowProps {
  data: RowData;
  index: number;
  style: React.CSSProperties
}

const Row = (props: RowProps) => {
  return (
    <ListItem
      style={props.style}
      button
      key={props.data.items[props.index].url}
      onClick={() => {
        props.data.itemSelected(props.data.items[props.index]);
      }}
    >
      <ListItemText primary={decodeURIComponent(props.data.items[props.index].name)} />
    </ListItem>
  );
};

const widthValue = (width: Breakpoint) => {
  if (isWidthDown("sm", width)) {
    return 580;
  } else if (isWidthDown("md", width)) {
    return 672;
  }
  return 820;
};

const AudioFileListView = (props: AudioFileGridViewProps & WithWidth) => {
  const {
    assets, loadAssets, groupName
  } = props;

  const [currentGroupName, setCurrentGroupName] = useState<Optional<string>>(undefined);

  useEffect(() => {
    if (assets === undefined || groupName !== currentGroupName) {
      if (loadAssets !== undefined) {
        loadAssets(groupName);
      }
      setCurrentGroupName(groupName);
    }
  }, [assets, currentGroupName, loadAssets, setCurrentGroupName, groupName]);
  return (
    <ProgressOrError
      isLoading={props.isLoading}
      error={props.error}
      retryClicked={() => {
        if (currentGroupName !== undefined && props.loadAssets !== undefined) {
          props.loadAssets(currentGroupName);
        }
      }}
    >
      {unwrapValue((assets) => {
        if (assets.length === 0)
          return (
            <div style={{
              marginTop: 16
            }}>
              <InfoDiv
                visible={true}
                title="Empty folder"
                body="We could not find any audio files for this artist, use the Upload tab to add content."
              />
            </div>
          );
        const rowData: RowData = {
          itemSelected: props.itemSelected,
          items: assets
        };
        return (
          <List
            height={420}
            itemCount={assets.length}
            itemSize={35}
            itemData={rowData}
            width={widthValue(props.width)}
          >
            {Row}
          </List>
        );
      }, props.assets)}
    </ProgressOrError>
  );
};

export default withWidth()(AudioFileListView);
