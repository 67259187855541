import { DomainError } from "./DomainError";
export var DomainResponseType;
(function (DomainResponseType) {
    DomainResponseType[DomainResponseType["success"] = 0] = "success";
    DomainResponseType[DomainResponseType["error"] = 1] = "error";
})(DomainResponseType || (DomainResponseType = {}));
export class DomainResponse {
    constructor(type, entity, error) {
        this.type = type;
        this.entity = entity;
        this.error = error;
    }
    static createSuccess(entity) {
        return new DomainResponse(DomainResponseType.success, entity, undefined);
    }
    static createSuccessEmpty() {
        return new DomainResponse(DomainResponseType.success, undefined, undefined);
    }
    static createError(error) {
        return new DomainResponse(DomainResponseType.error, undefined, DomainError.parse(error));
    }
    static createFatalError() {
        return new DomainResponse(DomainResponseType.error, undefined, DomainError.parse(DomainError.fatal()));
    }
    static fromError(domainError) {
        return new DomainResponse(DomainResponseType.error, undefined, domainError);
    }
    isSuccessful() {
        return this.type === DomainResponseType.success;
    }
    value() {
        switch (this.type) {
            case DomainResponseType.success:
                if (this.entity === undefined)
                    return {
                        error: DomainError.parse(DomainError.fatal())
                    };
                return {
                    entity: this.entity
                };
            case DomainResponseType.error:
                return {
                    error: this.error
                };
            default:
                throw new Error("Unsupported error");
        }
    }
}
