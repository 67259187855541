import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@material-ui/core";
import {
  SelectInputProps
} from "@material-ui/core/Select/SelectInput";

interface DropdownProps<T extends string | string[] | number> {
  label: string;
  options: DropdownOption<T>[];
  value?: T;
  name?: string;
  onValueChanged?: (value: T) => void;
  onChange?: SelectInputProps["onChange"];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface DropdownOption<T extends string | string[] | number> {
  value: T;
  label: string;
}

function DropdownView<T extends string | string[] | number>(props: DropdownProps<T>) {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel.current != null) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);
  return (
    <FormControl variant="outlined" fullWidth color="secondary">
      <InputLabel shrink ref={inputLabel} id={props.label}>
        {props.required ? props.label + " *" : props.label}
      </InputLabel>
      <Select
        labelId={props.label}
        name={props.name}
        value={props.value ?? ""}
        onChange={(event, child) => {
          if (props.onChange !== undefined)
            props.onChange(event, child)
          if (props.onValueChanged !== undefined)
            props.onValueChanged(event.target.value as T)
        }}
        disabled={props.disabled}
        required={props.required}
        labelWidth={labelWidth}
        input={<OutlinedInput notched labelWidth={labelWidth} />}
      >
        <MenuItem value={""}>{props.placeholder ?? "-"}</MenuItem>
        {props.options.map((it) => (
          <MenuItem key={it.label} value={it.value}>
            {it.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownView;
