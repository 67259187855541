import React from "react";
import ContentDialog from "../../../uikit/dialog/ContentDialog";
import {
  PrimaryButton
} from "../../../uikit/button/PrimaryButton";
import {
  DialogActions, Typography
} from "@material-ui/core";

interface SessionExpiredViewProps {
  showSessionExpiredModal: boolean;
  closeSessionExpiredDialog: () => void;
  navigateToUnauthenticated: () => void;
}

const SessionExpiredView = (props: SessionExpiredViewProps) => {
  return showDialog(
    () => (
      <ContentDialog
        isOpen={true}
        title="Account Required"
        onClose={props.closeSessionExpiredDialog}
      >
        <Typography variant="body1">
          You need an account to perform this action, either login or create an
          account to continue.
        </Typography>
        <DialogActions>
          <PrimaryButton
            onClick={() => {
              props.navigateToUnauthenticated();
            }}
          >
            LOGIN / CREATE ACCOUNT
          </PrimaryButton>
        </DialogActions>
      </ContentDialog>
    ),
    props.showSessionExpiredModal
  );
};

const showDialog = (view: () => JSX.Element, showSessionExpiredModal: boolean): JSX.Element | null => {
  if (showSessionExpiredModal) {
    return view();
  } else {
    return null;
  }
};

export {
  SessionExpiredView
};
