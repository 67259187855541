import Mapper from "../../Mapper";
import PodcastMapper from "../../podcast/mapper/PodcastMapper";
import CategoryMapper from "../../category/mapper/CategoryMapper";
import UpcomingMapper from "../../podcast/mapper/UpcomingMapper";
export default class HomeMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
        this.categoryMapper = new CategoryMapper();
        this.upcomingMapper = new UpcomingMapper();
    }
    from(entity) {
        return {
            live: this.podcastUnwrap(entity.live.live),
            categories: entity.categories.categories.map((categoryJson) => {
                return this.categoryMapper.from(categoryJson);
            }),
            released: entity.released.podcasts.map((podcastJson) => {
                return this.podcastMapper.from(podcastJson);
            }),
            upcoming: this.upcomingMapper.from(entity.upcoming),
            userSession: undefined
        };
    }
    podcastUnwrap(podcastJson) {
        if (podcastJson != null) {
            return this.podcastMapper.from(podcastJson);
        }
        else {
            return undefined;
        }
    }
}
