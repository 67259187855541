import Mapper from "../../../Mapper";
import ArtistMapper from "../../../artist/mapper/ArtistMapper";
import CategoryMapper from "../../../category/mapper/CategoryMapper";
export default class SubscriptionContentMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.artistMapper = new ArtistMapper();
        this.categoryMapper = new CategoryMapper();
    }
    from(entities) {
        return entities.subscriptions.map((entity) => {
            return {
                id: entity.subscriptionId,
                artist: entity.artist ? this.artistMapper.from(entity.artist) : undefined,
                category: entity.category ? this.categoryMapper.from(entity.category) : undefined
            };
        });
    }
}
