import {
  connect
} from "react-redux";
import ScheduleComponent, {
  SchedulePath
} from "./ScheduleComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  ScheduleActionType
} from "./model/ScheduleActions";
import {
  push
} from "connected-react-router";
import {
  AppRootActionType
} from "../../root/model/AppRootActions";
import {
  ScheduleFormValues, ScheduleWeeklyFormValues
} from "@rewindit/domain";
import {
  AssetType
} from "../assets/model/AssetType";
import {
  Optional
} from "../../..//shared/Optional";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.schedule
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadPlaylists: () => {
      dispatch({
        type: ScheduleActionType.FETCH_INITIAL_PLAYLISTS
      });
    },
    loadPodcast: (id: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_PODCAST_BY_ID,
        id: id
      });
    },
    loadPodcasts: () => {
      dispatch({
        type: ScheduleActionType.FETCH_INITIAL_SCHEDULE
      });
    },
    loadMore: (skip: number) => {
      dispatch({
        type: ScheduleActionType.FETCH_PODCASTS,
        skip: skip
      });
    },
    loadMoreArtistId: (artistId: string, skip: number) => {
      dispatch({
        type: ScheduleActionType.FETCH_PODCASTS_BY_ARTIST_MORE,
        artistId: artistId,
        skip: skip
      });
    },
    podcastSelected: (id: string, path: SchedulePath) => {
      dispatch({
        type: AppRootActionType.CLEAR_FORM
      });
      dispatch(push(`/${path}/edit/${id}`));
    },
    loadAudioFiles: (name: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES,
        name: name
      });
    },
    loadCoverImages: (name: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES,
        name: name
      });
    },
    loadSigningUrl: (name: string, assetType: AssetType) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL,
        name: name,
        assetType: assetType
      });
    },
    deleteSchedule: (id: string) => {
      dispatch({
        type: ScheduleActionType.DELETE_SCHEDULE,
        id: id
      });
    },
    reloadPodcasts: () => {
      dispatch(push("/schedule/"));
    },
    reloadPlaylists: () => {
      dispatch(push("/playlists/"));
      dispatch({
        type: ScheduleActionType.FETCH_INITIAL_PLAYLISTS
      });
    },
    createSchedule: (values: ScheduleFormValues) => {
      dispatch({
        type: ScheduleActionType.CREATE_SCHEDULE,
        values: values
      });
    },
    updateSchedule: (id: string, values: ScheduleFormValues) => {
      dispatch({
        type: ScheduleActionType.UPDATE_SCHEDULE,
        id: id,
        values: values
      });
    },
    createWeeklySchedule: (values: ScheduleWeeklyFormValues) => {
      dispatch({
        type: ScheduleActionType.CREATE_WEEKLY_SCHEDULE,
        values: values
      });
    },
    updateWeeklySchedule: (id: string, values: ScheduleWeeklyFormValues) => {
      dispatch({
        id: id,
        type: ScheduleActionType.UPDATE_WEEKLY_SCHEDULE,
        values: values
      });
    },
    setScrollPosition: (position: number) => {
      dispatch({
        type: ScheduleActionType.SET_SCROLL_POSITION,
        position: position
      });
    },
    loadPodcastsByArtist: (artistId: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_PODCASTS_BY_ARTIST,
        artistId: artistId
      });
    },
    searchByTitle: (terms: string) => {
      dispatch({
        type: ScheduleActionType.SEARCH_SCHEDULE_BY_TITLE,
        terms: terms
      });
    },
    resetInitialScheduleList: () => {
      dispatch({
        type: ScheduleActionType.RESET_INITIAL_SCHEDULE_LIST
      });
    },
    setSelectedArtistId: (selectedArtistId: Optional<string>) => {
      dispatch({
        type: ScheduleActionType.SET_SELECTED_ARTIST_ID,
        selectedArtistId: selectedArtistId
      });
    },
    setSearchTerms: (searchTerms: Optional<string>) => {
      dispatch({
        type: ScheduleActionType.SET_SEARCH_TERMS,
        searchTerms: searchTerms
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleComponent);
