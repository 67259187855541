import React from "react";
import {
  FormControl, Typography
} from "@material-ui/core";
import {
  ErrorDiv
} from "../../../../uikit/div/ErrorDiv";
import {
  AppTextField
} from "../../../../uikit/text/AppTextField";
import {
  FormMarginDiv
} from "../../../../uikit/div/FormMarginDiv";
import {
  ProgressButton
} from "../../../../uikit/button/ProgressButton";
import {
  LoginSelected
} from "../../../../shared/Events";
import {
  Optional
} from "../../../../shared/Optional";
import {
  HeaderBaselineDiv
} from "../../../../uikit/div/HeaderBaselineDiv";

interface LoginFormViewProps {
  error: Optional<string>;
  isLoading: boolean;
  loginSelected: LoginSelected;
}

const LoginFormView = (props: LoginFormViewProps) => {
  const [emailAddress, setEmailAddress] = React.useState("");
  const [password, setPassword] = React.useState("");
  return (
    <>
      <HeaderBaselineDiv>
        <Typography variant="h2">Sign In</Typography>
      </HeaderBaselineDiv>
      <FormControl style={{
        width: "100%"
      }}>
        <form
          onSubmit={() => {
            props.loginSelected(emailAddress, password);
          }}
        >
          <div style={{
            height: 72
          }}>
            <ErrorDiv errorMessage={props.error} />
          </div>
          <AppTextField
            autoFocus
            id="name"
            label="E-Mail Address"
            type="email"
            onChange={(value) => setEmailAddress(value)}
          />
          <FormMarginDiv />
          <AppTextField
            id="password"
            label="Password"
            type="password"
            onChange={(value) => setPassword(value)}
          />
          <FormMarginDiv />
          <div style={{
            paddingTop: 30
          }}>
            <ProgressButton
              fullWidth
              type="submit"
              onClick={() => {
                props.loginSelected(emailAddress, password);
              }}
              isLoading={props.isLoading}
            >
              Sign In
            </ProgressButton>
          </div>
        </form>
      </FormControl>
    </>
  );
};

export default LoginFormView;
