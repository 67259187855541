import React from "react";
import {
  HasChildren
} from "../../shared/Props";

const style = {
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24
};

export const ContentDiv = (props: HasChildren) => {
  return <div style={style}>{props.children}</div>;
};
