import React, {
  useState, useEffect
} from "react";
import {
  useFormik
} from "formik";
import {
  useParams
} from "react-router";
import TextInputView from "../../../../uikit/form/TextInputView";
import FormRow from "../../../../uikit/form/FormRow";
import FormContainerView from "../../../../uikit/form/FormContainerView";
import {
  Prompt, RouteComponentProps, withRouter
} from "react-router-dom";
import {
  Asset, Category, CategoryFormValues
} from "@rewindit/domain";
import CoverImagePicker from "../../../../uikit/file/CoverImagePicker";
import {
  Optional
} from "../../../../shared/Optional";
import FormGroupView from "../../../../uikit/form/FormGroup";
import ContentDialog from "../../../../uikit/dialog/ContentDialog";
import {
  Autocomplete
} from "@material-ui/lab";
import {
  TextField
} from "@material-ui/core";
import {
  FlexOneLeftDiv,
  FlexOneRightDiv
} from "../../../../uikit/div/FlexOneDiv";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import ConfirmDialog from "../../../../uikit/dialog/ConfirmDialog";
import {
  ProgressButton
} from "../../../../uikit/button/ProgressButton";
import SuccessDiv from "../../../../uikit/div/SuccessDiv";
import AssetPickerView from "../../assets/view/AssetPickerView";
import {
  AssetType
} from "../../assets/model/AssetType";

interface CategoryFormProps extends RouteComponentProps {
  categories: Category[];
  tags: string[];
  error: Optional<string>;
  isLoading: boolean;
  isDeleteLoading: boolean;
  deleteError: Optional<string>;
  onChangeRedirect: boolean;
  isFormLoading: boolean;
  formUpdated: boolean;
  coverImageIsLoading: boolean;
  coverImages: Optional<Asset[]>;
  coverImageError: Optional<string>;
  signingUrlIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
  loadCategoryCoverImages: (name: string) => void;
  loadSigningUrl: (name: string, assetType: AssetType) => void;
  deleteCategory: (id: string) => void;
  reloadCategories: () => void;
  createCategory: (values: CategoryFormValues) => void;
  updateCategory: (id: string, values: CategoryFormValues) => void;
}

const CategoryForm = (props: CategoryFormProps) => {
  const {
    id
  } = useParams<{ id: string }>();

  const category = props.categories.find((category) => category.id === id);

  const {
    coverImages, onChangeRedirect, reloadCategories
  } = props;

  useEffect(() => {
    if (onChangeRedirect) {
      reloadCategories();
    }
  }, [onChangeRedirect, reloadCategories]);

  const [isCoverPickerOpen, setOpenCoverPicker] = useState(false);

  const [isDeleteDialogOpen, setOpenDeleteDialog] = useState(false);

  const [assetPickerTabSelected, setAssetPickerTabSelected] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: category?.name ?? "",
      description: category?.description ?? "",
      imgUrl: category?.imgUrl ?? "",
      order: category?.order ?? "",
      tags: category?.tags ?? []
    },
    onSubmit: (values) => {
      if (id === undefined) {
        props.createCategory(values);
      } else {
        props.updateCategory(id, values);
      }
    }
  });
  return (
    <>
      <Prompt
        when={formik.dirty && !formik.isSubmitting}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ContentDialog
        onClose={() => {
          setOpenCoverPicker(false);
        }}
        isOpen={isCoverPickerOpen}
        title={"Select an artist cover image"}
      >
        <div style={{
          height: 420, width: "100%"
        }}>
          <AssetPickerView
            tabSelected={assetPickerTabSelected}
            groupName="categories"
            selectedAssetType="cover"
            signingUrl={props.signingUrl}
            signingUrlLoading={props.signingUrlIsLoading}
            signingUrlError={props.signingUrlError}
            coverImageIsLoading={props.coverImageIsLoading}
            coverImageError={props.coverImageError}
            audioFiles={undefined}
            audioFilesIsLoading={false}
            audioFilesError={undefined}
            loadCoverImages={props.loadCategoryCoverImages}
            itemSelected={(asset) => {
              setOpenCoverPicker(false);
              formik.values.imgUrl = asset.url;
            }}
            loadSigningUrl={props.loadSigningUrl}
            setTabSelected={setAssetPickerTabSelected}
            coverImages={coverImages}
          />
        </div>
      </ContentDialog>
      <ConfirmDialog
        title="Delete category"
        body="Are you sure you want to delete this category?"
        isOpen={isDeleteDialogOpen}
        isLoading={props.isDeleteLoading}
        error={props.deleteError}
        handleAgree={() => {
          props.deleteCategory(id);
        }}
        handleDismiss={() => {
          setOpenDeleteDialog(false);
        }}
      />
      <SuccessDiv
        visible={props.formUpdated}
        successMessage="Category update successful"
      />
      <FormContainerView>
        <FormRow>
          <FlexOneLeftDiv>
            <TextInputView
              label="Name"
              name="name"
              value={formik.values.name}
              required={true}
              onChanged={formik.handleChange}
            />
          </FlexOneLeftDiv>
          <FlexOneRightDiv>
            <TextInputView
              label="Description"
              name="description"
              value={formik.values.description}
              required={true}
              onChanged={formik.handleChange}
            />
          </FlexOneRightDiv>
        </FormRow>
        <FormRow>
          <TextInputView
            label="Order"
            name="order"
            value={formik.values.order}
            required={true}
            onChanged={formik.handleChange}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            fullWidth
            multiple
            onChange={(_, newValue) => {
              formik.setFieldValue("tags", newValue);
            }}
            options={props.tags}
            value={formik.values.tags}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Tags"
                variant="outlined"
                color="secondary"
              />
            )}
          />
        </FormRow>
        <FormRow>
          <FormGroupView title="Cover image">
            <CoverImagePicker
              coverImage={formik.values.imgUrl}
              isCoverPickerOpen={isCoverPickerOpen}
              onOpenCoverPicker={setOpenCoverPicker}
            />
          </FormGroupView>
        </FormRow>
        <FormRow>
          <FlexOneLeftDiv>
            <ProgressButton
              fullWidth
              isLoading={props.isFormLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Submit
            </ProgressButton>
          </FlexOneLeftDiv>
          <FlexOneRightDiv hidden={id === undefined}>
            <SecondaryButton
              fullWidth
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </SecondaryButton>
          </FlexOneRightDiv>
        </FormRow>
      </FormContainerView>
    </>
  );
};

export default withRouter(CategoryForm);
