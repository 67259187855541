import React from "react";
import {
  TextField
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";

const genres = [
  "Uncategorised",
  "Electronic",
  "Minimal",
  "Trip Hop",
  "Ambient",
  "Microhouse",
  "Techno",
  "Deep Techno",
  "Tech House",
  "Drum and Bass",
  "Eclectic",
  "Jazz",
  "World",
  "Experimental",
  "Noise",
  "House",
  "Deep House",
  "Hiphop",
  "Portuguese Hiphop",
  "Grime",
  "Real Dubstep",
  "Dub",
  "Reggae",
  "Bass",
  "Downtempo",
  "Ethno Beats",
  "Lofi",
  "Dancehall",
  "Afrobeat",
  "Drum and Bass / Real Dubstep",
  "Dub / Reggae",
  "Dub / Drum and Bass",
  "Techno / Noise",
  "Techno / Drum and Bass",
  "Funk / Nudisco",
  "Beats / Instrumental",
  "Footwork / Juke",
  "House / Techno",
  "Talk Show"
];

interface GenreDropdownProps {
  name?: string;
  value?: string;
  onValueChanged: (value: string) => void;
}

const GenreDropdown = (props: GenreDropdownProps) => {
  return <Autocomplete<string>
    fullWidth
    onChange={(_event, value: string | null) => {
      if (value != null) {
        props.onValueChanged(value);
      }
    }}
    options={genres}
    value={props.value}
    getOptionLabel={(option: string) => option}
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        label="Genre"
        variant="outlined"
        color="secondary"
      />
    )}
  />
}

export default GenreDropdown