import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError
} from "../../../../shared/Actions";
import {
  ArtistsActionType
} from "../../artists/model/ArtistsActions";
import {
  ScheduleActionType
} from "../../schedule/model/ScheduleActions";
import {
  DomainErrorValue, Asset, Artist
} from "@rewindit/domain";

export interface AssetsState {
  isLoading: boolean;
  error: Optional<string>;
  artists: Optional<Artist[]>;
  signingUrl: Optional<string>;
  signingUrlLoading: boolean;
  signingUrlError: Optional<string>;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  coverImages: Optional<Asset[]>;
  audioFilesIsLoading: boolean;
  audioFilesError: Optional<string>;
  audioFiles: Optional<Asset[]>;
}

const initialState: AssetsState = {
  signingUrl: undefined,
  signingUrlLoading: false,
  signingUrlError: undefined,
  isLoading: false,
  error: undefined,
  artists: undefined,
  coverImageIsLoading: false,
  coverImageError: undefined,
  coverImages: undefined,
  audioFilesIsLoading: false,
  audioFilesError: undefined,
  audioFiles: undefined
};

const assetsReducer = (
  state: AssetsState = initialState,
  action: AnyAction
): AssetsState => {
  switch (action.type) {
  case asStarted(ArtistsActionType.FETCH_ARTISTS):
    return startLoading(state);
  case asSuccess(ArtistsActionType.FETCH_ARTISTS):
    return stopLoading(withArtists(state, action.result.entity));
  case asError(ArtistsActionType.FETCH_ARTISTS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return startSigningUrlLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return stopSigningUrlLoading(withSigningUrl(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return stopSigningUrlLoading(
      withSigningUrlError(state, action.result.error)
    );

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return startCoverImageLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImages(state, action.result.entity)
    );
  case asError(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImageError(state, action.result.error)
    );

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return startAudioFilesLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return stopAudioFilesLoading(withAudioFiles(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return stopAudioFilesLoading(
      withAudioFilesError(state, action.result.error)
    );

  default:
    return {
      ...state
    };
  }
};

const withArtists = (state: AssetsState, artists: Artist[]) => {
  return {
    ...state,
    artists: artists
  };
};

const startSigningUrlLoading = (state: AssetsState) => {
  return {
    ...state,
    signingUrlLoading: true,
    signingUrlError: undefined
  };
};

const stopSigningUrlLoading = (state: AssetsState) => {
  return {
    ...state,
    signingUrlLoading: false
  };
};

const withSigningUrlError = (state: AssetsState, error: DomainErrorValue) => {
  return {
    ...state,
    audioSigningUrlError: error.body
  };
};

const withSigningUrl = (state: AssetsState, signingUrl: string) => {
  return {
    ...state,
    signingUrl: signingUrl
  };
};

const startCoverImageLoading = (state: AssetsState) => {
  return {
    ...state,
    coverImageIsLoading: true,
    coverImageError: undefined
  };
};

const stopCoverImageLoading = (state: AssetsState) => {
  return {
    ...state,
    coverImageIsLoading: false
  };
};

const withCoverImageError = (state: AssetsState, error: DomainErrorValue) => {
  return {
    ...state,
    coverImageError: error.body
  };
};

const withCoverImages = (state: AssetsState, coverImages: Asset[]) => {
  return {
    ...state,
    coverImages: coverImages
  };
};

const startAudioFilesLoading = (state: AssetsState) => {
  return {
    ...state,
    audioFilesIsLoading: true,
    audioFilesError: undefined
  };
};

const stopAudioFilesLoading = (state: AssetsState) => {
  return {
    ...state,
    audioFilesIsLoading: false
  };
};

const withAudioFilesError = (state: AssetsState, error: DomainErrorValue) => {
  return {
    ...state,
    audioFilesError: error.body
  };
};

const withAudioFiles = (state: AssetsState, audioFiles: Asset[]) => {
  return {
    ...state,
    audioFiles: audioFiles
  };
};

export {
  assetsReducer
};
