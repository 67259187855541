import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";
import {
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import {
  Artist
} from "@rewindit/domain";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import {
  SelectedId
} from "../../../../shared/Events";
import {
  encodeCloudFrontUrl
} from "../../../../utils/CloudFront";

interface ArtistListViewProps {
  artists: Artist[];
  artistSelected: SelectedId;
}

const style: CSSProperties = {
  display: "block",
  width: 120,
  height: 120
};

const ArtistListView = (props: ArtistListViewProps) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {props.artists.map((artist) => (
          <TableRow
            key={artist.id}
            hover
            onClick={() => props.artistSelected(artist.id)}
          >
            <TableCell style={{
              width: 120
            }}>
              <img src={encodeCloudFrontUrl(artist.imgUrl, 120)} alt={artist.name} style={style} />
            </TableCell>
            <TableCell>
              <div>
                <Typography variant="h6">{artist.name}</Typography>
              </div>
              <div>
                <Typography variant="body1">{artist.description}</Typography>
              </div>
              <div style={{
                marginTop: 8
              }}>
                <SecondaryButton
                  disabled
                  extraStyle={{
                    color: "black"
                  }}
                >
                  {artist.quote}
                </SecondaryButton>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ArtistListView;
