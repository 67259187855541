import React from "react";
import {
  Optional
} from "../../../shared/Optional";
import ContentDialog from "../../../uikit/dialog/ContentDialog";
import {
  PrimaryButton
} from "../../../uikit/button/PrimaryButton";
import {
  DialogActions, Typography
} from "@material-ui/core";
import {
  ContentDiv
} from "../../../uikit/div/ContentDiv";

interface ModalErrorViewProps {
  errorModal: Optional<string>;
  closeErrorDialog: () => void;
}

const ModalErrorView = (props: ModalErrorViewProps) => {
  return errorNotNull(
    (error) => (
      <ContentDialog
        title="Oops"
        onClose={props.closeErrorDialog}
        isOpen={true}
      >
        <ContentDiv>
          <Typography variant="body1">{error}</Typography>
        </ContentDiv>
        <DialogActions>
          <PrimaryButton
            onClick={() => {
              props.closeErrorDialog();
            }}
          >
            OK
          </PrimaryButton>
        </DialogActions>
      </ContentDialog>
    ),
    props.errorModal
  );
};

const errorNotNull = (view: (error: string) => JSX.Element, error?: string): JSX.Element | null => {
  if (error != null) {
    return view(error);
  } else {
    return null;
  }
};

export {
  ModalErrorView
};
