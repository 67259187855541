import { DomainResponse } from "../../DomainResponse";
import { postJoin, postCreateUsername, insertUser } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import UserSessionMapper from "../UserSessionMapper";
import sha1 from "sha1";
import uuid from "uuid/v1";
import { DomainErrorType } from "../../DomainError";
const userSessionMapper = new UserSessionMapper();
const join = (username, emailAddress, password, domainConfig) => {
    if (password.length < 8) {
        return of(DomainResponse.fromError({
            type: DomainErrorType.message,
            body: "Your password must be at least 8 characters."
        }));
    }
    else {
        return postJoin(emailAddress, username, sha1(password), uuid(), undefined, domainConfig.baseUrl).pipe(map((api) => userSessionMapper.mapFrom(api)), mergeMap((response) => {
            const userSession = response.entity;
            if (userSession === undefined)
                return of(DomainResponse.createFatalError());
            return saveUser(userSession, response);
        }), catchError((error) => of(DomainResponse.createError(error))));
    }
};
const createUsername = (username, token, domainConfig) => {
    return postCreateUsername(username, token, domainConfig.baseUrl).pipe(map((api) => userSessionMapper.mapFrom(api)), mergeMap((response) => {
        const userSession = response.entity;
        if (userSession === undefined)
            return of(DomainResponse.createFatalError());
        return saveUser(userSession, response);
    }), catchError((error) => {
        return of(DomainResponse.createError(error));
    }));
};
const saveUser = (userSession, response) => {
    return insertUser({
        id: userSession.id,
        token: userSession.token,
        username: userSession.username,
        emailWeekly: userSession.userPushPreferences.weekly
    }).pipe(map(() => response));
};
export { join, createUsername };
