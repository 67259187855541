export var ScheduleType;
(function (ScheduleType) {
    ScheduleType["PODCAST"] = "PODCAST";
    ScheduleType["PODCAST_AFTER_REALTIME"] = "PODCAST_AFTER_REALTIME";
    ScheduleType["REALTIME"] = "REALTIME";
    ScheduleType["WEEKLY"] = "WEEKLY";
})(ScheduleType || (ScheduleType = {}));
export const isPodcastIncomplete = (podcast) => {
    if (podcast === undefined)
        return false;
    return !podcast.imgUrl;
};
export const podcastIncompleteMessage = (podcast) => {
    if (podcast === undefined)
        return "";
    if (!podcast.imgUrl)
        return "A cover image is required.";
    return "";
};
