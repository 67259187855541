import React from "react";
import {
  Optional
} from "../../shared/Optional";
import {
  encodeCloudFrontUrl
} from "../../utils/CloudFront";

import {
  SecondaryButton
} from "../button/SecondaryButton";

interface CoverImagePickerProps {
  coverImage: Optional<string>;
  isCoverPickerOpen: boolean;
  onOpenCoverPicker: (open: boolean) => void;
}

const CoverImagePicker = (props: CoverImagePickerProps) => (
  <>
    <div hidden={props.coverImage !== undefined}>
      <SecondaryButton
        onClick={() => props.onOpenCoverPicker(!props.isCoverPickerOpen)}
      >
        Select cover image
      </SecondaryButton>
    </div>
    <div hidden={props.coverImage === undefined}>
      <SecondaryButton
        onClick={() => props.onOpenCoverPicker(!props.isCoverPickerOpen)}
      >
        <img
          src={props.coverImage ? encodeCloudFrontUrl(props.coverImage, 150) : process.env.PUBLIC_URL + "/app_icon.png"}
          alt={props.coverImage}
          width={150}
          height={150}
        />
      </SecondaryButton>
    </div>
  </>
);

export default CoverImagePicker;
