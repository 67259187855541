import React from "react"
import {
  HasChildren
} from "../../shared/Props"
import {
  Typography, Grid
} from "@material-ui/core"

interface FormGroupProps {
  title: string
}

const FormGroupView = (props: FormGroupProps & HasChildren) => (
  <div style={{
    marginBottom: 16
  }}>
    <div>
      <Typography variant="subtitle1" style={{
        color: "#333333"
      }}>
        {props.title}
      </Typography>
    </div>
    <Grid direction="column" container style={{
      marginTop: 4
    }}>
      {props.children
      }</Grid>
  </div>
)

export default FormGroupView