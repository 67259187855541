import { RxStorage } from "../RxStorage";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
const storage = new RxStorage("UserEntity");
const getUser = () => {
    return storage.getItem();
};
const insertUser = (entity) => {
    return storage.insertItem(entity);
};
const updateUserPreferences = (emailWeekly) => {
    return storage.getItem().pipe(mergeMap((userEntity) => {
        if (userEntity != null) {
            return storage.removeItem().pipe(mergeMap(() => {
                return storage.insertItem({
                    id: userEntity.id,
                    token: userEntity.token,
                    username: userEntity.username,
                    emailWeekly: emailWeekly
                });
            }));
        }
        else {
            return of(void 0);
        }
    }));
};
export { getUser, insertUser, updateUserPreferences };
