import React from "react";
import {
  TagsState
} from "./model/TagsReducer";
import TagListView from "./view/TagListView";
import {
  SelectedId
} from "../../../shared/Events";
import TagAssociationView from "./view/TagAssociationView";
import {
  Route, Switch
} from "react-router-dom";

interface TagsComponentProps extends TagsState {
  tagSelected: SelectedId;
  deleteTagSelected: SelectedId;
  podcastSelected: SelectedId;
  loadTags: () => void;
  loadAssociatedPodcasts: (tag: string) => void;
  setScrollPosition: (position: number) => void;
  resetDeleteTagDialog: () => void;
}

const TagsComponent = (props: TagsComponentProps) => {
  return (
    <Switch>
      <Route path={"/tags/:tag"}>
        <TagAssociationView {...props} />
      </Route>
      <Route path={"/"}>
        <TagListView {...props} />
      </Route>
    </Switch>
  );
};

export default TagsComponent;
