import { DomainResponse } from "./DomainResponse";
import { DomainError } from "./DomainError";
export default class Mapper {
    mapFrom(response) {
        if (response.isSuccessful()) {
            if (response.data != null) {
                return DomainResponse.createSuccess(this.from(response.data));
            }
            else {
                return DomainResponse.createSuccessEmpty();
            }
        }
        else if (response.statusCode === 401) {
            throw DomainError.sessionExpired();
        }
        else if (response.statusCode === 400) {
            throw this.mapError(response.error);
        }
        else if (response.statusCode === -111) {
            throw DomainError.networkError();
        }
        else {
            throw DomainError.fatal();
        }
    }
    mapError(apiError) {
        if (apiError != null) {
            return DomainError.message(apiError.error, apiError.action);
        }
        else {
            return DomainError.message("Something went wrong, please contact support if this continues to occur.");
        }
    }
}
