import { DomainResponse } from "../../DomainResponse";
import { of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import SubscriptionMapper from "./mapper/SubscriptionMapper";
import { getUserSubscriptions, insertUserSubscriptions, deleteUserSubscription, fetchSubscriptionContent, subscribe, unsubscribe } from "@rewindit/data";
import { getToken } from "../UserSessionRepository";
import VoidMapper from "../../VoidMapper";
import SubscriptionContentMapper from "./mapper/SubscriptionContentMapper";
const subscriptionMapper = new SubscriptionMapper();
const subscriptionContentMapper = new SubscriptionContentMapper();
const voidMapper = new VoidMapper();
const getSubscriptions = () => {
    return getUserSubscriptions().pipe(map((subscriptionEntities) => DomainResponse.createSuccess(subscriptionEntities.map((entity) => subscriptionMapper.fromLocal(entity)))));
};
const getSubscriptionContent = (domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchSubscriptionContent(token, domainConfig.baseUrl).pipe(map((subscriptionContent) => subscriptionContentMapper.mapFrom(subscriptionContent)))));
};
const subscribeToArtist = (contentId, domainConfig) => {
    return subscribeTo(contentId, "ARTIST", domainConfig);
};
const subscribeToCategory = (contentId, domainConfig) => {
    return subscribeTo(contentId, "CATEGORY", domainConfig);
};
const subscribeTo = (contentId, type, domainConfig) => {
    return getToken().pipe(mergeMap((token) => subscribe(contentId, type, token, domainConfig.baseUrl)), map((api) => subscriptionMapper.mapFrom(api)), mergeMap((response) => {
        const subscription = response.entity;
        if (subscription === undefined)
            return of(DomainResponse.createFatalError());
        return insertUserSubscriptions([
            {
                id: subscription.id,
                type: subscription.type,
                contentId: subscription.contentId,
                subscriptionTitle: subscription.title,
                dateCreated: subscription.dateCreated
            }
        ]).pipe(map(() => response));
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const unsubscribeFrom = (contentId, domainConfig) => {
    return getToken().pipe(mergeMap((token) => unsubscribe(contentId, token, domainConfig.baseUrl)), map((api) => voidMapper.mapFrom(api)), mergeMap(() => deleteUserSubscription(contentId)), map(() => DomainResponse.createSuccess(void 0)), catchError((error) => {
        const errorResponse = DomainResponse.createError(error);
        const errorObj = errorResponse.error;
        if (errorObj != null && errorObj.action === "ALREADY_UNSUBSCRIBED") {
            return deleteUserSubscription(contentId).pipe(map(() => DomainResponse.createError(error)));
        }
        else {
            return of(DomainResponse.createError(error));
        }
    }));
};
export { getSubscriptions, getSubscriptionContent, subscribeToArtist, subscribeToCategory, unsubscribeFrom };
