import {
  LoginActionType
} from "./LoginActions";
import {
  AnyAction
} from "redux";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError,
  clearError,
  asAdminRequired
} from "../../../../shared/Actions";
import {
  UserSession
} from "@rewindit/domain";
import {
  Optional
} from "../../../../shared/Optional";
import {
  AppRootActionType
} from "../../../root/model/AppRootActions";

export interface LoginState {
  userSession: Optional<UserSession>;
  error: Optional<string>;
  isLoading: boolean;
  redirect: boolean;
}

const initialState: LoginState = {
  userSession: undefined,
  error: undefined,
  isLoading: false,
  redirect: false
};

const loginReducer = (
  state: LoginState = initialState,
  action: AnyAction
): LoginState => {
  switch (action.type) {
  case asStarted(LoginActionType.POST_LOGIN):
    return startLoading(state);
  case asSuccess(LoginActionType.POST_LOGIN):
    return stopLoading(clearError(loginSuccess(state, action.result.entity)));
  case asError(LoginActionType.POST_LOGIN):
    return stopLoading(withError(state, action.result.error));

  case asAdminRequired(LoginActionType.POST_LOGIN):
    return stopLoading(state);

  case AppRootActionType.CLEAR_SESSION:
    return clearUserSession(state);

  case AppRootActionType.USER_AUTHENTICATED:
    return clearRedirect(state);

  default:
    return {
      ...state
    };
  }
};

const loginSuccess = (state: LoginState, userSession: UserSession) => {
  return {
    ...state, userSession: userSession, redirect: true
  };
};

const clearUserSession = (state: LoginState) => {
  return {
    ...state, userSession: undefined
  };
};

const clearRedirect = (state: LoginState) => {
  return {
    ...state, redirect: false
  };
};

export {
  loginReducer
};
