import React from "react";
import {
  Artist
} from "@rewindit/domain";
import {
  Autocomplete
} from "@material-ui/lab";
import {
  TextField
} from "@material-ui/core";

interface ArtistDropdownProps {
  artists: Artist[];
  name: string;
  value?: Artist;
  onValueChanged: (value: Artist) => void;
}

const ArtistDropdown = (props: ArtistDropdownProps) => (
  <Autocomplete<Artist>
    fullWidth
    onChange={(_event, value: Artist | null) => {
      if (value != null) {
        props.onValueChanged(value);
      }
    }}
    options={props.artists}
    value={props.value}
    getOptionLabel={(option: Artist) => option.name}
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        label="Artist"
        variant="outlined"
        color="secondary"
      />
    )}
  />
);

export default ArtistDropdown;
