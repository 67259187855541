import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError,
  withClearForm,
  startFormLoading,
  stopFormLoading,
  asModalError,
  withFormUpdated
} from "../../../../shared/Actions";
import {
  ArtistsActionType
} from "./ArtistsActions";
import {
  DomainErrorValue, Asset, Artist, ArtistParent
} from "@rewindit/domain";
import {
  AppRootActionType
} from "../../../root/model/AppRootActions";

export interface ArtistsState {
  artists: Optional<Artist[]>;
  tags: string[];
  isLoading: boolean;
  error: Optional<string>;
  isFormLoading: boolean;
  formUpdated: boolean;
  onChangeRedirect: boolean;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  coverImages: Optional<Asset[]>;
  signingUrlIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
}

const initialState: ArtistsState = {
  artists: undefined,
  tags: [],
  isLoading: false,
  error: undefined,
  isFormLoading: false,
  formUpdated: false,
  onChangeRedirect: false,
  coverImageIsLoading: false,
  coverImageError: undefined,
  coverImages: undefined,
  signingUrlIsLoading: false,
  signingUrlError: undefined,
  signingUrl: undefined
};

const artistsReducer = (
  state: ArtistsState = initialState,
  action: AnyAction
): ArtistsState => {
  switch (action.type) {
  case asStarted(ArtistsActionType.FETCH_ARTISTS_FOR_TAGS):
    return startLoading(withoutChangeRedirect(state));
  case asSuccess(ArtistsActionType.FETCH_ARTISTS_FOR_TAGS):
    return stopLoading(withArtists(state, action.result.entity));
  case asError(ArtistsActionType.FETCH_ARTISTS_FOR_TAGS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ArtistsActionType.FETCH_ARTIST_COVER_IMAGES):
    return startCoverImageLoading(state);
  case asSuccess(ArtistsActionType.FETCH_ARTIST_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImages(state, action.result.entity)
    );
  case asError(ArtistsActionType.FETCH_ARTIST_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImageError(state, action.result.error)
    );
  case asStarted(ArtistsActionType.FETCH_ARTIST_SIGNING_URL):
    return startSigningUrlLoading(state);
  case asSuccess(ArtistsActionType.FETCH_ARTIST_SIGNING_URL):
    return stopSigningUrlLoading(withSigningUrl(state, action.result.entity));
  case asError(ArtistsActionType.FETCH_ARTIST_SIGNING_URL):
    return stopSigningUrlLoading(withSigningUrlError(state, action.result.error));

  case asStarted(ArtistsActionType.CREATE_ARTIST):
    return startFormLoading(state);
  case asSuccess(ArtistsActionType.CREATE_ARTIST):
    return stopFormLoading(withChangeRedirect(state));
  case asModalError(ArtistsActionType.CREATE_ARTIST):
    return stopFormLoading(state);

  case asStarted(ArtistsActionType.UPDATE_ARTIST):
    return startFormLoading(state);
  case asSuccess(ArtistsActionType.UPDATE_ARTIST):
    return stopFormLoading(withFormUpdated(withChangeRedirect(state)));
  case asModalError(ArtistsActionType.UPDATE_ARTIST):
    return stopFormLoading(state);

  case AppRootActionType.CLEAR_FORM:
    return withClearForm(state);

  default:
    return {
      ...state
    };
  }
};

const withArtists = (state: ArtistsState, artist: ArtistParent) => {
  return {
    ...state,
    artists: artist.artists,
    tags: artist.tags
  };
};

const startCoverImageLoading = (state: ArtistsState) => {
  return {
    ...state,
    coverImageIsLoading: true,
    coverImageError: undefined
  };
};

const stopCoverImageLoading = (state: ArtistsState) => {
  return {
    ...state,
    coverImageIsLoading: false
  };
};

const withCoverImageError = (state: ArtistsState, error: DomainErrorValue) => {
  return {
    ...state,
    coverImageError: error.body
  };
};

const withCoverImages = (state: ArtistsState, coverImages: Asset[]) => {
  return {
    ...state,
    coverImages: coverImages
  };
};

const startSigningUrlLoading = (state: ArtistsState) => {
  return {
    ...state,
    signingUrlIsLoading: true,
    signingUrlError: undefined
  };
};

const stopSigningUrlLoading = (state: ArtistsState) => {
  return {
    ...state,
    signingUrlIsLoading: false
  };
};

const withSigningUrlError = (state: ArtistsState, error: DomainErrorValue) => {
  return {
    ...state,
    signingUrlError: error.body
  };
};


const withSigningUrl = (state: ArtistsState, signingUrl: string) => {
  return {
    ...state,
    signingUrl: signingUrl
  };
};

const withChangeRedirect = (state: ArtistsState) => {
  return {
    ...state,
    isFormLoading: false,
    onChangeRedirect: true
  };
};

const withoutChangeRedirect = (state: ArtistsState) => {
  return {
    ...state,
    onChangeRedirect: false
  };
};

export {
  artistsReducer
};
