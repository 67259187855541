import { RxFetch } from "../RxFetch";
const fetchSearchHint = (terms, baseUrl) => {
    return new RxFetch(baseUrl).get(`/search/hint/${encodeURIComponent(terms)}`);
};
const fetchSearchResults = (terms, baseUrl) => {
    return new RxFetch(baseUrl).get(`/search/${encodeURIComponent(terms)}`);
};
const fetchSearchPodcasts = (terms, baseUrl) => {
    return new RxFetch(baseUrl).get(`/search/podcast/${encodeURIComponent(terms)}`);
};
export { fetchSearchHint, fetchSearchResults, fetchSearchPodcasts };
