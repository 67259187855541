import { DomainResponse } from "../../DomainResponse";
import { fetchCommentsForPodcast, postCommentForPodcast, postCommentForNews, fetchComments, removeComment } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import CommentParentMapper from "./mapper/CommentParentMapper";
import CommentMapper from "./mapper/CommentMapper";
import { getToken } from "../../user/UserSessionRepository";
import VoidMapper from "../../VoidMapper";
const commentParentMapper = new CommentParentMapper();
const commentMapper = new CommentMapper();
const voidMapper = new VoidMapper();
const getPodcastComments = (podcastId, skip, limit, domainConfig) => {
    return fetchCommentsForPodcast(podcastId, skip, limit, domainConfig.baseUrl).pipe(map((api) => commentParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const createCommentForPodcast = (podcastId, body, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postCommentForPodcast(podcastId, body, token, domainConfig.baseUrl)), map((api) => commentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const createCommentForNews = (newsId, body, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postCommentForNews(newsId, body, token, domainConfig.baseUrl)), map((api) => commentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getComments = (skip, limit, domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchComments(token, skip, limit, domainConfig.baseUrl)), map((api) => commentParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const deleteComment = (id, domainConfig) => {
    return getToken().pipe(mergeMap((token) => removeComment(id, token, domainConfig.baseUrl)), map((api) => voidMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export { getPodcastComments, createCommentForPodcast, createCommentForNews, getComments, deleteComment };
