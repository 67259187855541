import { RxFetch } from "../RxFetch";
const fetchPodcasts = (skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/all/single?skip=${skip}&limit=${limit}`);
};
const fetchAllWeeklyPodcasts = (baseUrl) => {
    return new RxFetch(baseUrl).get("/podcast/all/weekly");
};
const fetchReleasedPodcasts = (skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/released/?skip=${skip}&limit=${limit}`);
};
const fetchPodcastsForArtist = (id, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/artist/${id}/?skip=${skip}&limit=${limit}`);
};
const fetchUpcomingPodcastsForArtist = (id, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/artist/${id}/upcoming/?skip=${skip}&limit=${limit}`);
};
const fetchPodcastsForArtistByGenre = (artistId, genreName, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/artist/${artistId}/genre/${encodeURIComponent(genreName)}?skip=${skip}&limit=${limit}`);
};
const fetchRelatedPodcasts = (podcastId, artistId, genreName, offset, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/genre/${encodeURIComponent(genreName)}/related/${podcastId}/artist/${artistId}?offset=${offset}&limit=${limit}`);
};
const fetchRandomPodcast = (podcastId, artistId, genreName, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/genre/${encodeURIComponent(genreName)}/random/${podcastId}/artist/${artistId}`);
};
const fetchPodcastsForArtistGroupedByGenre = (artistId, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/artist/${artistId}/group/genre/`);
};
const fetchPodcastsForArtistOrderByAppreciation = (artistId, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/artist/${artistId}/appreciation/?limit=5`);
};
const fetchPodcastsForCategory = (id, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/category/${id}/?skip=${skip}&limit=${limit}`);
};
const fetchPodcastsForCategoryByAppreciation = (id, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/category/${id}/appreciation/?skip=-1&limit=10`);
};
const fetchPodcastsForTag = (tag, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/tag/${tag}/?skip=${skip}&limit=${limit}`);
};
const fetchPodcastsForGenre = (name, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/genre/${encodeURIComponent(name)}/?skip=${skip}&limit=${limit}`);
};
const fetchPodcastByLink = (niceLink, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/name/${niceLink}`);
};
const fetchPodcastById = (podcastId, baseUrl) => {
    return new RxFetch(baseUrl).get(`/podcast/${podcastId}`);
};
const fetchPodcast = (by, niceLinkOrId, baseUrl) => {
    if (by === "niceLink") {
        return fetchPodcastByLink(niceLinkOrId, baseUrl);
    }
    else {
        return fetchPodcastById(niceLinkOrId, baseUrl);
    }
};
const postSchedule = (title, description, genre, type, audioStreamUrl, liveStreamUrl, imgUrl, liveScheduleStartTime, liveScheduleEndTime, liveScheduleTimeZone, artistId, tags, parts, token, baseUrl) => {
    return new RxFetch(baseUrl).post("/schedule/podcast/", {
        title: title,
        description: description,
        genre: genre,
        type: type,
        audioStreamUrl: audioStreamUrl,
        liveStreamUrl: liveStreamUrl,
        imgUrl: imgUrl,
        liveScheduleStartTime: liveScheduleStartTime,
        liveScheduleEndTime: liveScheduleEndTime,
        liveScheduleTimeZone: liveScheduleTimeZone,
        artist: {
            id: artistId
        },
        tags: tags,
        parts: parts
    }, {
        "X-Token": token
    });
};
const putSchedule = (id, title, description, genre, type, audioStreamUrl, liveStreamUrl, imgUrl, liveScheduleStartTime, liveScheduleEndTime, liveScheduleTimeZone, artistId, tags, parts, token, baseUrl) => {
    return new RxFetch(baseUrl).put(`/schedule/podcast/${id}`, {
        title: title,
        description: description,
        genre: genre,
        type: type,
        audioStreamUrl: audioStreamUrl,
        liveStreamUrl: liveStreamUrl,
        imgUrl: imgUrl,
        liveScheduleStartTime: liveScheduleStartTime,
        liveScheduleEndTime: liveScheduleEndTime,
        liveScheduleTimeZone: liveScheduleTimeZone,
        artist: {
            id: artistId
        },
        tags: tags,
        parts: parts
    }, {
        "X-Token": token
    });
};
const postWeeklySchedule = (title, description, liveStreamUrl, imgUrl, liveScheduleTimeZone, artistId, genre, tags, recurringDay, recurringStartHour, recurringStartMinutes, recurringEndHour, recurringEndMinutes, token, baseUrl) => {
    return new RxFetch(baseUrl).post("/schedule/recurring/weekly/", {
        title: title,
        description: description,
        genre: genre,
        liveStreamUrl: liveStreamUrl,
        imgUrl: imgUrl,
        liveScheduleTimeZone: liveScheduleTimeZone,
        artist: {
            id: artistId
        },
        tags: tags,
        recurring: {
            day: recurringDay,
            startHour: recurringStartHour,
            startMinutes: recurringStartMinutes,
            endHour: recurringEndHour,
            endMinutes: recurringEndMinutes
        }
    }, {
        "X-Token": token
    });
};
const putWeeklySchedule = (id, title, description, liveStreamUrl, imgUrl, liveScheduleTimeZone, artistId, genre, tags, recurringDay, recurringStartHour, recurringStartMinutes, recurringEndHour, recurringEndMinutes, token, baseUrl) => {
    return new RxFetch(baseUrl).put(`/schedule/recurring/weekly/${id}`, {
        title: title,
        description: description,
        genre: genre,
        liveStreamUrl: liveStreamUrl,
        imgUrl: imgUrl,
        liveScheduleTimeZone: liveScheduleTimeZone,
        artist: {
            id: artistId
        },
        tags: tags,
        recurring: {
            day: recurringDay,
            startHour: recurringStartHour,
            startMinutes: recurringStartMinutes,
            endHour: recurringEndHour,
            endMinutes: recurringEndMinutes
        }
    }, {
        "X-Token": token
    });
};
const removeSchedule = (id, token, baseUrl) => {
    return new RxFetch(baseUrl).delete(`/schedule/podcast/${id}`, {
        "X-Token": token
    });
};
export { fetchPodcasts, fetchAllWeeklyPodcasts, fetchReleasedPodcasts, fetchPodcastsForArtist, fetchUpcomingPodcastsForArtist, fetchPodcastsForArtistByGenre, fetchRelatedPodcasts, fetchRandomPodcast, fetchPodcastsForArtistGroupedByGenre, fetchPodcastsForArtistOrderByAppreciation, fetchPodcastsForCategory, fetchPodcastsForCategoryByAppreciation, fetchPodcastsForTag, fetchPodcastsForGenre, fetchPodcastByLink, fetchPodcastById, fetchPodcast, postSchedule, putSchedule, postWeeklySchedule, putWeeklySchedule, removeSchedule };
