import localforage from "localforage";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
export class RxStorage {
    constructor(entityName) {
        this.entityName = entityName;
    }
    getItem() {
        return new Observable((observer) => {
            localforage
                .getItem(this.entityName)
                .then((value) => {
                if (value != null) {
                    observer.next(value);
                }
                else {
                    observer.next(undefined);
                }
                observer.complete();
            })
                .catch((err) => observer.error(err));
        });
    }
    insertItem(value) {
        return new Observable((observer) => {
            localforage
                .setItem(this.entityName, value)
                .then(() => {
                observer.next(void 0);
                observer.complete();
            })
                .catch((err) => {
                observer.error(err);
            });
        });
    }
    removeItem() {
        return new Observable((observer) => {
            localforage
                .removeItem(this.entityName)
                .then(() => {
                observer.next(void 0);
                observer.complete();
            })
                .catch((err) => observer.error(err));
        });
    }
    getItems() {
        return new Observable((observer) => {
            localforage
                .getItem(this.entityName)
                .then((value) => {
                if (value != null) {
                    observer.next(value);
                }
                else {
                    observer.next([]);
                }
                observer.complete();
            })
                .catch((err) => observer.error(err));
        });
    }
    insertInto(value) {
        return this.getItems().pipe(map((list) => {
            const nextList = list || Array();
            nextList.push(value);
            return nextList;
        }), mergeMap((list) => this.insertItem(list)));
    }
    insertAll(values) {
        return this.getItems().pipe(map((list) => {
            const nextList = list || Array();
            nextList.push(...values);
            return nextList;
        }), mergeMap((list) => this.insertItem(list)));
    }
    filterReplace(predicate) {
        return this.getItems().pipe(mergeMap((list) => {
            if (list != null) {
                const results = list.filter(predicate);
                return this.insertItem(results);
            }
            else {
                return of(void 0);
            }
        }));
    }
    find(predicate) {
        return this.getItems().pipe(map((items) => {
            if (items != null) {
                const result = items.find(predicate);
                if (result != null && result !== undefined)
                    return result;
                return null;
            }
            return null;
        }));
    }
    filter(predicate) {
        return this.getItems().pipe(map((items) => {
            if (items != null) {
                const results = items.filter(predicate);
                if (results != null && results !== undefined)
                    return results;
                return null;
            }
            return null;
        }));
    }
    exists(predicate) {
        return this.find(predicate).pipe(map((result) => {
            if (result != null)
                return true;
            return false;
        }));
    }
    static clear() {
        return new Observable((observer) => {
            localforage
                .clear()
                .then(() => {
                observer.next(void 0);
                observer.complete();
            })
                .catch((err) => observer.error(err));
        });
    }
}
