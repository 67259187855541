import VoidMapper from "../../VoidMapper";
import { putLinkWithFacebook } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DomainResponse } from "../../DomainResponse";
const voidMapper = new VoidMapper();
const linkWithFacebook = (linkWithFacebookToken, domainConfig) => {
    return putLinkWithFacebook(linkWithFacebookToken, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export { linkWithFacebook };
