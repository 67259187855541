import React from "react";
import {
  HasChildren, Hidden
} from "../../shared/Props";

const FlexOneLeftDiv = (props: HasChildren & Hidden) => (
  <div
    hidden={props.hidden}
    style={{
      flex: 1, marginRight: props.hidden ? 0 : 8
    }}
  >
    {props.children}
  </div>
);

const FlexOneMiddleDiv = (props: HasChildren) => (
  <div style={{
    flex: 1, marginLeft: 8, marginRight: 8
  }}>{props.children}</div>
);

const FlexOneRightDiv = (props: HasChildren & Hidden) => (
  <div
    hidden={props.hidden}
    style={{
      flex: 1, marginLeft: props.hidden ? 0 : 8
    }}
  >
    {props.children}
  </div>
);

export {
  FlexOneLeftDiv, FlexOneMiddleDiv, FlexOneRightDiv
};
