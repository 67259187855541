import React, {
  ChangeEvent
} from "react"
import {
  Stylable, Disablable
} from "../../shared/Props"
import {
  TextField
} from "@material-ui/core"

interface MultilineTextInputViewProps {
  label: string
  placeholder?: string
  required?: boolean
  value?: string
  name?: string
  type?: string
  onChanged: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  rows: number
}

const MultilineTextInputView = (props: MultilineTextInputViewProps & Stylable & Disablable) =>
  <TextField
    fullWidth
    style={props.extraStyle}
    placeholder={props.placeholder}
    label={props.label}
    name={props.name}
    variant="outlined"
    value={props.value || ""}
    onChange={props.onChanged}
    required={props.required}
    type={props.type}
    disabled={props.disabled}
    InputLabelProps={{
      shrink: true
    }}
    color="secondary"
    rows={props.rows}
    multiline
  />

export default MultilineTextInputView