import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError
} from "../../../../shared/Actions";
import {
  CommentsActionType
} from "./CommentsActions";
import {
  DomainErrorValue, Comment, CommentParent
} from "@rewindit/domain";

export interface CommentsState {
  comments: Optional<Comment[]>;
  isLoading: boolean;
  error: Optional<string>;
  isLoadingMore: boolean;
  isDeleteLoading: boolean;
  deleteError: Optional<string>;
  deleteRedirect: boolean;
}

const initialState: CommentsState = {
  comments: undefined,
  isLoading: false,
  error: undefined,
  isLoadingMore: false,
  isDeleteLoading: false,
  deleteError: undefined,
  deleteRedirect: false
};

const commentsReducer = (
  state: CommentsState = initialState,
  action: AnyAction
): CommentsState => {
  switch (action.type) {
  case asStarted(CommentsActionType.FETCH_COMMENTS):
    return startLoading(withoutDeleteRedirect(state), action.skip);
  case asSuccess(CommentsActionType.FETCH_COMMENTS):
    return stopLoading(withComments(state, action.result.entity));
  case asError(CommentsActionType.FETCH_COMMENTS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(CommentsActionType.FETCH_MORE_COMMENTS):
    return startLoading(state, action.skip);
  case asSuccess(CommentsActionType.FETCH_MORE_COMMENTS):
    return stopLoading(withComments(state, action.result.entity));
  case asError(CommentsActionType.FETCH_MORE_COMMENTS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(CommentsActionType.DELETE_COMMENT):
    return startDeleteLoading(state);
  case asSuccess(CommentsActionType.DELETE_COMMENT):
    return withDeleteRedirect(state);
  case asError(CommentsActionType.DELETE_COMMENT):
    return withDeleteError(state, action.result.error);

  default:
    return {
      ...state
    };
  }
};

const withComments = (state: CommentsState, commentParent: CommentParent) => {
  if (state.comments === undefined) return {
    ...state, comments: commentParent.comments
  };
  return {
    ...state,
    comments: state.comments.concat(commentParent.comments)
  };
};

const startDeleteLoading = (state: CommentsState) => {
  return {
    ...state,
    isDeleteLoading: true,
    deleteError: undefined
  };
};

const withDeleteError = (state: CommentsState, error: DomainErrorValue) => {
  return {
    ...state,
    isDeleteLoading: false,
    deleteError: error.body
  };
};

const withDeleteRedirect = (state: CommentsState) => {
  return {
    ...state,
    isDeleteLoading: false,
    deleteRedirect: true
  };
};

const withoutDeleteRedirect = (state: CommentsState) => {
  return {
    ...state,
    deleteRedirect: false
  };
};

export {
  commentsReducer
};
