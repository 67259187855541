import React, {
  useState, useEffect
} from "react";
import {
  Grid, Typography
} from "@material-ui/core";
import {
  FlexOneLeftDiv,
  FlexOneRightDiv
} from "../../../../uikit/div/FlexOneDiv";
import MultilineTextInputView from "../../../../uikit/form/MultilineTextInputView";
import FormRow from "../../../../uikit/form/FormRow";
import TextInputView from "../../../../uikit/form/TextInputView";
import {
  PodcastPart
} from "@rewindit/domain";
import {
  Optional
} from "../../../../shared/Optional";
import DurationPickerView from "../../../../uikit/form/DurationPickerView";
import {
  timestampFromSecondsMinutesHoursInSeconds,
  timestampDurationBreakdown
} from "../../../../date/DateTimeFormatter";

interface SchedulePartViewProps {
  partialPart: PartialPodcastPart;
  partChanged: (part: PartialPodcastPart) => void;
}

export interface PartialPodcastPart {
  title: Optional<string>;
  description: Optional<string>;
  startSeconds: Optional<number>;
  endSeconds: Optional<number>;
}

const partialPodcastPart = (podcastPart: PodcastPart): PartialPodcastPart => {
  return {
    title: podcastPart?.title ?? undefined,
    description: podcastPart?.description ?? undefined,
    startSeconds: podcastPart?.startSeconds ?? 0,
    endSeconds: podcastPart?.endSeconds ?? 0
  };
};

const SchedulePartView = (props: SchedulePartViewProps) => {
  const [formStartHours, setFormStartHours] = useState(0);
  const [formStartMinutes, setFormStartMinutes] = useState(0);
  const [formStartSeconds, setFormStartSeconds] = useState(0);

  const [formEndHours, setFormEndHours] = useState(0);
  const [formEndMinutes, setFormEndMinutes] = useState(0);
  const [formEndSeconds, setFormEndSeconds] = useState(0);

  const {
    partialPart
  } = props;

  useEffect(() => {
    if (partialPart !== undefined && partialPart.startSeconds !== undefined) {
      const breakdown = timestampDurationBreakdown(
        partialPart.startSeconds !== undefined
          ? partialPart.startSeconds * 1000
          : 0
      );
      setFormStartHours(breakdown.hours);
      setFormStartMinutes(breakdown.minutes);
      setFormStartSeconds(breakdown.seconds);
    }
    if (partialPart !== undefined && partialPart.endSeconds !== undefined) {
      const breakdown = timestampDurationBreakdown(
        partialPart.endSeconds !== undefined ? partialPart.endSeconds * 1000 : 0
      );
      setFormEndHours(breakdown.hours);
      setFormEndMinutes(breakdown.minutes);
      setFormEndSeconds(breakdown.seconds);
    }
  }, [
    partialPart,
    setFormStartHours,
    setFormStartMinutes,
    setFormStartSeconds,
    setFormEndHours,
    setFormEndMinutes,
    setFormEndSeconds
  ]);

  return (
    <Grid container direction="row">
      <FormRow>
        <TextInputView
          label="Title"
          name="title"
          value={props.partialPart?.title ?? undefined}
          required={true}
          onChanged={(event) => {
            props.partChanged({
              ...props.partialPart,
              title: event.target.value
            });
          }}
        />
      </FormRow>
      <FormRow>
        <MultilineTextInputView
          label="Description"
          name="description"
          value={props.partialPart?.description ?? undefined}
          required={true}
          rows={5}
          onChanged={(event) => {
            props.partChanged({
              ...props.partialPart,
              description: event.target.value
            });
          }}
        />
      </FormRow>
      <FormRow>
        <FlexOneLeftDiv>
          <Typography
            variant="subtitle2"
            style={{
              marginBottom: 8, color: "#333"
            }}
          >
            Start time
          </Typography>
          <DurationPickerView
            hoursLabel="Hour"
            hours={formStartHours}
            hoursChanged={(hours) => {
              props.partChanged({
                ...props.partialPart,
                startSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  hours,
                  formStartMinutes,
                  formStartSeconds
                )
              });
            }}
            minutesLabel="Minutes"
            minutes={formStartMinutes}
            minutesChanged={(minutes) => {
              props.partChanged({
                ...props.partialPart,
                startSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  formStartHours,
                  minutes,
                  formStartSeconds
                )
              });
            }}
            secondsLabel="Seconds"
            seconds={formStartSeconds}
            secondsChanged={(seconds) => {
              setFormStartSeconds(seconds);
              props.partChanged({
                ...props.partialPart,
                startSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  formStartHours,
                  formStartMinutes,
                  seconds
                )
              });
            }}
          />
        </FlexOneLeftDiv>
        <FlexOneRightDiv>
          <Typography
            variant="subtitle2"
            style={{
              marginBottom: 8, color: "#333"
            }}
          >
            End time
          </Typography>
          <DurationPickerView
            hoursLabel="Hour"
            hours={formEndHours}
            hoursChanged={(hours) => {
              setFormEndHours(hours);
              props.partChanged({
                ...props.partialPart,
                endSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  hours,
                  formEndMinutes,
                  formEndSeconds
                )
              });
            }}
            minutesLabel="Minutes"
            minutes={formEndMinutes}
            minutesChanged={(minutes) => {
              setFormEndMinutes(minutes);
              props.partChanged({
                ...props.partialPart,
                endSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  formEndHours,
                  minutes,
                  formEndSeconds
                )
              });
            }}
            secondsLabel="Seconds"
            seconds={formEndSeconds}
            secondsChanged={(seconds) => {
              setFormEndSeconds(seconds);
              props.partChanged({
                ...props.partialPart,
                endSeconds: timestampFromSecondsMinutesHoursInSeconds(
                  formEndHours,
                  formEndMinutes,
                  seconds
                )
              });
            }}
          />
        </FlexOneRightDiv>
      </FormRow>
    </Grid>
  );
};

export {
  SchedulePartView, partialPodcastPart
};
