import {
  CategoriesActionType
} from "./CategoriesActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getCategoriesWithTags,
  getCovers,
  getSigningUrl,
  deleteCategory,
  createCategory,
  updateCategory
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const categoriesFetchEpic = createEpic(
  CategoriesActionType.FETCH_CATEGORIES,
  ErrorType.INLINE,
  () => getCategoriesWithTags(apiConfig)
);

const categoryDeleteEpic = createEpic(
  CategoriesActionType.DELETE_CATEGORY,
  ErrorType.INLINE,
  (action) => deleteCategory(action.id, apiConfig)
);

const categoryCreateEpic = createEpic(
  CategoriesActionType.CREATE_CATEGORY,
  ErrorType.MODAL,
  (action) => createCategory(action.values, apiConfig)
);

const categoryUpdateEpic = createEpic(
  CategoriesActionType.UPDATE_CATEGORY,
  ErrorType.MODAL,
  (action) => updateCategory(action.id, action.values, apiConfig)
);

const categoriesFetchCoverImageEpic = createEpic(
  CategoriesActionType.FETCH_CATEGORY_COVER_IMAGES,
  ErrorType.INLINE,
  (action) => getCovers(action.name, apiConfig)
);

const categoriesFetchCoverImageSignedUrlEpic = createEpic(
  CategoriesActionType.FETCH_CATEGORY_SIGNING_URL,
  ErrorType.INLINE,
  (action) => getSigningUrl(action.name, action.assetType, apiConfig)
);

export {
  categoriesFetchEpic,
  categoryDeleteEpic,
  categoryCreateEpic,
  categoryUpdateEpic,
  categoriesFetchCoverImageEpic,
  categoriesFetchCoverImageSignedUrlEpic
};
