import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Divider
} from "@material-ui/core";
import {
  Selected
} from "../../../shared/Events";

interface RootMenuProps {
  menuScheduleSelected: Selected;
  menuPlaylistsSelected: Selected;
  menuArtistsSelected: Selected;
  menuCategoriesSelected: Selected;
  menuCommentsSelected: Selected;
  menuUsersSelected: Selected;
  menuAssetsSelected: Selected;
  menuTagsSelected: Selected;
  menuLogoutSelected: Selected;
}

const RootMenu = (props: RootMenuProps) => (
  <List style={{
    backgroundColor: "#FFFFFF", height: "100%"
  }}>
    <ListItem button key={"Schedule"} onClick={props.menuScheduleSelected}>
      <ListItemIcon>
        <Icon>schedule</Icon>
      </ListItemIcon>
      <ListItemText primary={"Schedule"} />
    </ListItem>
    <ListItem button key={"Playlists"} onClick={props.menuPlaylistsSelected}>
      <ListItemIcon>
        <Icon>restore</Icon>
      </ListItemIcon>
      <ListItemText primary={"Playlists"} />
    </ListItem>
    <Divider />
    <ListItem button key={"Comments"} onClick={props.menuCommentsSelected}>
      <ListItemIcon>
        <Icon>chat_bubble</Icon>
      </ListItemIcon>
      <ListItemText primary={"Comments"} />
    </ListItem>
    <Divider />
    <ListItem
      button
      key={"Hosts & Artists"}
      onClick={props.menuArtistsSelected}
    >
      <ListItemIcon>
        <Icon>record_voice_over</Icon>
      </ListItemIcon>
      <ListItemText primary={"Hosts & Artists"} />
    </ListItem>
    <ListItem
      button
      key={"Moods & Categories"}
      onClick={props.menuCategoriesSelected}
    >
      <ListItemIcon>
        <Icon>queue_music</Icon>
      </ListItemIcon>
      <ListItemText primary={"Moods & Categories"} />
    </ListItem>
    <ListItem button key={"Tags"} onClick={props.menuTagsSelected}>
      <ListItemIcon>
        <Icon>format_list_bulleted</Icon>
      </ListItemIcon>
      <ListItemText primary={"Tags"} />
    </ListItem>
    <Divider />
    <ListItem button key={"Users"} onClick={props.menuUsersSelected}>
      <ListItemIcon>
        <Icon>account_box</Icon>
      </ListItemIcon>
      <ListItemText primary={"Users"} />
    </ListItem>
    <Divider />
    <ListItem button key={"Assets"} onClick={props.menuAssetsSelected}>
      <ListItemIcon>
        <Icon>image</Icon>
      </ListItemIcon>
      <ListItemText primary={"Assets"} />
    </ListItem>
    <Divider />
    <ListItem button key={"Logout"} onClick={props.menuLogoutSelected}>
      <ListItemIcon>
        <Icon>keyboard_return</Icon>
      </ListItemIcon>
      <ListItemText primary={"Logout"} />
    </ListItem>
  </List>
);

export default RootMenu;
