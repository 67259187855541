import {
  connect
} from "react-redux";
import ArtistsComponent from "./ArtistsComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  ArtistsActionType
} from "./model/ArtistsActions";
import {
  push
} from "connected-react-router";
import {
  ArtistFormValues
} from "@rewindit/domain";
import {
  AppRootActionType
} from "../../root/model/AppRootActions";
import {
  AssetType
} from "../assets/model/AssetType";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.artists
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadArtists: () => {
      dispatch({
        type: ArtistsActionType.FETCH_ARTISTS_FOR_TAGS
      });
    },
    artistSelected: (id: string) => {
      dispatch({
        type: AppRootActionType.CLEAR_FORM
      });
      dispatch(push(`/artists/edit/${id}`));
    },
    loadArtistCoverImages: (name: string) => {
      dispatch({
        type: ArtistsActionType.FETCH_ARTIST_COVER_IMAGES,
        name: name
      });
    },
    loadSigningUrl: (name: string, assetType: AssetType) => {
      dispatch({
        type: ArtistsActionType.FETCH_ARTIST_SIGNING_URL,
        name: name,
        assetType: assetType
      });
    },
    createArtist: (values: ArtistFormValues) => {
      dispatch({
        type: ArtistsActionType.CREATE_ARTIST,
        values: values
      });
    },
    updateArtist: (id: string, values: ArtistFormValues) => {
      dispatch({
        id: id,
        type: ArtistsActionType.UPDATE_ARTIST,
        values: values
      });
    },
    reloadArtists: () => {
      dispatch(push("/artists/"));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsComponent);
