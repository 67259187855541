import React, {
  useState
} from "react";

import {
  WidthBreakpoint
} from "../../shared/Props";
import {
  Dialog,
  withStyles,
  withWidth,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme
} from "@material-ui/core";
import {
  isWidthDown
} from "@material-ui/core/withWidth";
import {
  SecondaryButton
} from "../button/SecondaryButton";
import {
  PrimaryButton
} from "../button/PrimaryButton";
import {
  Optional
} from "../../shared/Optional";
import {
  ProgressOrContent
} from "../progress/ProgressOrContent";
import {
  ErrorDiv
} from "../div/ErrorDiv";

interface ContentDialogProps {
  isLoading: boolean;
  error: Optional<string>;
  isOpen: boolean;
  title: string;
  body: string;
  handleAgree: () => void;
  handleDismiss: () => void;
}

const styles = (theme: Theme) => ({
  parent: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: 680
    },
    [theme.breakpoints.up("lg")]: {
      width: 820
    }
  }
});

const ConfirmDialog = (props: ContentDialogProps & WidthBreakpoint) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Dialog
        open={isOpen || props.isOpen}
        onClose={() => {
          setOpen(false);
          props.handleDismiss();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={isWidthDown("sm", props.width)}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent style={{
          width: 540, height: 80
        }}>
          <ProgressOrContent {...props}>
            <DialogContentText
              hidden={props.isLoading || props.error !== undefined}
            >
              {props.body}
            </DialogContentText>
            <ErrorDiv errorMessage={props.error} />
          </ProgressOrContent>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={() => {
              setOpen(false);
              props.handleDismiss();
            }}
          >
            CANCEL
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              setOpen(false);
              props.handleAgree();
            }}
          >
            CONFIRM
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withWidth()(
  withStyles(styles, {
    withTheme: true
  })(ConfirmDialog)
);
