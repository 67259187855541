import React from "react";
import {
  HasChildren, Clickable, Loadable, Stylable
} from "../../shared/Props";
import {
  StubProgressButton
} from "./StubProgressButton";
import {
  PrimaryButton
} from "./PrimaryButton";

type Props = HasChildren &
  Clickable &
  Loadable &
  Stylable &
  ProgressButtonProps;

interface ProgressButtonProps {
  fullWidth?: boolean;
  type?: "submit" | "reset" | "button";
}

const ProgressButton = (props: Props) => {
  if (props.isLoading) {
    return <StubProgressButton {...props} />;
  } else {
    return (
      <PrimaryButton {...props} onClick={props.onClick}>
        {props.children}
      </PrimaryButton>
    );
  }
};

export {
  ProgressButton
};
