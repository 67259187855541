import {
  PodcastSchedule
} from "@rewindit/domain";
import {
  Optional
} from "../../../../shared/Optional";

enum ScheduleType {
  PODCAST = "PODCAST",
  PODCAST_AFTER_REALTIME = "PODCAST_AFTER_REALTIME",
  REALTIME = "REALTIME",
  WEEKLY = "WEEKLY",
}

const isPodcastIncomplete = (podcast: Optional<PodcastSchedule>) => {
  if (podcast === undefined) return false;
  return !podcast.imgUrl;
};

const podcastIncompleteMessage = (podcast: Optional<PodcastSchedule>) => {
  if (podcast === undefined) return "";
  if (!podcast.imgUrl) return "A cover image is required.";
  return "";
};

export {
  isPodcastIncomplete, podcastIncompleteMessage, ScheduleType
};
