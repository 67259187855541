import {
  applyMiddleware, createStore
} from "redux";
import {
  createEpicMiddleware
} from "redux-observable";
import {
  composeWithDevTools
} from "redux-devtools-extension";
import {
  rootEpic
} from "./rootEpic";
import {
  createRootReducer, historyMiddleWare
} from "./rootReducer";

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  createRootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware, historyMiddleWare))
);

epicMiddleware.run(rootEpic);

export {
  store
};
