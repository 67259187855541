import React from "react";
import {
  CircularProgress
} from "@material-ui/core";
import {
  CSSProperties
} from "@material-ui/styles";

const ProgressView = (style: CSSProperties) => {
  return <CircularProgress style={style} color="primary" />;
};

export {
  ProgressView
};
