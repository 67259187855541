import React, {
  useEffect
} from "react";
import {
  CSSProperties
} from "@material-ui/styles";
import {
  HeaderBaselineDiv
} from "./HeaderBaselineDiv";
import {
  Grid, Icon, Typography, Paper, Snackbar
} from "@material-ui/core";

interface SuccessDivProps {
  visible: boolean;
  successMessage: string;
}

const style: CSSProperties = {
  paddingLeft: 8
};

const SuccessDiv = (props: SuccessDivProps) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.visible);
  }, [props.visible]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => {
        setOpen(false);
      }}
    >
      <HeaderBaselineDiv>
        <Paper
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            backgroundColor: "#333333"
          }}
        >
          <Grid container>
            <Icon color="primary">check_circle</Icon>
            <Typography variant="body1" style={style} color="primary">
              {props.successMessage}
            </Typography>
          </Grid>
        </Paper>
      </HeaderBaselineDiv>
    </Snackbar>
  );
};

export default SuccessDiv;
