import {
  connect
} from "react-redux";
import {
  LoginComponent
} from "./LoginComponent";
import {
  RootState
} from "../../rootReducer";
import {
  Dispatch
} from "react";
import {
  AnyAction
} from "redux";
import {
  LoginActionType
} from "./model/LoginActions";
import {
  AppRootActionType
} from "../../root/model/AppRootActions";
import {
  UserSession
} from "@rewindit/domain";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.login
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSelected: (username: string, password: string) => {
      dispatch({
        type: LoginActionType.POST_LOGIN,
        username: username,
        password: password
      });
    },
    userAuthenticated: (userSession: UserSession) => {
      dispatch({
        type: AppRootActionType.USER_AUTHENTICATED,
        userSession: userSession
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
