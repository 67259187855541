enum ScheduleActionType {
  FETCH_INITIAL_PLAYLISTS = "FETCH_INITIAL_PLAYLISTS",
  FETCH_INITIAL_SCHEDULE = "FETCH_INITIAL_SCHEDULE",
  RESET_INITIAL_SCHEDULE_LIST = "RESET_INITIAL_SCHEDULE_LIST",
  SEARCH_SCHEDULE_BY_TITLE = "SEARCH_SCHEDULE_BY_TITLE",
  FETCH_PODCAST_BY_ID = "FETCH_PODCAST_BY_ID",
  FETCH_PODCASTS = "FETCH_PODCASTS",
  FETCH_PODCASTS_BY_ARTIST = "FETCH_PODCASTS_BY_ARTIST",
  FETCH_PODCASTS_BY_ARTIST_MORE = "FETCH_PODCASTS_BY_ARTIST_MORE",
  FETCH_SCHEDULE_AUDIO_FILES = "FETCH_SCHEDULE_AUDIO_FILES",
  FETCH_SCHEDULE_COVER_IMAGES = "FETCH_SCHEDULE_COVER_IMAGES",
  FETCH_SCHEDULE_SIGNING_URL = "FETCH_SCHEDULE_SIGNING_URL",
  UPDATE_SCHEDULE_COVER_IMAGE = "UPDATE_SCHEDULE_COVER_IMAGE",
  DELETE_SCHEDULE = "DELETE_SCHEDULE",
  CREATE_SCHEDULE = "CREATE_SCHEDULE",
  UPDATE_SCHEDULE = "UPDATE_SCHEDULE",
  CREATE_WEEKLY_SCHEDULE = "CREATE_WEEKLY_SCHEDULE",
  UPDATE_WEEKLY_SCHEDULE = "UPDATE_WEEKLY_SCHEDULE",
  FETCH_SCHEDULE_AUDIO_SIGNING_URL = "FETCH_SCHEDULE_AUDIO_SIGNING_URL",
  SET_SCROLL_POSITION = "SET_SCROLL_POSITION",
  SET_SEARCH_TERMS = "SET_SEARCH_TERMS",
  SET_SELECTED_ARTIST_ID = "SET_SELECTED_ARTIST_ID",
}

export {
  ScheduleActionType
};
