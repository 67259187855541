import { DomainResponse } from "../DomainResponse";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { fetchSearchHint, fetchSearchResults } from "@rewindit/data";
import SearchMapper from "./mapper/SearchMapper";
const searchMapper = new SearchMapper();
const searchHint = (terms, domainConfig) => {
    return fetchSearchHint(terms, domainConfig.baseUrl).pipe(map((api) => searchMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const searchResults = (terms, domainConfig) => {
    return fetchSearchResults(terms, domainConfig.baseUrl).pipe(map((api) => searchMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export { searchHint, searchResults };
