import React from "react";
import {
  TextField, IconButton, Icon
} from "@material-ui/core";

interface SearchTextViewProps {
  searchTerms?: string;
  resetSearch: () => void;
  triggerSearch: (terms: string) => void;
  termsChanged?: (terms: string) => void;
}

const SearchTextView = (props: SearchTextViewProps) => {
  return (
    <div style={{
      backgroundColor: "#FFFFFF"
    }}>
      <TextField
        fullWidth
        variant="outlined"
        color="secondary"
        placeholder={"Search by schedule title"}
        value={props.searchTerms ?? ""}
        onChange={(event) => {
          const value = event.target.value;
          if (value.length > 2) {
            props.triggerSearch(value);
          } else {
            props.resetSearch();
          }
          if (props.termsChanged !== undefined) {
            props.termsChanged(value);
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              style={{
                display:
                  props.searchTerms === undefined ||
                    props.searchTerms.length === 0
                    ? "none"
                    : "block"
              }}
              onClick={() => {
                props.resetSearch();
                if (props.termsChanged !== undefined) {
                  props.termsChanged("");
                }
              }}
            >
              <Icon>clear</Icon>
            </IconButton>
          )
        }}
      />
    </div>
  );
};

export default SearchTextView;
