import {
  connect
} from "react-redux";
import CategoriesComponent from "./CategoriesComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  CategoriesActionType
} from "./model/CategoriesActions";
import {
  push
} from "connected-react-router";
import {
  CategoryFormValues
} from "@rewindit/domain";
import {
  AppRootActionType
} from "../../root/model/AppRootActions";
import {
  AssetType
} from "../assets/model/AssetType";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.categories
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadCategories: () => {
      dispatch({
        type: CategoriesActionType.FETCH_CATEGORIES,
        skip: -1
      });
    },
    categorySelected: (id: string) => {
      dispatch({
        type: AppRootActionType.CLEAR_FORM
      });
      dispatch(push(`/categories/edit/${id}`));
    },
    loadCategoryCoverImages: (name: string) => {
      dispatch({
        type: CategoriesActionType.FETCH_CATEGORY_COVER_IMAGES,
        name: name
      });
    },
    loadSigningUrl: (name: string, assetType: AssetType) => {
      dispatch({
        type: CategoriesActionType.FETCH_CATEGORY_SIGNING_URL,
        name: name,
        assetType: assetType
      });
    },
    deleteCategory: (id: string) => {
      dispatch({
        type: CategoriesActionType.DELETE_CATEGORY,
        id: id
      });
    },
    createCategory: (values: CategoryFormValues) => {
      dispatch({
        type: CategoriesActionType.CREATE_CATEGORY,
        values: values
      });
    },
    updateCategory: (id: string, values: CategoryFormValues) => {
      dispatch({
        type: CategoriesActionType.UPDATE_CATEGORY,
        id: id,
        values: values
      });
    },
    reloadCategories: () => {
      dispatch(push("/categories/"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesComponent);
