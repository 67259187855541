import Mapper from "../../Mapper";
import { durationSeconds } from "../../DateTimeFormatter";
export default class PodcastPartMapper extends Mapper {
    from(entity) {
        return {
            title: entity.title,
            description: entity.description,
            startSeconds: entity.startSeconds,
            endSeconds: entity.endSeconds,
            duration: `${durationSeconds(entity.startSeconds)} - ${durationSeconds(entity.endSeconds)}`
        };
    }
}
