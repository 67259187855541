
import {
  DomainConfig
} from "@rewindit/domain"

const apiConfig: DomainConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL || ""
};

export {
  apiConfig
};
