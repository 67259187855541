import React, {
  useEffect
} from "react";
import {
  ArtistsState
} from "./model/ArtistsReducer";
import {
  LoadMore, SelectedId
} from "../../../shared/Events";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../shared/UnwrapValue";
import ArtistListView from "./view/ArtistListView";
import {
  Switch, Route
} from "react-router-dom";
import {
  ArtistFormValues
} from "@rewindit/domain";
import ArtistForm from "./form/ArtistForm";
import {
  AssetType
} from "../assets/model/AssetType";

interface ArtistsComponentProps extends ArtistsState {
  loadArtists: () => void;
  loadArtistCoverImages: (name: string) => void;
  loadMore: LoadMore;
  artistSelected: SelectedId;
  loadSigningUrl: (name: string, type: AssetType) => void;
  createArtist: (values: ArtistFormValues) => void;
  updateArtist: (id: string, values: ArtistFormValues) => void;
  reloadArtists: () => void;
}

const ArtistsComponent = (props: ArtistsComponentProps) => {
  const {
    artists,
    loadArtists,
    onChangeRedirect
  } = props;

  useEffect(() => {
    if (artists === undefined || onChangeRedirect) {
      loadArtists();
    }
  }, [artists, loadArtists, onChangeRedirect]);

  return (
    <ProgressOrError
      retryClicked={() => {
        loadArtists();
      }}
      {...props}
    >
      <Switch>
        <Route path={"/artists/edit/:id"}>
          {unwrapValue(
            (artists) => (
              <ArtistForm {...props} artists={artists} />
            ),
            props.artists
          )}
        </Route>
        <Route path={"/artists/add"}>
          {unwrapValue(
            (artists) => (
              <ArtistForm {...props} artists={artists} />
            ),
            props.artists
          )}
        </Route>
        <Route path={"/"}>
          {unwrapValue(
            (artists) => (
              <ArtistListView {...props} artists={artists} />
            ),
            props.artists
          )}
        </Route>
      </Switch>
    </ProgressOrError>
  );
};

export default ArtistsComponent;
