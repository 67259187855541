import Mapper from "../../Mapper";
import PodcastMapper from "./PodcastMapper";
export default class PodcastGenreGroupMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
    }
    from(entity) {
        return entity.categories
            .map((genreGroup) => {
            return {
                name: genreGroup.name,
                podcasts: genreGroup.podcasts.map((podcast) => this.podcastMapper.from(podcast))
            };
        })
            .sort((a, b) => b.podcasts.length - a.podcasts.length);
    }
}
