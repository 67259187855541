import Mapper from "../../Mapper";
export default class ArtistMapper extends Mapper {
    from(entity) {
        return {
            id: entity.id,
            name: entity.name,
            quote: entity.quote,
            description: entity.description,
            imgUrl: entity.imgUrl,
            websiteUrl: entity.websiteUrl,
            niceLink: entity.niceLink,
            order: entity.order !== undefined ? `${entity.order}` : undefined,
            userId: entity.userId,
            tags: entity.tags
        };
    }
}
