import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError,
  withClearForm,
  startFormLoading,
  stopFormLoading,
  withFormUpdated,
  asModalError
} from "../../../../shared/Actions";
import {
  CategoriesActionType
} from "./CategoriesActions";
import {
  DomainErrorValue, Asset, CategoryParent
} from "@rewindit/domain";
import {
  AppRootActionType
} from "../../../root/model/AppRootActions";

export interface CategoriesState {
  categoryParent: Optional<CategoryParent>;
  isLoading: boolean;
  error: Optional<string>;
  isDeleteLoading: boolean;
  deleteError: Optional<string>;
  onChangeRedirect: boolean;
  isFormLoading: boolean;
  formUpdated: boolean;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  coverImages: Optional<Asset[]>;
  signingUrlIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
}

const initialState: CategoriesState = {
  categoryParent: undefined,
  isLoading: false,
  error: undefined,
  isDeleteLoading: false,
  deleteError: undefined,
  onChangeRedirect: false,
  isFormLoading: false,
  formUpdated: false,
  coverImageIsLoading: false,
  coverImageError: undefined,
  coverImages: undefined,
  signingUrlIsLoading: false,
  signingUrlError: undefined,
  signingUrl: undefined
};

const categoriesReducer = (
  state: CategoriesState = initialState,
  action: AnyAction
): CategoriesState => {
  switch (action.type) {
  case asStarted(CategoriesActionType.FETCH_CATEGORIES):
    return startLoading(withoutChangeRedirect(state));
  case asSuccess(CategoriesActionType.FETCH_CATEGORIES):
    return stopLoading(withCategories(state, action.result.entity));
  case asError(CategoriesActionType.FETCH_CATEGORIES):
    return stopLoading(withError(state, action.result.error));

  case asStarted(CategoriesActionType.DELETE_CATEGORY):
    return startDeleteLoading(state);
  case asSuccess(CategoriesActionType.DELETE_CATEGORY):
    return withChangeRedirect(state);
  case asError(CategoriesActionType.DELETE_CATEGORY):
    return withDeleteError(state, action.result.error);

  case asStarted(CategoriesActionType.FETCH_CATEGORY_COVER_IMAGES):
    return startCoverImageLoading(state);
  case asSuccess(CategoriesActionType.FETCH_CATEGORY_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImages(state, action.result.entity)
    );
  case asError(CategoriesActionType.FETCH_CATEGORY_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImageError(state, action.result.error)
    );

  case asStarted(CategoriesActionType.FETCH_CATEGORY_SIGNING_URL):
    return startSigningUrlLoading(state);
  case asSuccess(CategoriesActionType.FETCH_CATEGORY_SIGNING_URL):
    return stopSigningUrlLoading(withSigningUrl(state, action.result.entity));
  case asError(CategoriesActionType.FETCH_CATEGORY_SIGNING_URL):
    return stopSigningUrlLoading(withSigningError(state, action.result.error));

  case asStarted(CategoriesActionType.CREATE_CATEGORY):
    return startFormLoading(state);
  case asSuccess(CategoriesActionType.CREATE_CATEGORY):
    return stopFormLoading(withChangeRedirect(state));
  case asModalError(CategoriesActionType.CREATE_CATEGORY):
    return stopFormLoading(state);

  case asStarted(CategoriesActionType.UPDATE_CATEGORY):
    return startFormLoading(state);
  case asSuccess(CategoriesActionType.UPDATE_CATEGORY):
    return stopFormLoading(withFormUpdated(withChangeRedirect(state)));
  case asModalError(CategoriesActionType.UPDATE_CATEGORY):
    return stopFormLoading(state);

  case AppRootActionType.CLEAR_FORM:
    return withClearForm(state);

  default:
    return {
      ...state
    };
  }
};

const withCategories = (
  state: CategoriesState,
  categoryParent: CategoryParent
) => {
  return {
    ...state,
    categoryParent: categoryParent
  };
};

const startCoverImageLoading = (state: CategoriesState) => {
  return {
    ...state,
    coverImageIsLoading: true,
    coverImageError: undefined
  };
};

const stopCoverImageLoading = (state: CategoriesState) => {
  return {
    ...state,
    coverImageIsLoading: false
  };
};

const withCoverImageError = (state: CategoriesState, error: DomainErrorValue) => {
  return {
    ...state,
    coverImageError: error.body
  };
};

const withCoverImages = (state: CategoriesState, coverImages: Asset[]) => {
  return {
    ...state,
    coverImages: coverImages
  };
};

const startSigningUrlLoading = (state: CategoriesState) => {
  return {
    ...state,
    signingUrlIsLoading: true,
    signingUrlError: undefined
  };
};

const stopSigningUrlLoading = (state: CategoriesState) => {
  return {
    ...state,
    signingUrlIsLoading: false
  };
};

const withSigningUrl = (state: CategoriesState, signingUrl: string) => {
  return {
    ...state,
    signingUrl: signingUrl
  };
};

const withSigningError = (state: CategoriesState, error: DomainErrorValue) => {
  return {
    ...state,
    signingUrlError: error.body
  };
};

const startDeleteLoading = (state: CategoriesState) => {
  return {
    ...state,
    isDeleteLoading: true,
    deleteError: undefined
  };
};

const withDeleteError = (state: CategoriesState, error: DomainErrorValue) => {
  return {
    ...state,
    isDeleteLoading: false,
    deleteError: error.body
  };
};

const withChangeRedirect = (state: CategoriesState) => {
  return {
    ...state,
    isDeleteLoading: false,
    onChangeRedirect: true
  };
};

const withoutChangeRedirect = (state: CategoriesState) => {
  return {
    ...state,
    onChangeRedirect: false
  };
};

export {
  categoriesReducer
};
