import Mapper from "../../Mapper";
import PodcastMapper from "./PodcastMapper";
export default class PodcastParentMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
    }
    from(entity) {
        return entity.podcasts.map((podcast) => this.podcastMapper.from(podcast));
    }
}
