import React from "react";
import {
  HasChildren,
  Clickable,
  Stylable,
  Colorable,
  Disablable,
  Hidden
} from "../../shared/Props";
import {
  Button
} from "@material-ui/core";
import {
  CSSProperties
} from "@material-ui/styles";

const style: CSSProperties = {
  outline: "none"
};

interface SecondaryButtonProps {
  fullWidth?: boolean;
}

const SecondaryButton = (
  props: HasChildren &
    Clickable &
    Stylable &
    Colorable &
    Disablable &
    Hidden &
    SecondaryButtonProps
) => (
  <Button
    fullWidth={props.fullWidth}
    hidden={props.hidden}
    style={{
      ...style, ...props.extraStyle
    }}
    onClick={props.onClick}
    variant="outlined"
    color={!!props.color ? props.color : "secondary"}
    disabled={props.disabled}
  >
    {props.children}
  </Button>
);

export {
  SecondaryButton
};
