import {
  AppRootActionType
} from "./AppRootActions";
import {
  Action,
  AnyAction
} from "redux";
import {
  asSuccess
} from "../../../shared/Actions";
import {
  DomainErrorValue, UserSession
} from "@rewindit/domain";
import {
  Optional
} from "../../../shared/Optional";

export interface AppRootState {
  userSession: Optional<UserSession>;
  errorModal: Optional<string>;
  showSessionExpiredModal: boolean;
  showAdminRequiredModal: boolean;
  showMenuDialog: boolean;
  sessionEndedRedirect: boolean;
  sessionStartedRedirect: boolean;
}

const initialState: AppRootState = {
  userSession: undefined,
  errorModal: undefined,
  showSessionExpiredModal: false,
  showAdminRequiredModal: false,
  showMenuDialog: false,
  sessionEndedRedirect: false,
  sessionStartedRedirect: false
};

const appRootReducer = (
  state: AppRootState = initialState,
  action: AnyAction
): AppRootState => {
  switch (action.type) {

  case AppRootActionType.USER_AUTHENTICATED:
    return userAuthenticated(state, action.userSession);

  case AppRootActionType.CLOSE_ADMIN_REQUIRED_DIALOG:
    return hideAdminRequired(state);

  case AppRootActionType.CLOSE_ERROR_DIALOG:
    return clearModalError(state);

  case AppRootActionType.CLEAR_SESSION:
    return hideSessionExpired(clearUserSession(state));

  case AppRootActionType.SHOW_MENU_DIALOG:
    return showMenuDialog(state);

  case AppRootActionType.CLOSE_MENU_DIALOG:
    return closeMenuDialog(state);

  case asSuccess(AppRootActionType.LOGOUT):
    return sessionEndedRedirect(clearUserSession(state));

  default:
    if (anySessionExpired(action)) {
      return showSessionExpired(state);
    } else if (anyAdminRequired(action)) {
      return showAdminRequired(state);
    } else if (anyModalError(action)) {
      return showModalError(state, action.result.error);
    } else {
      return {
        ...state
      };
    }
  }
};

const clearUserSession = (state: AppRootState) => {
  return {
    ...state, userSession: undefined
  };
};

const userAuthenticated = (state: AppRootState, userSession: UserSession) => {
  return {
    ...state,
    userSession: userSession,
    sessionStartedRedirect: true
  };
};

const clearModalError = (state: AppRootState) => {
  return {
    ...state,
    errorModal: undefined
  };
};

const showModalError = (state: AppRootState, error: DomainErrorValue) => {
  return {
    ...state,
    errorModal: error.body
  };
};

const showSessionExpired = (state: AppRootState) => {
  return {
    ...state,
    showSessionExpiredModal: true,
    userSession: undefined
  };
};

const hideSessionExpired = (state: AppRootState) => {
  return {
    ...state,
    showSessionExpiredModal: false
  };
};

const showAdminRequired = (state: AppRootState) => {
  return {
    ...state,
    showAdminRequiredModal: true,
    userSession: undefined
  };
};

const hideAdminRequired = (state: AppRootState) => {
  return {
    ...state,
    showAdminRequiredModal: false,
    userSession: undefined
  };
};

const showMenuDialog = (state: AppRootState) => {
  return {
    ...state,
    showMenuDialog: true
  };
};

const closeMenuDialog = (state: AppRootState) => {
  return {
    ...state,
    showMenuDialog: false
  };
};

const sessionEndedRedirect = (state: AppRootState) => {
  return {
    ...state,
    sessionEndedRedirect: true
  };
};

const anySessionExpired = (action?: AnyAction): boolean => {
  return action != null && (action as Action<string>).type.includes("SESSION_EXPIRED");
};

const anyAdminRequired = (action?: AnyAction): boolean => {
  return action != null && (action as Action<string>).type.includes("ADMIN_REQUIRED");
};

const anyModalError = (action?: AnyAction): boolean => {
  return (
    action != null &&
    (action as Action<string>).type.includes("MODAL_ERROR") &&
    action.result != null &&
    action.result.error != null
  );
};

export {
  appRootReducer
};
