import React, {
  useEffect
} from "react";
import {
  Selected
} from "../../shared/Events";
import {
  AppRootState
} from "./model/AppRootReducer";
import {
  ModalErrorView
} from "./view/ModalErrorView";
import {
  SessionExpiredView
} from "./view/SessionExpiredView";
import LoginConnect from "../user/login/LoginConnect";
import {
  AdminRequiredView
} from "./view/AdminRequiredView";
import DashboardView from "./view/DashboardView";
import MenuProps from "./view/AppMenuProps";
import {
  MenuDialogView
} from "./view/MenuDialogView";
import {
  Route
} from "react-router-dom";
import ScheduleConnect from "../portal/schedule/ScheduleConnect";
import ArtistsConnect from "../portal/artists/ArtistsConnect";
import CategoriesConnect from "../portal/categories/CategoriesConnect";
import TagsConnect from "../portal/tags/TagsConnect";
import CommentsConnect from "../portal/comments/CommentsConnect";
import UsersConnect from "../portal/users/UsersConnect";
import AssetsConnect from "../portal/assets/AssetsConnect";

export interface AppRootComponentProps extends AppRootState, MenuProps {
  openMenu: Selected;
  closeMenu: Selected;
  closeErrorDialog: () => void;
  closeSessionExpiredDialog: () => void;
  closeAdminRequiredDialog: () => void;
  navigateToRoot: () => void;
  navigateToSchedule: () => void;
  navigateToUnauthenticated: () => void;
  navigateToAddSchedule: () => void;
  navigateToAddPlaylist: () => void;
  navigateToAddArtist: () => void;
  navigateToAddCategory: () => void;
  navigateToUploadCoverImage: () => void;
  navigateToUploadAudio: () => void;
}

const AppRootComponent = (props: AppRootComponentProps) => {
  const {
    sessionEndedRedirect,
    sessionStartedRedirect
  } = props;

  useEffect(() => {
    if (sessionStartedRedirect) {
      props.navigateToRoot();
    }
    if (sessionEndedRedirect) {
      props.navigateToRoot();
    }
  }, [props, sessionEndedRedirect, sessionStartedRedirect]);

  return (
    <>
      <LoginOrApp {...props} />
    </>
  );
};

const LoginOrApp = (props: AppRootComponentProps) => {
  if (props.userSession == null) {
    return (
      <>
        <>
          <LoginConnect />
          <AdminRequiredView {...props} />
        </>
      </>
    );
  } else {
    return (
      <>
        <DashboardView {...props}>
          <Route
            path={["/schedule", "/playlists"]}
            component={ScheduleConnect}
          />
          <Route path={"/artists"} component={ArtistsConnect} />
          <Route path={"/categories"} component={CategoriesConnect} />
          <Route path={"/tags"} component={TagsConnect} />
          <Route path={"/comments"} component={CommentsConnect} />
          <Route path={"/users"} component={UsersConnect} />
          <Route path={"/assets"} component={AssetsConnect} />
        </DashboardView>
        <ModalErrorView {...props} />
        <AdminRequiredView {...props} />
        <SessionExpiredView {...props} />
        <MenuDialogView {...props} />
      </>
    );
  }
};

export {
  AppRootComponent
};
