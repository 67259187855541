import React from "react";
import {
  Grid
} from "@material-ui/core";
import DatePickerView from "../../../../uikit/form/DatePickerView";
import TimePickerView from "../../../../uikit/form/TimePickerView";
import {
  FlexOneLeftDiv,
  FlexOneRightDiv
} from "../../../../uikit/div/FlexOneDiv";
import {
  Stylable
} from "../../../../shared/Props";

interface ScheduleDateTimeViewProps {
  dateValue?: number | Date;
  onDateChanged: (timestamp: number) => void;
  timeValue?: number | Date;
  onTimeChanged: (timestamp: number) => void;
}

const ScheduleDateTimeView = (props: ScheduleDateTimeViewProps & Stylable) => {
  return (
    <Grid container direction="row" style={props.extraStyle}>
      <FlexOneLeftDiv>
        <DatePickerView
          label="Start Date"
          value={props.dateValue}
          onValueChanged={props.onDateChanged}
        />
      </FlexOneLeftDiv>
      <FlexOneRightDiv>
        <TimePickerView
          useUtc
          label="Start Time"
          value={props.timeValue}
          onValueChanged={props.onTimeChanged}
        />
      </FlexOneRightDiv>
    </Grid>
  );
};

export default ScheduleDateTimeView;
