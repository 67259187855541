import { DomainResponse } from "../../DomainResponse";
import { fetchUsers, fetchUserCount } from "@rewindit/data";
import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import UserParentMapper from "./mapper/UserParentMapper";
import UserCountMapper from "./mapper/UserCountMapper";
import { getToken } from "../UserSessionRepository";
const userParentMapper = new UserParentMapper();
const userCountMapper = new UserCountMapper();
const getUsers = (skip, limit, domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchUsers(token, skip, limit, domainConfig.baseUrl)), map((userApi) => userParentMapper.mapFrom(userApi)), catchError((error) => of(DomainResponse.createError(error))));
};
const getUsersCount = (domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchUserCount(token, domainConfig.baseUrl)), map((userApi) => userCountMapper.mapFrom(userApi)), catchError((error) => of(DomainResponse.createError(error))));
};
const getInitialUsersWithCount = (domainConfig) => {
    return getUsers(-1, 100, domainConfig).pipe(mergeMap((usersResponse) => getUsersCount(domainConfig).pipe(map((userCountResponse) => {
        if (usersResponse.entity === undefined)
            return DomainResponse.createFatalError();
        if (userCountResponse.entity === undefined)
            return DomainResponse.createFatalError();
        return DomainResponse.createSuccess({
            users: usersResponse.entity,
            count: userCountResponse.entity
        });
    }))));
};
export { getUsers, getUsersCount, getInitialUsersWithCount };
