import { RxFetch } from "../RxFetch";
export const fetchArtistTags = (artistId, baseUrl) => {
    return new RxFetch(baseUrl).get(`/tag/artist/${artistId}`);
};
export const fetchTagAutocomplete = (hint, baseUrl) => {
    return new RxFetch(baseUrl).get(`/tag/filter/${hint}`);
};
export const fetchTags = (baseUrl) => {
    return new RxFetch(baseUrl).get("/tag/");
};
export const deleteTag = (name, token, baseUrl) => {
    return new RxFetch(baseUrl).delete(`/tag/${name}`, {
        "X-Token": token
    });
};
