import React from "react";
import DropdownView from "./DropdownView";
import {
  Grid
} from "@material-ui/core";
import {
  FlexOneLeftDiv,
  FlexOneMiddleDiv,
  FlexOneRightDiv
} from "../div/FlexOneDiv";

interface DurationPickerViewProps {
  hoursLabel: string,
  hours: number;
  hoursChanged: (hours: number) => void;
  minutesLabel: string,
  minutes: number;
  minutesChanged: (minutes: number) => void;
  secondsLabel: string,
  seconds: number;
  secondsChanged: (seconds: number) => void;
}

const hours = () => {
  return [...Array(24).keys()].map((number) => ({
    value: number,
    label: number < 10 ? `0${number.toString()}` : number.toString()
  }));
};

const minutesOrSeconds = () => {
  return [...Array(60).keys()].map((number) => ({
    value: number,
    label: number < 10 ? `0${number.toString()}` : number.toString()
  }));
};

const DurationPickerView = (props: DurationPickerViewProps) => (
  <Grid container>
    <FlexOneLeftDiv>
      <DropdownView
        label={props.hoursLabel}
        value={props.hours}
        options={hours()}
        onValueChanged={(value) => {
          props.hoursChanged(value);
        }}
      />
    </FlexOneLeftDiv>
    <FlexOneMiddleDiv>
      <DropdownView
        label={props.minutesLabel}
        value={props.minutes}
        options={minutesOrSeconds()}
        onValueChanged={(value) => {
          props.minutesChanged(value);
        }}
      />
    </FlexOneMiddleDiv>
    <FlexOneRightDiv>
      <DropdownView
        label={props.secondsLabel}
        value={props.seconds}
        options={minutesOrSeconds()}
        onValueChanged={(value) => {
          props.secondsChanged(value);
        }}
      />
    </FlexOneRightDiv>
  </Grid>
);

export default DurationPickerView;
