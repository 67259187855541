import HomeMapper from "./mapper/HomeMapper";
import { DomainResponse } from "../DomainResponse";
import { fetchHome, getUser } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { podcastsWithStars } from "../podcast/PodcastWithStars";
const homeMapper = new HomeMapper();
export const getHome = (domainConfig) => {
    return fetchHome(domainConfig.baseUrl).pipe(map((api) => homeMapper.mapFrom(api)), map((response) => response.entity), mergeMap((home) => {
        if (home === undefined)
            return of(DomainResponse.createFatalError());
        return getHomeWithPodcastStarState(home).pipe(mergeMap((home) => getHomeWithUserSession(home)), map((home) => DomainResponse.createSuccess(home)));
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getHomeWithPodcastStarState = (home) => {
    return podcastsWithStars(home.released).pipe(map((podcastsWithStars) => ({
        live: home.live,
        categories: home.categories,
        released: podcastsWithStars,
        upcoming: home.upcoming,
        userSession: undefined
    })));
};
const getHomeWithUserSession = (home) => {
    return getUser().pipe(map((userEntity) => ({
        live: home.live,
        categories: home.categories,
        released: home.released,
        upcoming: home.upcoming,
        userSession: unwrapUserEntity(userEntity)
    })));
};
const unwrapUserEntity = (userEntity) => {
    if (userEntity != null) {
        return {
            id: userEntity.id,
            username: userEntity.username,
            token: userEntity.token,
            userPushPreferences: {
                weekly: userEntity.emailWeekly
            },
            stars: null,
            subscriptions: null
        };
    }
    else {
        return undefined;
    }
};
