import { putRecentlyPlayed, fetchRecentlyPlayed } from "@rewindit/data";
import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { DomainResponse } from "../../DomainResponse";
import { getToken, getUserId } from "../UserSessionRepository";
import RecentlyPlayedPodcastParentMapper from "./mapper/RecentlyPlayedPodcastParentMapper";
const recentlyPlayedPodcastParentMapper = new RecentlyPlayedPodcastParentMapper();
export const saveRecentlyPlayed = (podcastId, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putRecentlyPlayed(podcastId, token, domainConfig.baseUrl).pipe(map(() => DomainResponse.createSuccessEmpty()))), catchError(() => of(DomainResponse.createSuccessEmpty())));
};
export const getRecentlyPlayedForAuthUser = (skip, limit, domainConfig) => {
    return getUserId().pipe(mergeMap((userId) => fetchRecentlyPlayed(skip, limit, userId, domainConfig.baseUrl).pipe(map((api) => recentlyPlayedPodcastParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))))));
};
