import React, {
  useEffect, useState
} from "react";
import {
  useFormik
} from "formik";
import {
  Optional
} from "../../../../shared/Optional";
import TextInputView from "../../../../uikit/form/TextInputView";
import FormRow from "../../../../uikit/form/FormRow";
import {
  FlexOneLeftDiv,
  FlexOneRightDiv
} from "../../../../uikit/div/FlexOneDiv";
import MultilineTextInputView from "../../../../uikit/form/MultilineTextInputView";
import {
  Artist, ArtistFormValues, Asset, getArtistName
} from "@rewindit/domain";
import FormGroupView from "../../../../uikit/form/FormGroup";
import FormContainerView from "../../../../uikit/form/FormContainerView";
import ContentDialog from "../../../../uikit/dialog/ContentDialog";
import {
  useParams
} from "react-router";
import {
  withRouter, RouteComponentProps, Prompt
} from "react-router-dom";
import CoverImagePicker from "../../../../uikit/file/CoverImagePicker";
import {
  ProgressButton
} from "../../../../uikit/button/ProgressButton";
import SuccessDiv from "../../../../uikit/div/SuccessDiv";
import AssetPickerView from "../../assets/view/AssetPickerView";
import {
  AssetType
} from "../../assets/model/AssetType";
import {
  Autocomplete
} from "@material-ui/lab";
import {
  TextField
} from "@material-ui/core";

interface ArtistFormProps extends RouteComponentProps {
  artists: Artist[];
  tags: string[];
  isFormLoading: boolean;
  formUpdated: boolean;
  onChangeRedirect: boolean;
  coverImages: Optional<Asset[]>;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  signingUrlIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
  loadArtistCoverImages: (name: string) => void;
  loadSigningUrl: (name: string, type: AssetType) => void;
  createArtist: (values: ArtistFormValues) => void;
  updateArtist: (id: string, values: ArtistFormValues) => void;
  reloadArtists: () => void;
}

const ArtistForm = (props: ArtistFormProps) => {
  const {
    id
  } = useParams<{ id: string }>();

  const artist = props.artists.find((artist) => artist.id === id);

  const {
    coverImages, onChangeRedirect, reloadArtists
  } = props;

  useEffect(() => {
    if (onChangeRedirect) {
      reloadArtists();
    }
  }, [onChangeRedirect, reloadArtists]);

  const [isCoverPickerOpen, setOpenCoverPicker] = useState(false);

  const [coverImageTabSelected, setCoverImageTabSelected] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: artist?.name ?? "",
      quote: artist?.quote ?? "",
      description: artist?.description ?? "",
      userId: artist?.userId ?? "",
      websiteUrl: artist?.websiteUrl ?? "",
      order: artist?.order ?? "",
      imgUrl: artist?.imgUrl ?? "",
      tags: artist?.tags ?? undefined
    },
    onSubmit: (values) => {
      if (id === undefined) {
        props.createArtist(values);
      } else {
        props.updateArtist(id, values);
      }
    }
  });

  return (
    <>
      <Prompt
        when={formik.dirty && !formik.isSubmitting}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ContentDialog
        onClose={() => {
          setOpenCoverPicker(false);
          setCoverImageTabSelected(0);
        }}
        isOpen={isCoverPickerOpen}
        title={"Select an artist cover image"}
      >
        <div style={{
          height: 420, width: "100%"
        }}>
          <AssetPickerView
            tabSelected={coverImageTabSelected}
            groupName={getArtistName(id, props.artists)}
            selectedAssetType="cover"
            signingUrl={props.signingUrl}
            signingUrlLoading={props.signingUrlIsLoading}
            signingUrlError={props.signingUrlError}
            coverImageIsLoading={props.coverImageIsLoading}
            coverImageError={props.coverImageError}
            audioFiles={props.coverImages}
            audioFilesIsLoading={props.coverImageIsLoading}
            audioFilesError={props.coverImageError}
            loadCoverImages={props.loadArtistCoverImages}
            loadAudioFiles={props.loadArtistCoverImages}
            itemSelected={(asset) => {
              setOpenCoverPicker(false);
              formik.values.imgUrl = asset.url;
            }}
            loadSigningUrl={props.loadSigningUrl}
            setTabSelected={setCoverImageTabSelected}
            coverImages={coverImages}
          />
        </div>
      </ContentDialog>
      <SuccessDiv
        visible={props.formUpdated}
        successMessage="Artist update successful"
      />
      <FormContainerView>
        <FormGroupView title="Details">
          <FormRow>
            <FlexOneLeftDiv>
              <TextInputView
                label="Name"
                name="name"
                value={formik.values.name}
                required={true}
                onChanged={formik.handleChange}
              />
            </FlexOneLeftDiv>
            <FlexOneRightDiv>
              <TextInputView
                label="Quote"
                name="quote"
                value={formik.values.quote}
                required={true}
                onChanged={formik.handleChange}
              />
            </FlexOneRightDiv>
          </FormRow>
          <FormRow>
            <MultilineTextInputView
              label="Description"
              name="description"
              value={formik.values.description}
              required={true}
              rows={5}
              onChanged={formik.handleChange}
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              fullWidth
              multiple
              onChange={(_, newValue) => {
                formik.setFieldValue("tags", newValue);
              }}
              options={props.tags}
              value={formik.values.tags}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Tags"
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
          </FormRow>
          <FormRow>
            <TextInputView
              label="User ID"
              name="userId"
              value={formik.values.userId}
              onChanged={formik.handleChange}
            />
          </FormRow>
          <FormRow>
            <FlexOneLeftDiv>
              <TextInputView
                label="Website URL"
                name="websiteUrl"
                value={formik.values.websiteUrl}
                required={true}
                onChanged={formik.handleChange}
              />
            </FlexOneLeftDiv>
            <FlexOneRightDiv>
              <TextInputView
                label="Order"
                name="order"
                value={formik.values.order}
                required={true}
                onChanged={formik.handleChange}
              />
            </FlexOneRightDiv>
          </FormRow>
        </FormGroupView>
        <FormGroupView title="Cover image">
          <CoverImagePicker
            coverImage={formik.values.imgUrl}
            isCoverPickerOpen={isCoverPickerOpen}
            onOpenCoverPicker={setOpenCoverPicker}
          />
        </FormGroupView>
        <ProgressButton
          fullWidth
          isLoading={props.isFormLoading}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </ProgressButton>
      </FormContainerView>
    </>
  );
};

export default withRouter(ArtistForm);
