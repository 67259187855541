import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError,
  withDeleteError,
  withScrollPosition,
  startDeleteLoading,
  stopDeleteLoading
} from "../../../../shared/Actions";
import {
  TagsActionType
} from "./TagsActions";
import {
  TagAssociation
} from "@rewindit/domain";

export interface TagsState {
  tagAssociation: Optional<TagAssociation>;
  tags: Optional<string[]>;
  isLoading: boolean;
  error: Optional<string>;
  scrollPosition: number;
  hasMoreItems: boolean;
  isDeleteLoading: boolean;
  deleteError: Optional<string>
  isDeleteTagDialogReady: boolean;
}

const initialState: TagsState = {
  tagAssociation: undefined,
  tags: undefined,
  isLoading: false,
  error: undefined,
  scrollPosition: 0,
  hasMoreItems: true,
  isDeleteLoading: false,
  deleteError: undefined,
  isDeleteTagDialogReady: true
};

export const tagsReducer = (
  state: TagsState = initialState,
  action: AnyAction
): TagsState => {
  switch (action.type) {
  case asStarted(TagsActionType.FETCH_TAGS):
    return startLoading(state);
  case asSuccess(TagsActionType.FETCH_TAGS):
    return stopLoading(withTags(state, action.result.entity));
  case asError(TagsActionType.FETCH_TAGS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(TagsActionType.FETCH_TAG_ASSOCIATED_PODCAST):
    return startLoading(state);
  case asSuccess(TagsActionType.FETCH_TAG_ASSOCIATED_PODCAST):
    return stopLoading(withTagAssociation(state, action.result.entity));
  case asError(TagsActionType.FETCH_TAG_ASSOCIATED_PODCAST):
    return stopLoading(withError(state, action.result.error));

  case asStarted(TagsActionType.REMOVE_TAG):
    return startDeleteLoading(state);
  case asSuccess(TagsActionType.REMOVE_TAG):
    return stopDeleteLoading(closeDeleteTagDialog(removeTag(state, action.name)));
  case asError(TagsActionType.REMOVE_TAG):
    return stopDeleteLoading(withDeleteError(state, action.result.error));

  case TagsActionType.SET_SCROLL_POSITION:
    return withScrollPosition(state, action.position);

  case TagsActionType.RESET_DELETE_TAG_DILOAG:
    return resetDeleteTagDialog(state)

  default:
    return {
      ...state
    };
  }
};

const withTags = (state: TagsState, tags: string[]) => {
  return {
    ...state,
    tags: tags
  };
};

const withTagAssociation = (
  state: TagsState,
  tagAssociation: TagAssociation
) => {
  if (tagAssociation.podcasts.length === 0)
    return {
      ...state, hasMoreItems: false
    };
  return {
    ...state,
    tagAssociation: tagAssociation
  };
};

const closeDeleteTagDialog = (state: TagsState) => {
  return {
    ...state,
    isDeleteTagDialogReady: false
  }
}

const resetDeleteTagDialog = (state: TagsState) => {
  return {
    ...state,
    isDeleteTagDialogReady: true
  }
}

const removeTag = (state: TagsState, name: string) => {
  return {
    ...state,
    tags: (state.tags ?? []).filter((tag) => tag !== name)
  }
}