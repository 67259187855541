import React, {
  ChangeEvent
} from "react";
import {
  Stylable,
  Disablable
} from "../../shared/Props";
import {
  TextField
} from "@material-ui/core";

interface TextInputViewProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  name?: string;
  type?: string;
  onChanged: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const TextInputView = (props: TextInputViewProps & Stylable & Disablable) => (
  <TextField
    fullWidth
    style={props.extraStyle}
    placeholder={props.placeholder}
    label={props.label}
    variant="outlined"
    value={props.value || ""}
    name={props.name}
    onChange={props.onChanged}
    required={props.required}
    type={props.type}
    disabled={props.disabled}
    InputLabelProps={{
      shrink: true
    }}
    color="secondary"
  />
);

export default TextInputView;
