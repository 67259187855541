import VoidMapper from "../../VoidMapper";
import { postResetPassword } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DomainResponse } from "../../DomainResponse";
const voidMapper = new VoidMapper();
const resetPassword = (emailAddress, domainConfig) => {
    return postResetPassword(emailAddress, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export { resetPassword };
