import React, {
  useEffect
} from "react";
import {
  Optional
} from "../../../../shared/Optional";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import {
  RouteComponentProps, withRouter
} from "react-router-dom";
import {
  useParams
} from "react-router";
import unwrapValue from "../../../../shared/UnwrapValue";
import {
  TagAssociation
} from "@rewindit/domain";
import ScheduleListView from "../../schedule/view/ScheduleListView";
import {
  SelectedId
} from "../../../../shared/Events";

interface TagAssociationViewProps extends RouteComponentProps {
  tagAssociation: Optional<TagAssociation>;
  isLoading: boolean;
  error: Optional<string>;
  scrollPosition: number;
  hasMoreItems: boolean;
  loadAssociatedPodcasts: (tag: string) => void;
  podcastSelected: SelectedId;
  setScrollPosition: (position: number) => void;
}

const TagAssociationView = (props: TagAssociationViewProps) => {
  const {
    tag
  } = useParams<{ tag: string }>();

  const {
    tagAssociation, loadAssociatedPodcasts
  } = props;

  useEffect(() => {
    if (tagAssociation === undefined || tagAssociation.tagName !== tag) {
      loadAssociatedPodcasts(tag);
    }
  }, [tag, loadAssociatedPodcasts, tagAssociation]);

  return (
    <ProgressOrError
      {...props}
      retryClicked={() => {
        props.loadAssociatedPodcasts(tag);
      }}
    >
      {unwrapValue(
        (tagAssociation) => (
          <ScheduleListView
            {...props}
            podcasts={tagAssociation.podcasts}
            isLoadingMore={false}
            retryClicked={() => {
              props.loadAssociatedPodcasts(tag);
            }}
            hasMoreItems={props.hasMoreItems}
            setScrollPosition={props.setScrollPosition}
            scrollPosition={props.scrollPosition}
            showSearch={false}
            searchActive={false}
            isLoadingList={false}
            errorList={undefined}
            artists={[]}
          />
        ),
        tagAssociation
      )}
    </ProgressOrError>
  );
};

export default withRouter(TagAssociationView);
