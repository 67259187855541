import React from "react";
import {
  SmallProgressView
} from "../progress/SmallProgressView";
import {
  PrimaryButton
} from "./PrimaryButton";
import {
  Clickable
} from "../../shared/Props";
import {
  CSSProperties
} from "@material-ui/styles";

const parentStyle: CSSProperties = {
  position: "relative",
  height: 34,
  width: "100%"
};

interface StubProgressButtonProps {
  fullWidth?: boolean;
}

const StubProgressButton = (props: Clickable & StubProgressButtonProps) => {
  return (
    <div style={{
      ...parentStyle
    }}>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          top: 5,
          left: 0,
          right: 0,
          width: 28,
          height: 28
        }}
      >
        <SmallProgressView />
      </div>
      <div style={{
        position: "absolute", width: "100%"
      }}>
        <PrimaryButton
          fullWidth={props.fullWidth}
          extraStyle={{
            width: "100%"
          }}
          onClick={props.onClick}
          disabled
        >
          &nbsp;
        </PrimaryButton>
      </div>
    </div>
  );
};

export {
  StubProgressButton
};
