import { RxFetch } from "../RxFetch";
const fetchCovers = (prefix, token, baseUrl) => {
    return new RxFetch(baseUrl).get(`/asset/cover/${prefix}`, {
        "X-Token": token
    });
};
const fetchAudio = (prefix, token, baseUrl) => {
    return new RxFetch(baseUrl).get(`/asset/audio/${prefix}`, {
        "X-Token": token
    });
};
const getSignedUrl = (bucketName, mimeType, key, ext, token, baseUrl) => {
    return new RxFetch(baseUrl).get(`/asset/sign/${bucketName}?mimeType=${mimeType}${keyValue(key)}&ext=${ext}`, {
        "X-Token": token
    });
};
const keyValue = (key) => {
    return key !== undefined ? `&key=${key}` : "";
};
export { fetchCovers, fetchAudio, getSignedUrl };
