import Mapper from "../../../Mapper";
export default class SubscriptionMapper extends Mapper {
    fromLocal(entity) {
        return {
            id: entity.id,
            contentId: entity.contentId,
            title: entity.subscriptionTitle,
            type: this.type(entity.type),
            dateCreated: entity.dateCreated
        };
    }
    from(entity) {
        return {
            id: entity.id,
            contentId: entity.contentId,
            type: this.type(entity.type),
            title: entity.subscriptionTitle,
            dateCreated: entity.dateCreated
        };
    }
    type(rawType) {
        switch (rawType) {
            case "ARTIST":
            case "CATEGORY":
                return rawType;
            default:
                return "UNKNOWN";
        }
    }
}
