import Mapper from "../../../Mapper";
import { fullDescriptiveDate } from "../../../DateTimeFormatter";
export default class UserMapper extends Mapper {
    from(entity) {
        return {
            id: entity.id,
            username: entity.username,
            emailAddress: entity.emailAddress,
            dateCreated: fullDescriptiveDate(entity.dateCreated),
            timestamp: entity.dateCreated,
            isActivated: entity.isActivated
        };
    }
}
