import React from "react";
import {
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import {
  Chip
} from "@material-ui/core";

interface TagCloudViewProps {
  tags: string[];
}

const root: CSSProperties = {
  display: "flex",
  flexWrap: "wrap"
};

const TagCloudView = (props: TagCloudViewProps) => (
  <div style={root}>
    {props.tags.map((tag) => (
      <Chip key={tag} label={tag} style={{
        marginTop: 4, marginRight: 4
      }} />
    ))}
  </div>
);

export default TagCloudView;
