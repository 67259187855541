import "intersection-observer-polyfill";
import React from "react";
import IntersectionVisible from "react-intersection-visible";

interface IntersectionVisibleWrapperProps {
  onShow: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const IntersectionVisibleWrapper = (props: IntersectionVisibleWrapperProps) => (
  <IntersectionVisible
    onShow={props.onShow}
    className="element"
  >
    {props.children}
  </IntersectionVisible>
)