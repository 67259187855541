import React, {
  useEffect, useState
} from "react";
import {
  AssetsState
} from "./model/AssetsReducer";
import {
  Paper
} from "@material-ui/core";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import ArtistDropdown from "../../../uikit/form/ArtistDropdown";
import unwrapValue from "../../../shared/UnwrapValue";
import {
  Optional
} from "../../../shared/Optional";
import DropdownView from "../../../uikit/form/DropdownView";
import {
  AssetType
} from "../assets/model/AssetType";
import AssetPickerView from "./view/AssetPickerView";
import {
  Artist, Asset, getArtistName
} from "@rewindit/domain";

interface AssetsComponentProps extends AssetsState {
  loadArtists: () => void;
  loadSigningUrl: (name: string, type: AssetType) => void;
  loadCoverImages: (name: string) => void;
  loadAudioFiles: (name: string) => void;
  itemSelected: (url: Asset) => void;
}

interface AssetTypeOption {
  value: AssetType
  label: string
}

const assetTypeOptions: AssetTypeOption[] = [
  {
    value: "cover", label: "Cover image"
  },
  {
    value: "audio", label: "Audio file"
  }
];

const AssetsComponent = (props: AssetsComponentProps) => {
  const {
    artists, loadArtists
  } = props;

  const [selectedAssetType, setSelectedAssetType] = useState<
    Optional<AssetType>
  >(undefined);

  const [selectedArtist, setSelectedArtist] = useState<Optional<string>>(
    undefined
  );

  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    if (artists === undefined) {
      loadArtists();
    }
  }, [artists, loadArtists]);

  return (
    <>
      <ProgressOrError
        retryClicked={() => {
          loadArtists();
        }}
        {...props}
      >
        {unwrapValue(
          (artists) => (
            <Paper style={{
              padding: 16
            }}>
              <div>
                <div style={{
                  marginBottom: 16
                }}>
                  <ArtistDropdown
                    artists={artists}
                    name="artist"
                    value={artists.find(
                      (artist) => artist.id === selectedArtist
                    )}
                    onValueChanged={(artist: Artist) => {
                      setTabSelected(0);
                      setSelectedArtist(artist.id);
                    }}
                  />
                </div>
                <div>
                  <DropdownView<AssetType>
                    label="Asset type"
                    name="asset_type"
                    value={selectedAssetType}
                    options={assetTypeOptions}
                    onValueChanged={(value) => {
                      setTabSelected(0);
                      setSelectedAssetType(value);
                    }}
                    required
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: 16
                }}
                hidden={!selectedAssetType || !selectedArtist}
              >
                <AssetPickerView
                  {...props}
                  tabSelected={tabSelected}
                  setTabSelected={setTabSelected}
                  groupName={getArtistName(selectedArtist, artists)}
                  selectedAssetType={selectedAssetType}
                />
              </div>
            </Paper>
          ),
          artists
        )}
      </ProgressOrError>
    </>
  );
};

export default AssetsComponent;
