import React from "react";
import {
  CSSProperties
} from "@material-ui/styles";
import {
  Optional
} from "../../shared/Optional";
import {
  HeaderBaselineDiv
} from "./HeaderBaselineDiv";
import {
  Typography
} from "@material-ui/core";

interface ErrorDivProps {
  errorMessage: Optional<string>;
}

const style: CSSProperties = {
  color: "#FF0000"
};

export const ErrorDiv = (props: ErrorDivProps) => {
  if (props.errorMessage != null) {
    return (
      <HeaderBaselineDiv>
        <Typography variant="body1" style={style}>
          {props.errorMessage}
        </Typography>
      </HeaderBaselineDiv>
    );
  } else {
    return <></>;
  }
};
