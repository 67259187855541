import React, {
  useState, useEffect
} from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";
import {
  Comment
} from "@rewindit/domain";
import {
  prettyDate
} from "../../../../date/DateTimeFormatter";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import {
  SelectedId, LoadMore
} from "../../../../shared/Events";
import {
  LoadMoreProgress
} from "../../../../uikit/progress/LoadMoreProgress";
import ConfirmDialog from "../../../../uikit/dialog/ConfirmDialog";
import {
  Optional
} from "../../../../shared/Optional";
import {
  IntersectionVisibleWrapper
} from "../../../../uikit/IntersectionVisibleWrapper";

interface CommentListViewProps {
  comments: Comment[];
  deleteComment: SelectedId;
  loadMore: LoadMore;
  isLoadingMore: boolean;
  isDeleteLoading: boolean;
  deleteError: Optional<string>;
  deleteRedirect: boolean;
  reloadComments: () => void;
}

const CommentListView = (props: CommentListViewProps) => {
  const [isDeleteDialogOpen, setOpenDeleteDialog] = useState(false);

  const [pendingDeleteId, setPendingDeleteId] = useState<Optional<string>>(
    undefined
  );

  const {
    deleteRedirect, reloadComments
  } = props;

  useEffect(() => {
    if (deleteRedirect) {
      reloadComments();
    }
  }, [deleteRedirect, reloadComments]);

  return (
    <>
      <ConfirmDialog
        title="Delete comment"
        body="Are you sure you want to delete this comment?"
        isOpen={isDeleteDialogOpen}
        isLoading={props.isDeleteLoading}
        error={props.deleteError}
        handleAgree={() => {
          if (pendingDeleteId !== undefined) {
            props.deleteComment(pendingDeleteId);
          }
        }}
        handleDismiss={() => {
          setOpenDeleteDialog(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {props.comments.map((comment) => (
              <TableRow key={comment.id}>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      opacity: comment.deleted ? 0.5 : 1,
                      textDecoration: comment.deleted ? "line-through" : "none"
                    }}
                  >
                    {comment.authorName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    style={{
                      opacity: comment.deleted ? 0.5 : 1,
                      textDecoration: comment.deleted ? "line-through" : "none"
                    }}
                  >
                    {comment.body}
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{
                  width: 120
                }}>
                  <Typography
                    variant="body1"
                    style={{
                      opacity: comment.deleted ? 0.5 : 1,
                      textDecoration: comment.deleted ? "line-through" : "none"
                    }}
                  >
                    {prettyDate(comment.dateCreated)}
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{
                  width: 80
                }}>
                  <SecondaryButton
                    extraStyle={{
                      display: comment.deleted ? "none" : "block"
                    }}
                    onClick={() => {
                      setPendingDeleteId(comment.id);
                      setOpenDeleteDialog(true);
                    }}
                  >
                    Delete
                  </SecondaryButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingIndicator {...props} />
    </>
  );
};

const LoadingIndicator = (props: CommentListViewProps) => (
  <IntersectionVisibleWrapper
    onShow={() =>
      props.loadMore(props.comments[props.comments.length - 1].dateCreated)
    }
  >
    <LoadMoreProgress isLoading={props.isLoadingMore} />
  </IntersectionVisibleWrapper>
);

export default CommentListView;
