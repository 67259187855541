import React from "react";
import {
  HasChildren
} from "../../shared/Props";
import {
  Optional
} from "../../shared/Optional";
import {
  ErrorCard
} from "../ErrorCard";
import {
  Selected
} from "../../shared/Events";
import {
  ProgressView
} from "./ProgressView";

export interface ProgressOrErrorProps {
  isLoading: boolean;
  error: Optional<string>;
  retryClicked: Selected;
  empty?: boolean;
}

const progressDivStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 100
};

const ProgressOrError = (props: ProgressOrErrorProps & HasChildren): JSX.Element => {
  if (props.empty) return <>No results.</>;
  if (props.error != null)
    return <ErrorCard error={props.error} retryClicked={props.retryClicked} />;
  if (props.isLoading)
    return (
      <div style={progressDivStyle}>
        <ProgressView />
      </div>
    );
  return <>{props.children}</>;
};

export {
  ProgressOrError
};
