import React, {
  useState
  ,
  useEffect
} from "react";
import ReactS3Uploader, {
  S3Response
} from "react-s3-uploader";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../../shared/UnwrapValue";
import {
  Optional
} from "../../../../shared/Optional";
import {
  ErrorDiv
} from "../../../../uikit/div/ErrorDiv";
import {
  ContentDiv
} from "../../../../uikit/div/ContentDiv";
import {
  LinearProgress, Typography
} from "@material-ui/core";

import TextInputView from "../../../../uikit/form/TextInputView";
import {
  PrimaryButton
} from "../../../../uikit/button/PrimaryButton";
import {
  useFormik
} from "formik";
import {
  nowFormatted
} from "../../../../date/DateTimeFormatter";
import uuid from "uuid";
import {
  AssetType
} from "../../assets/model/AssetType";

interface FileUploaderViewProps {
  groupName: string;
  type: AssetType;
  mimeType: "images/jpeg" | "audio/mpeg";
  signingUrl: Optional<string>;
  signingUrlError: Optional<string>;
  signingUrlLoading: boolean;
  expectedWidth: number;
  expectedHeight: number;
  loadSigningUrl: (name: string, type: AssetType) => void;
  uploadSuccess: () => void;
}

const FileUploaderView = (props: FileUploaderViewProps) => {
  const [validationError, setValidationError] = useState<Optional<string>>(
    undefined
  );

  const [progress, setProgress] = useState<Optional<number>>(0);
  const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState<Optional<string>>("");
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentType, setCurrentType] = useState<Optional<AssetType>>(undefined);

  const {
    signingUrl, loadSigningUrl, type, groupName
  } = props;

  useEffect(() => {
    if (currentGroupName !== groupName || type !== currentType) {
      setCurrentGroupName(groupName);
      setCurrentType(type);
      if (type === "audio") {
        setFileName(undefined);
      } else {
        const fileName = groupName + "/" + uuid.v4();
        setFileName(fileName);
        loadSigningUrl(fileName, type);
      }
    }
  }, [
    signingUrl,
    loadSigningUrl,
    type,
    setFileName,
    currentGroupName,
    setCurrentGroupName,
    currentType,
    setCurrentType,
    groupName
  ]);

  const formik = useFormik({
    initialValues: {
      fileName: undefined
    },
    onSubmit: (values) => {
      const fileName = `${groupName}/${nowFormatted()} - ${values.fileName}`;
      setFileName(fileName);
      props.loadSigningUrl(fileName, type);
    }
  });

  return (
    <ProgressOrError
      isLoading={props.signingUrlLoading}
      error={props.signingUrlError}
      retryClicked={() => {
        if (fileName) {
          props.loadSigningUrl(fileName, type);
        }
      }}
    >
      <ContentDiv>
        <ErrorDiv errorMessage={validationError} />
        <LinearProgress
          style={{
            marginBottom: 16
          }}
          variant="determinate"
          value={progress}
          hidden={progress === 0 || success}
        />
        <>
          <div hidden={type === "cover" || fileName !== undefined}>
            <TextInputView
              label="File name (without extension)"
              name="fileName"
              value={formik.values.fileName}
              onChanged={formik.handleChange}
            />
            <div style={{
              marginTop: 16
            }}>
              <PrimaryButton
                fullWidth
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Next
              </PrimaryButton>
            </div>
          </div>
          <div
            hidden={fileName === "" || success || type === "cover"}
            style={{
              marginBottom: 12
            }}
          >
            <Typography variant="subtitle1">{fileName}</Typography>
          </div>
          <div hidden={fileName === undefined}>
            {unwrapValue((signingUrl) => {
              if (props.type === "audio") {
                return (
                  <ReactS3Uploader
                    hidden={success}
                    getSignedUrl={(_, callback) => {
                      callback({
                        signedUrl: signingUrl
                      });
                    }}
                    accept={props.mimeType}
                    onProgress={(
                      percent: number,
                      _status: string,
                      _file: File
                    ) => {
                      setProgress(percent);
                    }}
                    onError={(message: string) => {
                      setValidationError(message);
                    }}
                    onFinish={(result: S3Response, file: File) => {
                      props.uploadSuccess();
                      setSuccess(true);
                    }}
                    contentDisposition="auto"
                  />
                );
              } else {
                return (
                  <ReactS3Uploader
                    hidden={success}
                    getSignedUrl={(_, callback) => {
                      callback({
                        signedUrl: signingUrl
                      });
                    }}
                    accept={props.mimeType}
                    preprocess={(file: File, next: (file: File) => void) => {
                      setValidationError(undefined);
                      validateDimensions(
                        file,
                        props.expectedWidth,
                        props.expectedHeight,
                        () => {
                          setProgress(1);
                          next(file);
                        },
                        () => {
                          setValidationError(
                            `Cover image must be ${props.expectedWidth} x ${props.expectedHeight}`
                          );
                        }
                      );
                    }}
                    onProgress={(percent: number, _: string, _file: File) => {
                      setProgress(percent);
                    }}
                    onError={(message: string) => {
                      setValidationError(message);
                    }}
                    onFinish={(result: S3Response, file: File) => {
                      props.uploadSuccess();
                      setSuccess(true);
                    }}
                    contentDisposition="auto"
                  />
                );
              }
            }, props.signingUrl)}
          </div>
        </>
      </ContentDiv>
    </ProgressOrError>
  );
};

const validateDimensions = (
  file: File,
  expectedWidth: number,
  expectedHeight: number,
  done: () => void,
  error: () => void
) => {
  const img = new Image();
  img.src = window.URL.createObjectURL(file);
  img.onload = () => {
    if (img.width !== expectedWidth || img.height !== expectedHeight) {
      error();
    } else {
      done();
    }
  };
};

export default FileUploaderView;
