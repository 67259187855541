import {
  SelectInputProps
} from "@material-ui/core/Select/SelectInput";
import React from "react";
import DropdownView from "../../../../uikit/form/DropdownView";

interface ScheduleTypeDropdownProps {
  onChange: SelectInputProps["onChange"];
  value?: string;
  name: string;
}

const options = [
  {
    value: "PODCAST",
    label: "Release podcast"
  },
  {
    value: "REALTIME",
    label: "Live stream"
  },
  {
    value: "PODCAST_AFTER_REALTIME",
    label: "Live stream, release podcast on completion"
  },
  {
    value: "WEEKLY",
    label: "Weekly Recurring"
  }
];

const ScheduleTypeDropdown = (props: ScheduleTypeDropdownProps) => (
  <DropdownView<string>
    label="Schedule Type"
    name={props.name}
    value={props.value}
    options={options}
    onChange={props.onChange}
  />
);

export default ScheduleTypeDropdown;
