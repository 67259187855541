import { RxFetch } from "../../RxFetch";
const putLogin = (emailAddress, hash, pushToken, baseUrl) => {
    return new RxFetch(baseUrl).put("/user/auth/", {
        emailAddress: emailAddress,
        hash: hash,
        pushToken: pushToken
    });
};
const postResetPassword = (emailAddress, baseUrl) => {
    return new RxFetch(baseUrl).post("/user/reset/password/", {
        emailAddress: emailAddress
    });
};
const putChangePassword = (resetPasswordToken, hash, salt, baseUrl) => {
    return new RxFetch(baseUrl).put("/user/reset/password/", {
        resetPasswordToken: resetPasswordToken,
        hash: hash,
        salt: salt
    });
};
const putLoginWithFacebook = (token, userId, applicationId, pushToken, baseUrl) => {
    return new RxFetch(baseUrl).put("/user/auth/facebook/", {
        token: token,
        userId: userId,
        applicationId: applicationId,
        pushToken: pushToken
    });
};
const putLinkWithFacebook = (linkWithFacebookToken, baseUrl) => {
    return new RxFetch(baseUrl).put("/user/facebook/", {
        linkWithFacebookToken: linkWithFacebookToken
    });
};
export { putLogin, postResetPassword, putChangePassword, putLoginWithFacebook, putLinkWithFacebook };
