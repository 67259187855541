import React, {
  CSSProperties
} from "react";
import {
  ButtonBase
} from "@material-ui/core";
import {
  Selected
} from "../../shared/Events";

interface LogoButtonProps {
  selected?: Selected;
}

const style: CSSProperties = {
  outline: "none"
};

const LogoButton = (props: LogoButtonProps) => (
  <ButtonBase focusRipple style={style} onClick={props.selected}>
    <img
      src={process.env.PUBLIC_URL + "/app_icon.png"}
      alt="Rewind It"
      style={{
        width: 60, height: 60
      }}
      onClick={props.selected}
    />
  </ButtonBase>
);

export {
  LogoButton
};
