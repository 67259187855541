class ApiResponse {
    constructor(statusCode, data, error) {
        this.isSuccessful = () => {
            return this.statusCode >= 200 && this.statusCode < 300;
        };
        this.statusCode = statusCode;
        this.data = data;
        this.error = error;
    }
}
export { ApiResponse };
