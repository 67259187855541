import React, {
  useEffect
} from "react";
import {
  CategoriesState
} from "./model/CategoriesReducer";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import CategoryListView from "./view/CategoryListView";
import unwrapValue from "../../../shared/UnwrapValue";
import {
  SelectedId
} from "../../../shared/Events";
import {
  Switch, Route
} from "react-router-dom";
import CategoryForm from "./view/CategoryForm";
import {
  CategoryFormValues
} from "@rewindit/domain";
import {
  AssetType
} from "../assets/model/AssetType";

interface CategoriesComponentProps extends CategoriesState {
  loadCategories: () => void;
  categorySelected: SelectedId;
  loadCategoryCoverImages: (name: string) => void;
  loadSigningUrl: (name: string, assetType: AssetType) => void;
  deleteCategory: (id: string) => void;
  reloadCategories: () => void;
  createCategory: (values: CategoryFormValues) => void;
  updateCategory: (id: string, values: CategoryFormValues) => void;
}

const CategoriesComponent = (props: CategoriesComponentProps) => {
  const {
    categoryParent, loadCategories, onChangeRedirect
  } = props;

  useEffect(() => {
    if (categoryParent === undefined || onChangeRedirect) {
      loadCategories();
    }
  }, [categoryParent, onChangeRedirect, loadCategories]);

  return (
    <ProgressOrError
      retryClicked={() => {
        loadCategories();
      }}
      {...props}
    >
      <Switch>
        <Route path={"/categories/edit/:id"}>
          {unwrapValue(
            (categoryParent) => (
              <CategoryForm
                {...props}
                tags={categoryParent.tags}
                categories={categoryParent.categories}
              />
            ),
            categoryParent
          )}
        </Route>
        <Route path={"/categories/add"}>
          {unwrapValue(
            (categoryParent) => (
              <CategoryForm
                {...props}
                tags={categoryParent.tags}
                categories={categoryParent.categories}
              />
            ),
            categoryParent
          )}
        </Route>
        <Route path={"/"}>
          {unwrapValue(
            (categoryParent) => (
              <CategoryListView
                {...props}
                categories={categoryParent.categories}
              />
            ),
            props.categoryParent
          )}
        </Route>
      </Switch>
    </ProgressOrError>
  );
};

export default CategoriesComponent;
