import React from "react";
import AppMenuProps from "./AppMenuProps";
import {
  Dialog, Toolbar, IconButton, Icon
} from "@material-ui/core";
import {
  Selected
} from "../../../shared/Events";
import RootMenu from "./RootMenu";

interface MenuDialogProps {
  showMenuDialog: boolean;
  closeMenu: Selected;
}

const MenuDialogView = (props: MenuDialogProps & AppMenuProps) => {
  return showDialog(
    () => (
      <>
        <Dialog open={true} onClose={props.closeMenu} fullScreen={true}>
          <CloseButton {...props} />
          <RootMenu {...props} />
        </Dialog>
      </>
    ),
    props.showMenuDialog
  );
};

const CloseButton = (props: MenuDialogProps) => (
  <Toolbar>
    <IconButton
      style={{
        outline: "none"
      }}
      edge="start"
      color="inherit"
      onClick={props.closeMenu}
      aria-label="close"
    >
      <Icon>close</Icon>
    </IconButton>
  </Toolbar>
);

const showDialog = (view: () => JSX.Element, showMenuDialog: boolean): JSX.Element | null => {
  if (showMenuDialog) {
    return view();
  } else {
    return null;
  }
};

export {
  MenuDialogView
};
