import React from "react"
import {
  HasChildren
} from "../../shared/Props"

const RightDivFill = (props: HasChildren) => (
  <div style={{
    flex: 1
  }}>{props.children}</div>
)

export default RightDivFill