import React from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import {
  asTimestamp
} from "../../date/DateTimeFormatter";
import moment from "moment";
import UTCUtils from "../../date/UTCUtils";

interface TimePickerViewProps {
  label: string;
  value?: number | Date;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  includeSeconds?: boolean;
  useUtc?: boolean;
  onValueChanged: (timestamp: number) => void;
}

const TimePickerView = (props: TimePickerViewProps) => {
  const format = props.includeSeconds ? "HH:mm:ss" : "HH:mm";
  const views: ("hours" | "minutes" | "seconds")[] = props.includeSeconds
    ? ["hours", "minutes", "seconds"]
    : ["hours", "minutes"];
  const utils = props.useUtc ? UTCUtils : DateFnsUtils;
  return (
    <MuiPickersUtilsProvider utils={utils} libInstance={moment}>
      <KeyboardTimePicker
        disableToolbar={props.useUtc}
        fullWidth
        autoOk
        inputVariant="outlined"
        label={props.label}
        placeholder={props.placeholder}
        ampm={false}
        value={props.value ?? ""}
        variant="inline"
        disabled={props.disabled}
        onChange={(v) => props.onValueChanged(asTimestamp(v || ""))}
        InputLabelProps={{
          shrink: true
        }}
        required={props.required}
        color="secondary"
        views={views}
        format={format}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePickerView;
