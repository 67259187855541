import { DomainResponse } from "../DomainResponse";
import { fetchPodcastsForArtist, fetchArtistByName, fetchArtists, fetchArtistById, fetchAllArtists, subscriptionExists, postArtist, putArtist } from "@rewindit/data";
import { of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import PodcastParentMapper from "../podcast/mapper/PodcastParentMapper";
import ArtistMapper from "./mapper/ArtistMapper";
import ArtistParentMapper from "./mapper/ArtistParentMapper";
import { podcastsResponseWithStars } from "../podcast/PodcastWithStars";
import { getArtistTags, getTags } from "../tags/TagsRepository";
import { getPodcastsForArtistOrderByAppreciation, getPodcastsForArtistGroupedByGenre, getUpcomingPodcastsForArtist } from "../podcast/PodcastRepository";
import VoidMapper from "../VoidMapper";
import { getToken } from "../user/UserSessionRepository";
const podcastParentMapper = new PodcastParentMapper();
const artistParentMapper = new ArtistParentMapper();
const artistMapper = new ArtistMapper();
const voidMapper = new VoidMapper();
const getArtists = (domainConfig) => {
    return fetchArtists(domainConfig.baseUrl).pipe(map((artistApi) => artistParentMapper.mapFrom(artistApi)));
};
const getAllArtists = (domainConfig) => {
    return fetchAllArtists(domainConfig.baseUrl).pipe(map((artistApi) => artistParentMapper.mapFrom(artistApi)));
};
const getArtistsWithTags = (domainConfig) => {
    return getAllArtists(domainConfig).pipe(mergeMap((artistsDomain) => getTags(domainConfig).pipe(map((tagsDomain) => {
        if (artistsDomain.entity === undefined)
            return DomainResponse.createFatalError();
        if (tagsDomain.entity === undefined)
            return DomainResponse.createFatalError();
        return DomainResponse.createSuccess({
            artists: artistsDomain.entity,
            tags: tagsDomain.entity
        });
    }), catchError((error) => of(DomainResponse.createError(error))))));
};
const getArtistWithPodcasts = (niceLink, domainConfig) => {
    return fetchArtistByName(niceLink, domainConfig.baseUrl).pipe(map((artistApi) => artistMapper.mapFrom(artistApi)), map((response) => response.entity), mergeMap((artist) => {
        if (artist === undefined)
            return of(DomainResponse.createFatalError());
        return subscriptionExists(artist.id).pipe(map((subscribed) => {
            return {
                artist: artist,
                subscribed: subscribed
            };
        }), mergeMap((artistWithSubscription) => fetchPodcastsForArtist(artistWithSubscription.artist.id, -1, 40, domainConfig.baseUrl).pipe(map((podcastApi) => podcastParentMapper.mapFrom(podcastApi)), map((response) => response.entity), mergeMap((podcasts) => {
            if (podcasts === undefined)
                return of(DomainResponse.createFatalError());
            return podcastsResponseWithStars(podcasts).pipe(mergeMap((podcastListResponse) => getArtistTags(artistWithSubscription.artist.id, domainConfig).pipe(mergeMap((tagsResponse) => getPodcastsForArtistOrderByAppreciation(artistWithSubscription.artist.id, domainConfig).pipe(mergeMap((mostAppreciatedResponse) => getUpcomingPodcastsForArtist(artistWithSubscription.artist.id, -1, 1, domainConfig).pipe(mergeMap((upcomingPodcasts) => getPodcastsForArtistGroupedByGenre(artistWithSubscription.artist.id, domainConfig).pipe(map((podcastGenreGroupResponse) => {
                if (podcastListResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                if (podcastListResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                if (upcomingPodcasts.entity === undefined)
                    return DomainResponse.createFatalError();
                if (tagsResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                if (mostAppreciatedResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                if (podcastGenreGroupResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                return DomainResponse.createSuccess({
                    artist: artistWithSubscription.artist,
                    podcasts: podcastListResponse.entity,
                    upcomingPodcasts: upcomingPodcasts.entity,
                    subscribed: artistWithSubscription.subscribed,
                    tags: tagsResponse.entity,
                    mostAppreciatedPodcasts: mostAppreciatedResponse.entity,
                    podcastGenreGroup: podcastGenreGroupResponse.entity
                });
            }))))))))));
        }))));
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getArtistById = (id, domainConfig) => {
    return fetchArtistById(id, domainConfig.baseUrl).pipe(map((api) => artistMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const createArtist = (values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => {
        var _a;
        return postArtist(values.name, values.quote, values.description, values.userId, values.websiteUrl, values.order, values.imgUrl, (_a = values.tags) !== null && _a !== void 0 ? _a : [], token, domainConfig.baseUrl).pipe(map((response) => artistMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))));
    }));
};
const updateArtist = (id, values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putArtist(id, values.name, values.quote, values.description, values.userId, values.websiteUrl, values.order, values.imgUrl, values.tags, token, domainConfig.baseUrl).pipe(map((response) => voidMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
export { getArtists, getAllArtists, getArtistsWithTags, getArtistWithPodcasts, getArtistById, createArtist, updateArtist };
