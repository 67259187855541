import {
  connect
} from "react-redux";
import UsersComponent from "./UsersComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  UsersActionType
} from "./model/UsersActions";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.users
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadUsers: () => {
      dispatch({
        type: UsersActionType.FETCH_INITIAL_USERS
      });
    },
    loadMore: (skip: number) => {
      dispatch({
        type: UsersActionType.FETCH_USERS,
        skip: skip
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
