import React, {
  CSSProperties
} from "react";
import {
  Divider, Theme, withStyles
} from "@material-ui/core";
import {
  WithClasses
} from "../../shared/Props";

const styles = (theme: Theme) => ({
  horizontal: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
      marginBottom: 24
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 32,
      marginBottom: 32
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 48,
      marginBottom: 48
    }
  }
});

const HorizontalDivider = (props: CSSProperties & WithClasses) => (
  <Divider className={props.classes.horizontal} variant="middle" />
);

export default withStyles(styles, {
  withTheme: true
})(HorizontalDivider);
