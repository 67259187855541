import Mapper from "../../../Mapper";
import PodcastMapper from "../../../podcast/mapper/PodcastMapper";
import { prettyDate } from "../../../DateTimeFormatter";
export default class RecentlyPlayedPodcastParentMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
    }
    from(entity) {
        return entity.podcasts.map((recentlyPlayedPodcast) => {
            return Object.assign(Object.assign({}, this.podcastMapper.from(recentlyPlayedPodcast.podcast)), { recentlyPlayed: {
                    id: recentlyPlayedPodcast.recentlyPlayedId,
                    playedDate: prettyDate(recentlyPlayedPodcast.recentlyPlayedDateTime)
                } });
        });
    }
}
