enum CategoriesActionType {
  FETCH_CATEGORIES = "FETCH_CATEGORIES",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  FETCH_CATEGORY_COVER_IMAGES = "FETCH_CATEGORY_COVER_IMAGES",
  FETCH_CATEGORY_SIGNING_URL = "FETCH_CATEGORY_SIGNING_URL",
}

export {
  CategoriesActionType
};
