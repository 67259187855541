import {
  connect
} from "react-redux";
import AssetsComponent from "./AssetsComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  AssetType
} from "../assets/model/AssetType";
import {
  ArtistsActionType
} from "../artists/model/ArtistsActions";
import {
  ScheduleActionType
} from "../schedule/model/ScheduleActions";

import {
  Asset
} from "@rewindit/domain";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.assets
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadCoverImages: (name: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES,
        assetType: "cover",
        name: name
      });
    },
    loadAudioFiles: (name: string) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES,
        assetType: "audio",
        name: name
      });
    },
    loadArtists: () => {
      dispatch({
        type: ArtistsActionType.FETCH_ARTISTS
      });
    },
    loadSigningUrl: (name: string, assetType: AssetType) => {
      dispatch({
        type: ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL,
        assetType: assetType,
        name: name
      });
    },
    itemSelected: (asset: Asset) => {
      window.open(asset.url);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsComponent);
