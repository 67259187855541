import { RxStorage } from "../../RxStorage";
const storage = new RxStorage("SubscriptionListEntity");
const insertUserSubscriptions = (entities) => {
    return storage.insertAll(entities);
};
const deleteUserSubscription = (contentId) => {
    return storage.filterReplace((entity) => entity.contentId !== contentId);
};
const getUserSubscriptions = () => {
    return storage.getItems();
};
const subscriptionExists = (contentId) => {
    return storage.exists((entity) => entity.contentId === contentId);
};
export { getUserSubscriptions, insertUserSubscriptions, deleteUserSubscription, subscriptionExists };
