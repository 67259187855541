import Mapper from "../../Mapper";
import ArtistMapper from "../../artist/mapper/ArtistMapper";
import PodcastMapper from "../../podcast/mapper/PodcastMapper";
export default class SearchMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.artistMapper = new ArtistMapper();
        this.podcastMapper = new PodcastMapper();
    }
    from(entity) {
        return {
            podcasts: entity.podcasts.map((podcast) => this.podcastMapper.from(podcast)),
            artists: entity.artists.map((artist) => this.artistMapper.from(artist))
        };
    }
}
