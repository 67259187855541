import Mapper from "../../../Mapper";
import PodcastMapper from "../../../podcast/mapper/PodcastMapper";
import { prettyDate } from "../../../DateTimeFormatter";
export default class StarPodcastMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
    }
    from(entity) {
        return entity.stars.map((star) => {
            return Object.assign(Object.assign({}, this.podcastMapper.from(star.podcast)), { appreciation: {
                    appreciatedDate: prettyDate(star.dateCreated)
                } });
        });
    }
}
