import React from "react";
import {
  HasChildren
} from "../../shared/Props";
import {
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import {
  Paper, Grid
} from "@material-ui/core";

const paperStyle: CSSProperties = {
  padding: 24
};

const FormContainerView = (props: HasChildren) => (
  <Paper style={paperStyle}>
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid container direction="column">
        {props.children}
      </Grid>
    </form>
  </Paper>
);

export default FormContainerView;
