import {
  connect
} from "react-redux";
import CommentsComponent from "./CommentsComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  CommentsActionType
} from "./model/CommentsActions";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.comments
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadComments: () => {
      dispatch({
        type: CommentsActionType.FETCH_COMMENTS
      });
    },
    loadMore: (skip: number) => {
      dispatch({
        type: CommentsActionType.FETCH_MORE_COMMENTS,
        skip: skip
      });
    },
    deleteComment: (id: string) => {
      dispatch({
        type: CommentsActionType.DELETE_COMMENT,
        id: id
      });
    },
    reloadComments: () => {
      dispatch({
        type: CommentsActionType.FETCH_COMMENTS
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsComponent);
