import Mapper from "../../Mapper";
export default class RecurringMapper extends Mapper {
    from(entity) {
        return {
            day: entity.day,
            startHour: entity.startHour,
            startMinutes: entity.startMinutes,
            endHour: entity.endHour,
            endMinutes: entity.endMinutes,
            blocks: entity.blocks.map((block) => ({
                week: block.week,
                year: block.year
            }))
        };
    }
}
