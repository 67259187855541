import { PodcastStatus } from "../Podcast";
import Mapper from "../../Mapper";
import PodcastPartMapper from "./PodcastPartMapper";
import { prettyDate } from "../../DateTimeFormatter";
export default class PodcastMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastPartMapper = new PodcastPartMapper();
        this.mapStreamUrl = (entity) => {
            if (entity.live) {
                return entity.liveStreamUrl || "";
            }
            else {
                return entity.audioStreamUrl || "";
            }
        };
        this.mapParts = (parts) => {
            return parts.map((part) => {
                return this.podcastPartMapper.from(part);
            });
        };
        this.mapStatus = (podcast) => {
            if (podcast.upcoming) {
                return PodcastStatus.UPCOMING;
            }
            else if (podcast.live) {
                return PodcastStatus.LIVE;
            }
            else if (podcast.terminated === true) {
                return PodcastStatus.TERMINATED;
            }
            else {
                return PodcastStatus.RELEASED;
            }
        };
    }
    from(entity) {
        return {
            id: entity.id,
            title: entity.title,
            description: entity.description,
            streamUrl: this.mapStreamUrl(entity),
            imgUrl: entity.imgUrl,
            liveScheduleStartTime: entity.liveScheduleStartTime,
            liveScheduleStartDate: entity.liveScheduleStartDate,
            liveScheduleEndTime: entity.liveScheduleEndTime,
            liveScheduleEndDate: entity.liveScheduleEndDate,
            liveScheduleTimeZone: entity.liveScheduleTimeZone,
            niceLink: entity.niceLink,
            commentCount: entity.commentCount || 0,
            starCount: entity.starCount || 0,
            genre: entity.genre,
            starredByUser: false,
            status: this.mapStatus(entity),
            artist: {
                id: entity.artist.id,
                name: entity.artist.name,
                niceLink: entity.artist.niceLink
            },
            parts: this.mapParts(entity.parts || []),
            prettyDate: prettyDate(entity.liveScheduleStartTime),
            tags: entity.tags
        };
    }
}
