import Mapper from "../../Mapper";
export default class AssetMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.stripUrl = (url) => {
            const pieces = url.split(".com/");
            if (pieces.length > 1)
                return pieces[1];
            return url;
        };
    }
    from(entity) {
        return entity.content.map((url) => {
            return {
                url: url,
                name: this.stripUrl(url)
            };
        });
    }
}
