import VoidMapper from "../../VoidMapper";
import { putActivateAccount, postResendActivationEmail } from "@rewindit/data";
import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { DomainResponse } from "../../DomainResponse";
import { getToken } from "../UserSessionRepository";
const voidMapper = new VoidMapper();
const activateAccount = (activationToken, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putActivateAccount(activationToken, token, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)))), catchError((error) => of(DomainResponse.createError(error))));
};
const resendActivationEmail = (domainConfig) => {
    return getToken().pipe(mergeMap((token) => postResendActivationEmail(token, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)))), catchError((error) => of(DomainResponse.createError(error))));
};
export { activateAccount, resendActivationEmail };
