import moment from "moment";
import Mapper from "../../Mapper";
import { PodcastStatus } from "../Podcast";
import PodcastPartMapper from "./PodcastPartMapper";
import PodcastRecurringMapper from "./PodcastRecurringMapper";
export default class PodcastScheduleMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastPartMapper = new PodcastPartMapper();
        this.podcastRecurringMapper = new PodcastRecurringMapper();
        this.mapParts = (parts) => {
            return parts.map((part) => {
                return this.podcastPartMapper.from(part);
            });
        };
        this.mapRecurring = (recurring) => {
            if (recurring == null)
                return undefined;
            return this.podcastRecurringMapper.from(recurring);
        };
        this.mapStatus = (podcast) => {
            if (podcast.upcoming) {
                return PodcastStatus.UPCOMING;
            }
            else if (podcast.live) {
                return PodcastStatus.LIVE;
            }
            else if (podcast.terminated === true) {
                return PodcastStatus.TERMINATED;
            }
            else {
                return PodcastStatus.RELEASED;
            }
        };
    }
    from(entity) {
        var _a;
        return {
            id: entity.id,
            title: entity.title,
            description: entity.description,
            audioStreamUrl: entity.audioStreamUrl,
            liveStreamUrl: entity.liveStreamUrl,
            imgUrl: entity.imgUrl,
            liveScheduleStartTime: entity.liveScheduleStartTime,
            liveScheduleStartDate: moment(entity.liveScheduleStartDate),
            liveScheduleEndTime: entity.liveScheduleEndTime,
            liveScheduleEndDate: moment(entity.liveScheduleEndDate),
            liveScheduleTimeZone: entity.liveScheduleTimeZone,
            niceLink: entity.niceLink,
            commentCount: entity.commentCount || 0,
            starCount: entity.starCount || 0,
            genre: entity.genre,
            type: (_a = entity.type) !== null && _a !== void 0 ? _a : "PODCAST",
            status: this.mapStatus(entity),
            artist: {
                id: entity.artist.id,
                name: entity.artist.name,
                niceLink: entity.artist.niceLink
            },
            parts: this.mapParts(entity.parts || []),
            tags: entity.tags,
            recurring: this.mapRecurring(entity.recurring)
        };
    }
}
