import React from "react";
import HorizontalDivider from "../../uikit/dividers/HorizontalDivider";
import {
  ProgressView
} from "./ProgressView";
import {
  CSSProperties
} from "@material-ui/styles";

export interface LoadMoreProgressProps {
  isLoading: boolean;
}

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 100
};

const hide: CSSProperties = {
  display: "none"
};

const showProgress: CSSProperties = {
  display: "block"
};

const showDivider: CSSProperties = {
  display: "inline-block",
  width: "100%"
};

const LoadMoreProgress = (props: LoadMoreProgressProps): JSX.Element => (
  <div style={style}>
    <div style={props.isLoading ? showProgress : hide}>
      <ProgressView />
    </div>
    <div style={props.isLoading ? hide : showDivider}>
      <HorizontalDivider />
    </div>
  </div>
);

export {
  LoadMoreProgress
};
