import React from "react";
import DropdownView from "./DropdownView";

interface TimezoneValue {
  value: number,
  label: string
}

const dropdownValues: TimezoneValue[] = [
  {
    value: -12,
    label: "(GMT -12:00)"
  },
  {
    value: -11,
    label: "(GMT -11:00)"
  },
  {
    value: -10,
    label: "(GMT -10:00)"
  },
  {
    value: -9,
    label: "(GMT -9:00)"
  },
  {
    value: -8,
    label: "(GMT -8:00)"
  },
  {
    value: -7,
    label: "(GMT -7:00)"
  },
  {
    value: -6,
    label: "(GMT -6:00)"
  },
  {
    value: -5,
    label: "(GMT -5:00)"
  },
  {
    value: -4,
    label: "(GMT -4:00)"
  },
  {
    value: -3,
    label: "(GMT -3:00)"
  },
  {
    value: -2,
    label: "(GMT -2:00)"
  },
  {
    value: -1,
    label: "(GMT -1:00)"
  },
  {
    value: 0,
    label: "(GMT)"
  },
  {
    value: 1,
    label: "(GMT +1:00)"
  },
  {
    value: 2,
    label: "(GMT +2:00)"
  },
  {
    value: 3,
    label: "(GMT +3:00)"
  },
  {
    value: 4,
    label: "(GMT +4:00)"
  },
  {
    value: 5,
    label: "(GMT +5:00)"
  },
  {
    value: 6,
    label: "(GMT +6:00)"
  },
  {
    value: 7,
    label: "(GMT +7:00)"
  },
  {
    value: 8,
    label: "(GMT +8:00)"
  },
  {
    value: 9,
    label: "(GMT +9:00)"
  },
  {
    value: 10,
    label: "(GMT +10:00)"
  },
  {
    value: 11,
    label: "(GMT +11:00)"
  },
  {
    value: 12,
    label: "(GMT +12:00)"
  }
];

interface TimezoneDropdownProps {
  value?: number;
  onValueChanged: (value: number) => void;
}

const TimezoneDropdown = (props: TimezoneDropdownProps) => (
  <DropdownView<number>
    label="Timezone"
    value={props.value}
    options={dropdownValues}
    onValueChanged={(value) => {
      props.onValueChanged(value);
    }}
    required
  />
);

export default TimezoneDropdown;
