import React, {
  useEffect, useState
} from "react";
import {
  useFormik
} from "formik";
import {
  useParams
} from "react-router";
import unwrap, {
  Optional
} from "../../../../shared/Optional";
import {
  PodcastSchedule, Artist, Asset, getArtistName
} from "@rewindit/domain";
import TextInputView from "../../../../uikit/form/TextInputView";
import FormRow from "../../../../uikit/form/FormRow";
import {
  FlexOneLeftDiv,
  FlexOneRightDiv,
  FlexOneMiddleDiv
} from "../../../../uikit/div/FlexOneDiv";
import GenreDropdown from "../../../../uikit/form/GenreDropdown";
import DayDropdown from "../../../../uikit/form/DayDropdown";
import CoverImagePicker from "../../../../uikit/file/CoverImagePicker";
import MultilineTextInputView from "../../../../uikit/form/MultilineTextInputView";
import ArtistDropdown from "../../../../uikit/form/ArtistDropdown";
import FormGroupView from "../../../../uikit/form/FormGroup";
import ScheduleDateTimeView from "./ScheduleDateTimeView";
import TimezoneDropdown from "../../../../uikit/form/TimezoneDropdown";
import DurationPickerView from "../../../../uikit/form/DurationPickerView";
import ScheduleTypeDropdown from "./ScheduleTypeDropdown";
import {
  Grid, TextField
} from "@material-ui/core";
import FormContainerView from "../../../../uikit/form/FormContainerView";
import {
  Prompt, RouteComponentProps, withRouter
} from "react-router-dom";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import AudioFileDropdown from "../../../../uikit/file/AudioFileDropdown";
import ContentDialog from "../../../../uikit/dialog/ContentDialog";
import {
  timeDurationBreakdown,
  timestampFromMinutesHours,
  timestampDurationBreakdown,
  dateWithoutTime,
  timeHoursMinutesWithoutDate,
  timestampFromSecondsMinutesHours,
  timeWithLocalZone,
  timeWithoutZone
} from "../../../../date/DateTimeFormatter";
import SchedulePartContainer from "./SchedulePartContainer";
import {
  partialPodcastPart
} from "./SchedulePartView";
import TimePickerView from "../../../../uikit/form/TimePickerView";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import ConfirmDialog from "../../../../uikit/dialog/ConfirmDialog";
import ScheduleFormValues from "./ScheduleFormValues";
import SuccessDiv from "../../../../uikit/div/SuccessDiv";
import {
  ProgressButton
} from "../../../../uikit/button/ProgressButton";
import {
  Autocomplete
} from "@material-ui/lab";
import {
  ScheduleType,
  isPodcastIncomplete,
  podcastIncompleteMessage
} from "./ScheduleType";
import {
  WarningDiv
} from "../../../../uikit/div/WarningDiv";
import ScheduleWeeklyFormValues from "./ScheduleWeeklyFormValues";
import AssetPickerView from "../../assets/view/AssetPickerView";
import {
  AssetType
} from "../../assets/model/AssetType";
import moment from "moment";

interface ScheduleFormProps extends RouteComponentProps {
  lazyPodcast: Optional<PodcastSchedule>;
  podcasts: PodcastSchedule[];
  artists: Artist[];
  existingTags: Optional<string[]>;
  audioFileUrls: Optional<Asset[]>;
  audioFileUrlsLoading: boolean;
  audioFileUrlsError: Optional<string>;
  error: Optional<string>;
  isLoading: boolean;
  isDeleteLoading: boolean;
  isFormLoading: boolean;
  formUpdated: boolean;
  deleteError: Optional<string>;
  onChangeRedirect: boolean;
  coverImages: Optional<Asset[]>;
  coverImageError: Optional<string>;
  coverImageIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
  signingUrlIsLoading: boolean;
  audioSigningUrlError: Optional<string>;
  audioSigningUrl: Optional<string>;
  audioSigningUrlIsLoading: boolean;
  loadPodcast: (id: string) => void;
  loadAudioFiles: (name: string) => void;
  loadCoverImages: (name: string) => void;
  loadSigningUrl: (name: string, type: AssetType) => void;
  deleteSchedule: (id: string) => void;
  reloadPodcasts: () => void;
  reloadPlaylists: () => void;
  createSchedule: (values: ScheduleFormValues) => void;
  updateSchedule: (id: string, values: ScheduleFormValues) => void;
  createWeeklySchedule: (values: ScheduleWeeklyFormValues) => void;
  updateWeeklySchedule: (id: string, values: ScheduleWeeklyFormValues) => void;
}

const initialValuesFromPodcast = (podcast: Optional<PodcastSchedule>) => {
  const timeDuration = unwrap((podcast) => {
    if (
      podcast.liveScheduleStartDate === undefined ||
      podcast.liveScheduleEndDate === undefined
    )
      return undefined;
    return timeDurationBreakdown(
      podcast.liveScheduleStartDate.toDate().getTime(),
      podcast.liveScheduleEndDate.toDate().getTime()
    );
  }, podcast);
  return {
    title: unwrap((podcast) => podcast.title, podcast),
    description: unwrap((podcast) => podcast.description, podcast),
    artist: unwrap((podcast) => podcast.artist.id, podcast),
    genre: unwrap((podcast) => podcast.genre, podcast),
    type: unwrap((podcast) => podcast.type, podcast),
    audioStreamUrl: unwrap((podcast) => podcast.audioStreamUrl, podcast),
    liveStreamUrl: podcast?.liveStreamUrl ?? "https://live.rewindit.fm/stream",
    startDate:
      unwrap((podcast) => podcast.liveScheduleStartDate.toDate(), podcast) ??
      dateWithoutTime(moment().toDate().getTime()),
    startTime:
      unwrap(
        (podcast) =>
          timeWithLocalZone(
            timeWithoutZone(
              podcast.liveScheduleStartTime,
              podcast.liveScheduleTimeZone
            )
          ),
        podcast
      ) ?? dateWithoutTime(moment().toDate().getTime()),
    timezone: unwrap((podcast) => podcast.liveScheduleTimeZone, podcast),
    durationHours: timeDuration?.hours ?? 0,
    durationMinutes: timeDuration?.minutes ?? 0,
    durationSeconds: timeDuration?.seconds ?? 0,
    tags:
      unwrap(
        (podcast) => (podcast.tags !== undefined ? [...podcast.tags] : []),
        podcast
      ) ?? [],
    coverImage: unwrap((podcast) => podcast.imgUrl, podcast),
    parts:
      unwrap(
        (parts) => parts.map((part) => partialPodcastPart(part)),
        podcast?.parts
      ) ?? [],
    recurringDay: podcast?.recurring?.day ?? 1,
    recurringStartHour: podcast?.recurring?.startHour ?? 0,
    recurringStartMinutes: podcast?.recurring?.startMinutes ?? 0,
    recurringEndHour: podcast?.recurring?.endHour ?? 0,
    recurringEndMinutes: podcast?.recurring?.endMinutes ?? 0
  };
};

const resolvePodcast = (
  lazyPodcast: Optional<PodcastSchedule>,
  podcasts: PodcastSchedule[],
  id: Optional<string>
): Optional<PodcastSchedule> => {
  if (lazyPodcast !== undefined && id !== undefined) return lazyPodcast;
  return podcasts.find((podcast) => podcast.id === id);
};

const ScheduleForm = (props: ScheduleFormProps) => {
  const {
    id
  } = useParams<{ id: string }>();

  const podcast = resolvePodcast(props.lazyPodcast, props.podcasts, id);

  const [isLoadingFromUrl, setIsLoadingFromUrl] = useState(false);

  const [assetPickerTabSelected, setAssetPickerTabSelected] = useState(0);

  const formik = useFormik({
    initialValues: initialValuesFromPodcast(podcast),
    onSubmit: (values) => {
      if (values.type === ScheduleType.WEEKLY) {
        const scheduleWeeklyFormValues: ScheduleWeeklyFormValues = {
          title: values.title ?? "",
          description: values.description ?? "",
          type: values.type ?? "",
          liveStreamUrl: values.liveStreamUrl ?? "",
          imgUrl: values.coverImage ?? "",
          liveScheduleTimeZone: values.timezone ?? 0,
          artistId: values.artist ?? "",
          tags: values.tags ?? [],
          genre: values.genre ?? "",
          recurring: {
            day: values.recurringDay,
            startHour: values.recurringStartHour,
            startMinutes: values.recurringStartMinutes,
            endHour: values.recurringEndHour,
            endMinutes: values.recurringEndMinutes,
            blocks: []
          }
        };
        if (id === undefined) {
          props.createWeeklySchedule(scheduleWeeklyFormValues);
        } else {
          props.updateWeeklySchedule(id, scheduleWeeklyFormValues);
        }
      } else {
        const liveScheduleStartTime =
          dateWithoutTime(values.startDate) +
          timeHoursMinutesWithoutDate(values.startTime);

        const liveScheduleEndTime =
          liveScheduleStartTime +
          timestampFromSecondsMinutesHours(
            values.durationHours,
            values.durationMinutes,
            values.durationSeconds
          );

        const scheuleFormValues: ScheduleFormValues = {
          title: values.title ?? "",
          description: values.description ?? "",
          genre: values.genre ?? "",
          type: values.type ?? "",
          audioStreamUrl: values.audioStreamUrl ?? "",
          liveStreamUrl: values.liveStreamUrl ?? "",
          imgUrl: values.coverImage ?? "",
          liveScheduleStartTime: liveScheduleStartTime,
          liveScheduleEndTime: liveScheduleEndTime,
          liveScheduleTimeZone: values.timezone ?? 0,
          artistId: values.artist ?? "",
          tags: values.tags,
          parts: (values.parts ?? []).map((partialPart) => {
            return {
              title: partialPart.title ?? "",
              description: partialPart.description ?? "",
              startSeconds: partialPart.startSeconds ?? 0,
              endSeconds: partialPart.endSeconds ?? 0
            };
          })
        };
        if (id === undefined) {
          props.createSchedule(scheuleFormValues);
        } else {
          props.updateSchedule(id, scheuleFormValues);
        }
      }
    }
  });

  const {
    coverImages,
    loadCoverImages,
    audioFileUrls,
    loadAudioFiles,
    loadPodcast,
    onChangeRedirect,
    reloadPodcasts,
    reloadPlaylists
  } = props;

  useEffect(() => {
    if ((podcast === undefined || id !== podcast.id) && id !== undefined) {
      setIsLoadingFromUrl(true);
      loadPodcast(id);
    }
    if (onChangeRedirect) {
      if (formik.values.type === ScheduleType.WEEKLY) {
        reloadPlaylists();
      } else {
        reloadPodcasts();
      }
    }
  }, [
    id,
    podcast,
    loadPodcast,
    coverImages,
    audioFileUrls,
    loadAudioFiles,
    onChangeRedirect,
    reloadPlaylists,
    reloadPodcasts,
    formik.values.type,
    setIsLoadingFromUrl
  ]);

  const [isAudioPickerOpen, setOpenAudioPicker] = useState(false);

  const [isCoverPickerOpen, setOpenCoverPicker] = useState(false);

  const [isDeleteDialogOpen, setOpenDeleteDialog] = useState(false);

  if (isLoadingFromUrl && podcast !== undefined) {
    formik.setValues(initialValuesFromPodcast(podcast));
    setIsLoadingFromUrl(false);
  }

  return (
    <>
      <Prompt
        when={formik.dirty && !formik.isSubmitting}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ContentDialog
        onClose={() => {
          setOpenAudioPicker(false);
          setAssetPickerTabSelected(0);
        }}
        isOpen={isAudioPickerOpen}
        title={"Select an audio file"}
      >
        <div style={{
          height: 420, width: "100%"
        }}>
          <AssetPickerView
            tabSelected={assetPickerTabSelected}
            groupName={getArtistName(formik.values.artist, props.artists)}
            selectedAssetType="audio"
            signingUrl={props.signingUrl}
            signingUrlLoading={props.signingUrlIsLoading}
            signingUrlError={props.signingUrlError}
            coverImageIsLoading={props.coverImageIsLoading}
            coverImageError={props.coverImageError}
            audioFiles={props.audioFileUrls}
            audioFilesIsLoading={props.audioFileUrlsLoading}
            audioFilesError={props.audioFileUrlsError}
            loadCoverImages={loadCoverImages}
            loadAudioFiles={loadAudioFiles}
            itemSelected={(asset) => {
              setOpenAudioPicker(false);
              formik.values.audioStreamUrl = asset.url;
            }}
            loadSigningUrl={props.loadSigningUrl}
            setTabSelected={setAssetPickerTabSelected}
            coverImages={coverImages}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        onClose={() => {
          setOpenCoverPicker(false);
          setAssetPickerTabSelected(0);
        }}
        isOpen={isCoverPickerOpen}
        title={"Select a schedule cover image"}
      >
        <div style={{
          height: 420, width: "100%"
        }}>
          <AssetPickerView
            tabSelected={assetPickerTabSelected}
            groupName={getArtistName(formik.values.artist, props.artists)}
            selectedAssetType="cover"
            signingUrl={props.signingUrl}
            signingUrlLoading={props.signingUrlIsLoading}
            signingUrlError={props.signingUrlError}
            coverImageIsLoading={props.coverImageIsLoading}
            coverImageError={props.coverImageError}
            audioFiles={props.audioFileUrls}
            audioFilesIsLoading={props.audioFileUrlsLoading}
            audioFilesError={props.audioFileUrlsError}
            loadCoverImages={loadCoverImages}
            loadAudioFiles={loadAudioFiles}
            itemSelected={(asset) => {
              setOpenCoverPicker(false);
              formik.values.coverImage = asset.url;
            }}
            loadSigningUrl={props.loadSigningUrl}
            setTabSelected={setAssetPickerTabSelected}
            coverImages={coverImages}
          />
        </div>
      </ContentDialog>
      <ConfirmDialog
        title="Delete schedule"
        body="Are you sure you want to delete this schedule?"
        isOpen={isDeleteDialogOpen}
        isLoading={props.isDeleteLoading}
        error={props.deleteError}
        handleAgree={() => {
          props.deleteSchedule(id);
        }}
        handleDismiss={() => {
          setOpenDeleteDialog(false);
        }}
      />
      <SuccessDiv
        visible={props.formUpdated}
        successMessage="Schedule update successful"
      />
      <WarningDiv
        visible={isPodcastIncomplete(podcast)}
        title="Schedule incomplete"
        body={podcastIncompleteMessage(podcast)}
      />
      <ProgressOrError
        isLoading={props.isLoading}
        error={props.error}
        retryClicked={() => {
          loadPodcast(id);
        }}
      >
        <FormContainerView>
          <FormGroupView title="Meta data">
            <FormRow>
              <TextInputView
                label="Title"
                name="title"
                value={formik.values.title}
                required={true}
                onChanged={formik.handleChange}
              />
            </FormRow>
            <FormRow>
              <FlexOneLeftDiv>
                <MultilineTextInputView
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  rows={5}
                  onChanged={formik.handleChange}
                />
              </FlexOneLeftDiv>
              <FlexOneRightDiv>
                <Grid
                  container
                  alignContent="space-between"
                  style={{
                    height: "100%"
                  }}
                >
                  <ArtistDropdown
                    artists={props.artists}
                    name="artist"
                    value={props.artists.find(
                      (artist) => formik.values.artist === artist.id
                    )}
                    onValueChanged={(artist) => {
                      formik.setFieldValue("artist", artist?.id ?? undefined)
                    }}
                  />
                  <GenreDropdown
                    name="genre"
                    value={formik.values.genre}
                    onValueChanged={(genre) => {
                      formik.setFieldValue("genre", genre ?? undefined)
                    }}
                  />
                </Grid>
              </FlexOneRightDiv>
            </FormRow>
          </FormGroupView>
          <FormGroupView title="Type">
            <FormRow>
              <ScheduleTypeDropdown
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
              />
            </FormRow>
          </FormGroupView>
          <FormGroupView title="Media">
            <FormRow>
              <FlexOneLeftDiv>
                <TextInputView
                  label="Livestream URL"
                  name="liveStreamUrl"
                  value={formik.values.liveStreamUrl}
                  required={true}
                  onChanged={formik.handleChange}
                />
              </FlexOneLeftDiv>
              <FlexOneRightDiv
                hidden={
                  formik.values.type === ScheduleType.WEEKLY ||
                  formik.values.type === ScheduleType.REALTIME
                }
              >
                <AudioFileDropdown
                  label="Audio file"
                  name="audioStreamUrl"
                  value={formik.values.audioStreamUrl}
                  onClick={() => {
                    setOpenAudioPicker(true);
                  }}
                />
              </FlexOneRightDiv>
            </FormRow>
            <div hidden={formik.values.type === ScheduleType.WEEKLY}>
              <FormRow>
                <FlexOneLeftDiv>
                  <ScheduleDateTimeView
                    dateValue={formik.values.startDate}
                    onDateChanged={(timestamp) => {
                      formik.setFieldValue("startDate", timestamp);
                    }}
                    timeValue={formik.values.startTime}
                    onTimeChanged={(timestamp) => {
                      formik.setFieldValue("startTime", timestamp);
                    }}
                  />
                </FlexOneLeftDiv>
                <FlexOneMiddleDiv>
                  <TimezoneDropdown
                    value={formik.values.timezone}
                    onValueChanged={(timeZone) => {
                      formik.setFieldValue("timezone", timeZone);
                    }}
                  />
                </FlexOneMiddleDiv>
                <FlexOneRightDiv>
                  <DurationPickerView
                    hoursLabel="Duration in Hours"
                    hours={formik.values.durationHours}
                    hoursChanged={(hours) => {
                      formik.setFieldValue("durationHours", hours);
                    }}
                    minutesLabel="Minutes"
                    minutes={formik.values.durationMinutes}
                    minutesChanged={(minutes) => {
                      formik.setFieldValue("durationMinutes", minutes);
                    }}
                    secondsLabel="Seconds"
                    seconds={formik.values.durationSeconds}
                    secondsChanged={(seconds) => {
                      formik.setFieldValue("durationSeconds", seconds);
                    }}
                  />
                </FlexOneRightDiv>
              </FormRow>
            </div>
            <div hidden={formik.values.type !== ScheduleType.WEEKLY}>
              <FormRow>
                <div style={{
                  flex: 1, marginRight: 16
                }}>
                  <DayDropdown
                    name="recurringDay"
                    value={formik.values.recurringDay}
                    onChange={formik.handleChange}
                  />
                </div>
                <div style={{
                  flex: 1, marginRight: 16
                }}>
                  <TimezoneDropdown
                    value={formik.values.timezone}
                    onValueChanged={(timeZone) => {
                      formik.setFieldValue("timezone", timeZone);
                    }}
                  />
                </div>
                <div style={{
                  flex: 1, marginRight: 16
                }}>
                  <TimePickerView
                    useUtc
                    label="Start time"
                    value={timestampFromMinutesHours(
                      formik.values.recurringStartMinutes,
                      formik.values.recurringStartHour
                    )}
                    onValueChanged={(timestamp) => {
                      const duration = timestampDurationBreakdown(timestamp);
                      formik.setFieldValue(
                        "recurringStartMinutes",
                        duration.minutes
                      );
                      formik.setFieldValue(
                        "recurringStartHour",
                        duration.hours
                      );
                    }}
                  />
                </div>
                <div style={{
                  flex: 1
                }}>
                  <TimePickerView
                    useUtc
                    label="End time"
                    value={timestampFromMinutesHours(
                      formik.values.recurringEndMinutes,
                      formik.values.recurringEndHour
                    )}
                    onValueChanged={(timestamp) => {
                      const duration = timestampDurationBreakdown(timestamp);
                      formik.setFieldValue(
                        "recurringEndMinutes",
                        duration.minutes
                      );
                      formik.setFieldValue("recurringEndHour", duration.hours);
                    }}
                  />
                </div>
              </FormRow>
            </div>
          </FormGroupView>
          <FormGroupView title="Tags (&#x23ce;) press return between each tag">
            <FormRow>
              <Autocomplete
                fullWidth
                multiple
                freeSolo
                onChange={(_, newValue) => {
                  formik.setFieldValue("tags", newValue);
                }}
                options={props.existingTags ?? []}
                value={formik.values.tags}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tags"
                    variant="outlined"
                    color="secondary"
                  />
                )}
              />
            </FormRow>
          </FormGroupView>
          <FormGroupView title="Cover image">
            <CoverImagePicker
              coverImage={formik.values.coverImage}
              isCoverPickerOpen={isCoverPickerOpen}
              onOpenCoverPicker={setOpenCoverPicker}
            />
          </FormGroupView>
          <div hidden={formik.values.type === ScheduleType.WEEKLY}>
            <FormGroupView title="Parts">
              <SchedulePartContainer
                partialPodcastParts={formik.values.parts}
                addPart={() => {
                  const newParts = formik.values.parts;
                  newParts.push({
                    title: undefined,
                    description: undefined,
                    startSeconds: 0,
                    endSeconds: 0
                  });
                  formik.setFieldValue("parts", newParts);
                }}
                removePart={(index) => {
                  const newParts = formik.values.parts;
                  newParts.splice(index, 1);
                  formik.setFieldValue("parts", newParts);
                }}
                partChanged={(partialPart, index) => {
                  const newParts = formik.values.parts;
                  newParts[index] = partialPart;
                  formik.setFieldValue("parts", newParts);
                }}
              />
            </FormGroupView>
          </div>
          <FormRow>
            <FlexOneLeftDiv>
              <ProgressButton
                fullWidth
                isLoading={props.isFormLoading}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Submit
              </ProgressButton>
            </FlexOneLeftDiv>
            <FlexOneRightDiv hidden={id === undefined}>
              <SecondaryButton
                fullWidth
                onClick={() => {
                  setOpenDeleteDialog(true);
                }}
              >
                Delete
              </SecondaryButton>
            </FlexOneRightDiv>
          </FormRow>
        </FormContainerView>
      </ProgressOrError>
    </>
  );
};

export default withRouter(ScheduleForm);
