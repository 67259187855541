import React from "react";
import {
  Tabs, Tab
} from "@material-ui/core";
import FileUploaderView from "./FileUploaderView";
import {
  AssetType
} from "../../assets/model/AssetType";
import {
  Optional
} from "../../../../shared/Optional";
import {
  Asset
} from "@rewindit/domain";
import CoverImageGridView from "./CoverImageGridView";
import AudioFileListView from "./AudioFileListView";

interface AssetPickerViewProps {
  tabSelected: number;
  groupName: string;
  selectedAssetType: Optional<AssetType>;
  signingUrl: Optional<string>;
  signingUrlLoading: boolean;
  signingUrlError: Optional<string>;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  coverImages: Optional<Asset[]>;
  audioFilesIsLoading: boolean;
  audioFilesError: Optional<string>;
  audioFiles: Optional<Asset[]>;
  loadCoverImages: (name: string) => void;
  loadAudioFiles?: (name: string) => void;
  itemSelected: (asset: Asset) => void;
  loadSigningUrl: (name: string, type: AssetType) => void;
  setTabSelected: (index: number) => void;
}

const AssetPickerView = (props: AssetPickerViewProps) => {
  return (
    <>
      <Tabs value={props.tabSelected}>
        <Tab
          key="assets"
          label="Assets"
          style={{
            fontSize: 16, textTransform: "uppercase"
          }}
          onClick={() => {
            props.setTabSelected(0);
          }}
        />
        <Tab
          key="upload"
          label="Upload"
          style={{
            fontSize: 16, textTransform: "uppercase"
          }}
          onClick={() => {
            props.setTabSelected(1);
          }}
        />
      </Tabs>
      <div style={{
        marginTop: 16
      }} hidden={props.tabSelected === 1}>
        <OptionalCoverImageGridView
          {...props}
          hidden={
            !props.selectedAssetType ||
            !props.groupName ||
            props.selectedAssetType !== "cover"
          }
        />
        <OptionalAudioFileListView
          {...props}
          hidden={
            !props.selectedAssetType ||
            !props.groupName ||
            props.selectedAssetType !== "audio"
          }
        />
      </div>
      <div style={{
        marginTop: 16
      }} hidden={props.tabSelected === 0}>
        <OptionalFileUploadView
          {...props}
          uploadSuccess={() => {
            props.setTabSelected(0);
          }}
          mimeType={props.selectedAssetType === "cover" ? "images/jpeg" : "audio/mpeg"}
        />
      </div>
    </>
  );
};

interface OptionalAudioFileListViewProps {
  tabSelected: number;
  hidden: boolean;
  groupName: string;
}

const OptionalAudioFileListView = (
  props: OptionalAudioFileListViewProps & AssetPickerViewProps
) => {
  if (props.tabSelected === 0 && !props.hidden) {
    return (
      <AudioFileListView
        {...props}
        assets={props.audioFiles}
        error={props.audioFilesError}
        isLoading={props.audioFilesIsLoading}
        loadAssets={props.loadAudioFiles}
      />
    );
  } else {
    return <></>;
  }
};

interface OptionalCoverImageGridViewProps {
  tabSelected: number;
  hidden: boolean;
  groupName: string;
}

const OptionalCoverImageGridView = (
  props: AssetPickerViewProps & OptionalCoverImageGridViewProps
) => {
  if (props.tabSelected === 0 && !props.hidden) {
    return (
      <CoverImageGridView
        {...props}
        assets={props.coverImages}
        error={props.coverImageError}
        isLoading={props.coverImageIsLoading}
        loadAssets={props.loadCoverImages}
      />
    );
  } else {
    return <></>;
  }
};

interface OptionalFileUploadViewProps {
  groupName: string;
  tabSelected: number;
  mimeType: "images/jpeg" | "audio/mpeg";
  selectedAssetType: Optional<AssetType>;
  uploadSuccess: () => void;
}

const OptionalFileUploadView = (
  props: AssetPickerViewProps & OptionalFileUploadViewProps
) => {
  if (props.tabSelected === 1 && props.selectedAssetType !== undefined) {
    return (
      <FileUploaderView
        {...props}
        type={props.selectedAssetType}
        mimeType={props.mimeType}
        expectedWidth={500}
        expectedHeight={500}
      />
    );
  } else {
    return <></>;
  }
};

export default AssetPickerView;
