import moment from "moment";
export const prettyDate = (timestamp) => {
    const diff = (new Date().getTime() - timestamp) / 1000;
    const dayDiff = Math.floor(diff / 86400);
    if (dayDiff === 0) {
        if (diff < 60) {
            return "just now";
        }
        else if (diff < 120) {
            return "1 minute ago";
        }
        else if (diff < 3600) {
            return `${Math.floor(diff / 60)} minutes ago`;
        }
        else if (diff < 7200) {
            return "1 hour ago";
        }
        else if (diff < 86400) {
            return `${Math.floor(diff / 3600)} hours ago`;
        }
        else {
            return "Unknown";
        }
    }
    else if (dayDiff === 1) {
        return "Yesterday";
    }
    else if (dayDiff < 7) {
        return `${dayDiff} days ago`;
    }
    else if (dayDiff < 28) {
        const weeks = Math.floor(dayDiff / 7);
        if (weeks === 1) {
            return "1 week ago";
        }
        else {
            return `${weeks} weeks ago`;
        }
    }
    else if (dayDiff >= 28 && dayDiff < 336) {
        const months = Math.floor(dayDiff / 28);
        if (months === 1) {
            return "1 month ago";
        }
        else {
            return `${months} months ago`;
        }
    }
    else if (dayDiff >= 336) {
        const years = Math.floor(dayDiff / 336);
        if (years === 1) {
            return "Over 1 year ago";
        }
        else {
            return `Over ${years} years ago`;
        }
    }
    else {
        return moment(timestamp).format("Do MMMM YYYY");
    }
};
export const durationSeconds = (time) => {
    if (time != null) {
        return moment.utc(time * 1000).format("HH:mm:ss");
    }
    else {
        return "00:00:00";
    }
};
export const fullDayDescriptiveDate = (timestamp) => {
    return moment(timestamp).format("dddd Do MMMM");
};
export const descriptiveDate = (timestamp) => {
    const currentDate = moment.utc(timestamp);
    const today = moment.utc().endOf("day");
    const tomorrow = moment.utc().add(1, "day").endOf("day");
    if (currentDate < today) {
        return "Today";
    }
    else if (currentDate < tomorrow) {
        return "Tomorrow";
    }
    else {
        return currentDate.format("dddd Do MMMM");
    }
};
export const fullDescriptiveDate = (timestamp) => {
    return moment.utc(timestamp).format("Do MMMM YYYY");
};
