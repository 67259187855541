import {
  ErrorType
} from "../../../shared/Actions";
import {
  logout
} from "@rewindit/domain";
import {
  AppRootActionType
} from "./AppRootActions";
import {
  createEpic
} from "../../../shared/CreateEpic";

const rootLogoutEpic = createEpic(
  AppRootActionType.LOGOUT,
  ErrorType.MODAL,
  () => logout()
);

export {
  rootLogoutEpic
};
