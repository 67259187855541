import { DomainResponse } from "../../DomainResponse";
import { deleteWeekly, putPreferences, getUser, updateUserPreferences } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import UserPreferecesMapper from "./mapper/UserPreferencesMapper";
import { getToken } from "../UserSessionRepository";
import VoidMapper from "../../VoidMapper";
const voidMapper = new VoidMapper();
const userPreferencesMapper = new UserPreferecesMapper();
const updatePreferences = (emailWeekly, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putPreferences(emailWeekly, token, domainConfig.baseUrl).pipe(map((api) => userPreferencesMapper.mapFrom(api)), mergeMap((response) => updateUserPreferences(emailWeekly).pipe(map(() => response))))), catchError((error) => of(DomainResponse.createError(error))));
};
const getPreferences = () => {
    return getUser().pipe(map((userEntity) => {
        if (userEntity != null) {
            return DomainResponse.createSuccess({
                weekly: userEntity.emailWeekly
            });
        }
        else {
            return DomainResponse.createError(Error());
        }
    }));
};
const unsubscribeFromWeekly = (unsubscribeToken, domainConfig) => {
    return deleteWeekly(unsubscribeToken, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)), mergeMap((response) => updateUserPreferences(false).pipe(map(() => response))), catchError((error) => of(DomainResponse.createError(error))));
};
export { updatePreferences, getPreferences, unsubscribeFromWeekly };
