import React from "react";
import {
  HasChildren
} from "../../shared/Props";
import {
  ProgressView
} from "./ProgressView";

export interface ProgressOrContentProps {
  isLoading: boolean;
}

const ProgressOrContent = (
  props: ProgressOrContentProps & HasChildren
): JSX.Element => {
  if (props.isLoading) return <ProgressView />;
  return <>{props.children}</>;
};

export {
  ProgressOrContent
};
