import React from "react";
import {
  SchedulePartView, PartialPodcastPart
} from "./SchedulePartView";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import FormRow from "../../../../uikit/form/FormRow";
import {
  Divider
} from "@material-ui/core";

interface SchedulePartContainerProps {
  partialPodcastParts: PartialPodcastPart[];
  partChanged: (part: PartialPodcastPart, index: number) => void;
  addPart: () => void;
  removePart: (index: number) => void;
}

const SchedulePartContainer = (props: SchedulePartContainerProps) => (
  <>
    {props.partialPodcastParts.map((partialPart, index) => {
      return (
        <React.Fragment key={index}>
          <FormRow>
            <SchedulePartView
              partialPart={partialPart}
              partChanged={(part: PartialPodcastPart) => {
                props.partChanged(part, index);
              }}
            />
            <div>
              <SecondaryButton
                onClick={() => {
                  props.removePart(index);
                }}
              >
                Remove
              </SecondaryButton>
            </div>
          </FormRow>
          <Divider
            variant="middle"
            style={{
              marginTop: 16, marginBottom: 16
            }}
          />
        </React.Fragment>
      );
    })}
    <FormRow>
      <SecondaryButton
        onClick={() => {
          props.addPart();
        }}
      >
        Add part
      </SecondaryButton>
    </FormRow>
  </>
);

export default SchedulePartContainer;
