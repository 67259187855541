import React from "react";
import {
  CircularProgress
} from "@material-ui/core";
import {
  Stylable
} from "../../shared/Props";

const SmallProgressView = (props: Stylable) => {
  return (
    <CircularProgress
      style={{
        ...props.extraStyle
      }}
      color="primary"
      size={24}
      thickness={4}
    />
  );
};

export {
  SmallProgressView
};
