import React, {
  useEffect
} from "react";
import {
  UsersState
} from "./model/UsersReducer";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../shared/UnwrapValue";
import {
  LoadMore
} from "../../../shared/Events";
import UserListView from "./view/UserListView";

interface UsersComponentProps extends UsersState {
  loadUsers: () => void;
  loadMore: LoadMore;
}

const UsersComponent = (props: UsersComponentProps) => {
  const {
    users, loadUsers
  } = props;

  useEffect(() => {
    if (users == null) {
      loadUsers();
    }
  }, [users, loadUsers]);

  return (
    <ProgressOrError
      retryClicked={() => {
        loadUsers();
      }}
      {...props}
    >
      {unwrapValue(
        (users) => (
          <UserListView {...props} users={users} />
        ),
        props.users
      )}
    </ProgressOrError>
  );
};

export default UsersComponent;
