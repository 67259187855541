import React, {
  useEffect
} from "react";
import {
  LoginSelected
} from "../../../shared/Events";
import {
  LoginState
} from "./model/LoginReducer";
import LoginFormView from "./view/LoginFormView";
import {
  UserSession
} from "@rewindit/domain";
import {
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import {
  Grid, Paper
} from "@material-ui/core";

export interface LoginComponentProps extends LoginState {
  loginSelected: LoginSelected;
  userAuthenticated: (userSession: UserSession) => void;
}

const rootStyle: CSSProperties = {
  position: "absolute",
  top: "20%",
  width: "100%"
};

const paperStyle: CSSProperties = {
  padding: "30px",
  width: "580px"
};

const LoginComponent = (props: LoginComponentProps) => {
  const {
    userAuthenticated, userSession, redirect
  } = props;
  useEffect(() => {
    if (userSession !== undefined && redirect) {
      userAuthenticated(userSession);
    }
  }, [userAuthenticated, userSession, redirect]);

  return (
    <div style={rootStyle}>
      <Grid container justify="center">
        <Paper style={paperStyle}>
          <LoginFormView {...props} />
        </Paper>
      </Grid>
    </div>
  );
};

export {
  LoginComponent
};
