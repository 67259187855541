import Mapper from "../../../Mapper";
import { prettyDate } from "../../../DateTimeFormatter";
export default class CommentMapper extends Mapper {
    from(entity) {
        return {
            id: entity.id,
            contentId: entity.contentDetails.id,
            body: entity.comment,
            dateCreated: entity.dateCreated,
            niceDate: prettyDate(entity.dateCreated),
            authorId: entity.author.id,
            authorName: entity.author.name,
            deleted: entity.deleted
        };
    }
}
