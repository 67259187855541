import { DomainResponse } from "../../DomainResponse";
import { putLogin, putChangePassword, putLoginWithFacebook, insertUser, insertLocalStars, insertUserSubscriptions } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import UserSessionMapper from "../UserSessionMapper";
import sha1 from "sha1";
import { DomainErrorType } from "../../DomainError";
import uuid from "uuid";
import { UserFacebookSessionMapper } from "./UserFacebookSessionMapper";
const userSessionMapper = new UserSessionMapper();
const userFacebookSessionMapper = new UserFacebookSessionMapper();
const login = (username, password, domainConfig) => {
    return putLogin(username, sha1(password), undefined, domainConfig.baseUrl).pipe(map((api) => userSessionMapper.mapFrom(api)), mergeMap((response) => {
        const userSession = response.entity;
        if (userSession === undefined)
            return of(DomainResponse.createFatalError());
        return saveUser(userSession, response);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const facebookLogin = (token, userId, appId, domainConfig) => {
    return putLoginWithFacebook(token, userId, appId, undefined, domainConfig.baseUrl).pipe(map((api) => userFacebookSessionMapper.mapFrom(api)), mergeMap((response) => {
        if (response.entity === undefined || response.entity.userSession === undefined) {
            return of(response);
        }
        return saveUser(response.entity.userSession, response);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const changePassword = (password, confirmPassword, resetToken, domainConfig) => {
    if (password.length < 8) {
        return of(DomainResponse.fromError({
            type: DomainErrorType.message,
            body: "Your password must be at least 8 characters."
        }));
    }
    else if (password !== confirmPassword) {
        return of(DomainResponse.fromError({
            type: DomainErrorType.message,
            body: "Your passwords did not match."
        }));
    }
    else {
        return putChangePassword(resetToken, sha1(password), uuid(), domainConfig.baseUrl).pipe(map((api) => userSessionMapper.mapFrom(api)), mergeMap((response) => {
            const userSession = response.entity;
            if (userSession === undefined)
                return of(DomainResponse.createFatalError());
            return saveUser(userSession, response);
        }), catchError((error) => of(DomainResponse.createError(error))));
    }
};
function saveUser(userSession, response) {
    return insertUser({
        id: userSession.id,
        token: userSession.token,
        username: userSession.username,
        emailWeekly: userSession.userPushPreferences.weekly
    }).pipe(mergeMap(() => saveStars(userSession.stars, response)), mergeMap(() => saveSubscriptions(userSession.subscriptions, response)));
}
function saveStars(stars, response) {
    if (stars != null) {
        return insertLocalStars(stars.map((star) => ({
            id: star.id,
            podcastId: star.podcastId,
            podcastImgUrl: star.podcastImgUrl,
            podcastTitle: star.podcastTitle,
            authorId: star.authorId,
            authorName: star.authorName,
            dateCreated: star.dateCreated
        }))).pipe(mergeMap(() => of(response)));
    }
    else {
        return of(response);
    }
}
function saveSubscriptions(subscriptions, response) {
    if (subscriptions != null) {
        return insertUserSubscriptions(subscriptions.map((subscription) => ({
            id: subscription.id,
            type: subscription.type,
            contentId: subscription.contentId,
            subscriptionTitle: subscription.title,
            dateCreated: subscription.dateCreated
        }))).pipe(mergeMap(() => of(response)));
    }
    else {
        return of(response);
    }
}
export { login, facebookLogin, changePassword };
