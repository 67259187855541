import Mapper from "../../Mapper";
import _ from "lodash";
import moment from "moment";
import { descriptiveDate, fullDayDescriptiveDate } from "../../DateTimeFormatter";
import PodcastMapper from "./PodcastMapper";
export default class UpcomingMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastMapper = new PodcastMapper();
        this.upcoming = (upcoming) => {
            const upcomingRowItems = this.createUpcomingItems(upcoming);
            const upcomingRows = [];
            for (const key in upcomingRowItems) {
                if (upcomingRowItems.hasOwnProperty(key)) {
                    const rows = upcomingRowItems[key];
                    upcomingRows.push({
                        groupTitle: rows[0].groupTitle,
                        items: rows
                    });
                }
            }
            return upcomingRows;
        };
        this.createUpcomingItems = (upcoming) => {
            const rows = upcoming.podcasts.map((podcast) => this.upcomingItem(podcast));
            return _.groupBy(rows, "orderedBy");
        };
        this.upcomingItem = (podcast) => {
            return {
                orderedBy: moment
                    .utc(this.getTimeWithZone(podcast.liveScheduleStartTime, podcast.liveScheduleEndTime, podcast.liveScheduleTimeZone).startTime)
                    .startOf("day")
                    .unix(),
                groupTitle: this.currentDate(podcast),
                when: this.upcomingDate(podcast.liveScheduleStartTime, podcast.liveScheduleEndTime, podcast.liveScheduleTimeZone),
                descriptiveDate: fullDayDescriptiveDate(podcast.liveScheduleStartTime),
                podcast: this.podcastMapper.from(podcast)
            };
        };
        this.currentDate = (podcast) => {
            const timeWithZone = this.getTimeWithZone(podcast.liveScheduleStartTime, podcast.liveScheduleEndTime, podcast.liveScheduleTimeZone);
            return descriptiveDate(timeWithZone.startTime);
        };
        this.getTimeWithZone = (liveScheduleStartTime, liveScheduleEndTime, liveScheduleTimeZone) => {
            const browserTimeZone = -(new Date().getTimezoneOffset() / 60);
            const timezone = liveScheduleTimeZone;
            let timezoneDifference = Math.abs(timezone - browserTimeZone);
            // negate the timezoneDifference behind the browser timezone
            if (timezone >= browserTimeZone) {
                timezoneDifference = -timezoneDifference;
            }
            const timezoneDifferenceHoursInMilliseconds = timezoneDifference * 3600000;
            const startDateTime = liveScheduleStartTime;
            const startDateTimeWithZone = startDateTime + timezoneDifferenceHoursInMilliseconds;
            const endDateTime = liveScheduleEndTime;
            const endDateTimeWithZone = endDateTime + timezoneDifferenceHoursInMilliseconds;
            return {
                startTime: startDateTimeWithZone,
                endTime: endDateTimeWithZone
            };
        };
        this.upcomingDate = (liveScheduleStartTime, liveScheduleEndTime, liveScheduleTimeZone) => {
            const timeWithZone = this.getTimeWithZone(liveScheduleStartTime, liveScheduleEndTime, liveScheduleTimeZone);
            const startDate = moment.utc(timeWithZone.startTime).format("HH:mm");
            const endDate = moment.utc(timeWithZone.endTime).format("HH:mm");
            return startDate + " - " + endDate;
        };
    }
    from(entity) {
        return this.upcoming(entity);
    }
}
