import Mapper from "../../../Mapper";
import CommentMapper from "./CommentMapper";
export default class CommentParentMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.commentMapper = new CommentMapper();
    }
    from(entity) {
        return {
            comments: entity.comments.map((commentJson) => this.commentMapper.from(commentJson)),
            skip: entity.skip
        };
    }
}
