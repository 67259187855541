import { RxFetch } from "../../RxFetch";
const fetchCommentsForPodcast = (podcastId, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/comment/podcast/${podcastId}/?skip=${skip}&limit=${limit}`);
};
const fetchCommentsForNews = (newsId, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/comment/news/${newsId}/?skip=${skip}&limit=${limit}`);
};
const postCommentForPodcast = (podcastId, body, accessToken, baseUrl) => {
    return new RxFetch(baseUrl).post(`/comment/podcast/${podcastId}`, {
        comment: body
    }, {
        "X-Token": accessToken
    });
};
const postCommentForNews = (newsId, body, accessToken, baseUrl) => {
    return new RxFetch(baseUrl).post(`/comment/news/${newsId}`, {
        comment: body
    }, {
        "X-Token": accessToken
    });
};
const fetchComments = (token, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/comment/?skip=${skip}&limit=${limit}`, {
        "X-Token": token
    });
};
const removeComment = (id, token, baseUrl) => {
    return new RxFetch(baseUrl).delete(`/comment/podcast/${id}`, {
        "X-Token": token
    });
};
export { fetchCommentsForPodcast, fetchCommentsForNews, postCommentForPodcast, postCommentForNews, fetchComments, removeComment };
