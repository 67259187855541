import {
  ScheduleActionType
} from "./ScheduleActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getAudio,
  getCovers,
  getSigningUrl,
  getPodcastSchdules,
  getPodcastScheduleById,
  getPodcastSchdulesForArtist,
  getInitialPodcastsWithArtists,
  deleteSchedule,
  createSchedule,
  updateSchedule,
  createWeeklySchedule,
  updateWeeklySchedule,
  searchPodcastSchedules,
  getInitialWeeklyPodcastsWithArtists
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const schedulePlaylistsFetchEpic = createEpic(
  ScheduleActionType.FETCH_INITIAL_PLAYLISTS,
  ErrorType.INLINE,
  () => getInitialWeeklyPodcastsWithArtists(apiConfig)
);

const scheduleFetchInitialEpic = createEpic(
  ScheduleActionType.FETCH_INITIAL_SCHEDULE,
  ErrorType.INLINE,
  () => getInitialPodcastsWithArtists(100, apiConfig)
);

const scheduleFetchInitialScheduleListEpic = createEpic(
  ScheduleActionType.RESET_INITIAL_SCHEDULE_LIST,
  ErrorType.INLINE,
  () => getPodcastSchdules(-1, 100, apiConfig)
);

const scheduleSearchByPodcastEpic = createEpic(
  ScheduleActionType.SEARCH_SCHEDULE_BY_TITLE,
  ErrorType.INLINE,
  (action) => searchPodcastSchedules(action.terms, apiConfig)
);

const schedulFetchByIdEpic = createEpic(
  ScheduleActionType.FETCH_PODCAST_BY_ID,
  ErrorType.INLINE,
  (action) => getPodcastScheduleById(action.id, apiConfig)
);

const scheduleFetchEpic = createEpic(
  ScheduleActionType.FETCH_PODCASTS,
  ErrorType.INLINE,
  (action) => getPodcastSchdules(action.skip, 100, apiConfig)
);

const scheduleByArtistFetchEpic = createEpic(
  ScheduleActionType.FETCH_PODCASTS_BY_ARTIST,
  ErrorType.INLINE,
  (action) => getPodcastSchdulesForArtist(action.artistId, -1, 100, apiConfig)
);

const scheduleByArtistMoreFetchEpic = createEpic(
  ScheduleActionType.FETCH_PODCASTS_BY_ARTIST_MORE,
  ErrorType.INLINE,
  (action) => getPodcastSchdulesForArtist(action.artistId, action.skip, 100, apiConfig)
);

const scheduleDeleteEpic = createEpic(
  ScheduleActionType.DELETE_SCHEDULE,
  ErrorType.MODAL,
  (action) => deleteSchedule(action.id, apiConfig)
);

const scheduleCreateEpic = createEpic(
  ScheduleActionType.CREATE_SCHEDULE,
  ErrorType.MODAL,
  (action) => createSchedule(action.values, apiConfig)
);

const scheduleUpdateEpic = createEpic(
  ScheduleActionType.UPDATE_SCHEDULE,
  ErrorType.MODAL,
  (action) => updateSchedule(action.id, action.values, apiConfig)
);

const scheduleCreateWeeklyEpic = createEpic(
  ScheduleActionType.CREATE_WEEKLY_SCHEDULE,
  ErrorType.MODAL,
  (action) => createWeeklySchedule(action.values, apiConfig)
);

const scheduleUpdateWeeklyEpic = createEpic(
  ScheduleActionType.UPDATE_WEEKLY_SCHEDULE,
  ErrorType.MODAL,
  (action) => updateWeeklySchedule(action.id, action.values, apiConfig)
);

const scheduleFetchAudioFilesEpic = createEpic(
  ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES,
  ErrorType.INLINE,
  (action) => getAudio(action.name, apiConfig)
);

const scheduleFetchCoverImageEpic = createEpic(
  ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES,
  ErrorType.INLINE,
  (action) => getCovers(action.name, apiConfig)
);

const scheduleFetchSignedUrlEpic = createEpic(
  ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL,
  ErrorType.INLINE,
  (action) => getSigningUrl(action.name, action.assetType, apiConfig)
);

export {
  schedulePlaylistsFetchEpic,
  scheduleFetchInitialEpic,
  scheduleFetchInitialScheduleListEpic,
  scheduleSearchByPodcastEpic,
  schedulFetchByIdEpic,
  scheduleFetchEpic,
  scheduleByArtistFetchEpic,
  scheduleByArtistMoreFetchEpic,
  scheduleDeleteEpic,
  scheduleCreateEpic,
  scheduleUpdateEpic,
  scheduleCreateWeeklyEpic,
  scheduleUpdateWeeklyEpic,
  scheduleFetchAudioFilesEpic,
  scheduleFetchCoverImageEpic,
  scheduleFetchSignedUrlEpic
};
