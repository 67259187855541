import React from "react";
import {
  Typography,
  WithWidth,
  isWidthDown,
  withWidth,
  Breadcrumbs,
  Link
} from "@material-ui/core";
import {
  Route
} from "react-router-dom";
import {
  Breakpoint
} from "@material-ui/core/styles/createBreakpoints";
import {
  Selected
} from "../../../shared/Events";

const variant = (width: Breakpoint) => {
  return isWidthDown("md", width) ? "subtitle1" : "h6";
};

interface TitleViewProps {
  menuScheduleSelected: Selected;
  menuPlaylistsSelected: Selected;
  menuAssetsSelected: Selected;
  menuNewsSelected: Selected;
  menuArtistsSelected: Selected;
  menuCategoriesSelected: Selected;
  menuTagsSelected: Selected;
}

const TitleView = (props: TitleViewProps & WithWidth) => (
  <Typography variant={variant(props.width)}>
    <Route path={"/schedule"} exact>
      Schedule
    </Route>
    <Route path={"/schedule/edit"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuScheduleSelected}
        >
          Schedule
        </Link>
        <Typography color="textPrimary">Manage</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/schedule/add"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuScheduleSelected}
        >
          Schedule
        </Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/playlists"} exact>
      Playlists
    </Route>
    <Route path={"/playlists/edit"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuPlaylistsSelected}
        >
          Playlists
        </Link>
        <Typography color="textPrimary">Manage</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/playlists/add"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuPlaylistsSelected}
        >
          Playlists
        </Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/news"} exact>
      News
    </Route>
    <Route path={"/news/edit"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuNewsSelected}
        >
          News
        </Link>
        <Typography color="textPrimary">Manage</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/news/add"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuNewsSelected}
        >
          News
        </Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/artists"} exact>
      Hosts &amp; Artists
    </Route>
    <Route path={"/artists/edit"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuArtistsSelected}
        >
          Hosts &amp; Artists
        </Link>
        <Typography color="textPrimary">Manage</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/artists/add"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuArtistsSelected}
        >
          Hosts &amp; Artists
        </Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/categories"} exact>
      Moods &amp; Cateogories
    </Route>
    <Route path={"/categories/edit"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuCategoriesSelected}
        >
          Moods &amp; Cateogories
        </Link>
        <Typography color="textPrimary">Manage</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/categories/add"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuCategoriesSelected}
        >
          Moods &amp; Cateogories
        </Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/comments"}>Comments</Route>
    <Route path={"/users"}>Users</Route>
    <Route path={"/tags"} exact>
      Tags
    </Route>
    <Route path={"/tags/:id"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuTagsSelected}
        >
          Tags
        </Link>
        <Typography color="textPrimary">View</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/assets/upload/cover"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuAssetsSelected}
        >
          Assets
        </Link>
        <Typography color="textPrimary">Upload cover image</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/assets/upload/audio"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{
            cursor: "pointer"
          }}
          color="inherit"
          onClick={props.menuAssetsSelected}
        >
          Assets
        </Link>
        <Typography color="textPrimary">Upload audio asset</Typography>
      </Breadcrumbs>
    </Route>
    <Route path={"/assets"} exact>
      Assets
    </Route>
  </Typography>
);

export default withWidth()(TitleView);
