import { RxFetch } from "../../RxFetch";
const fetchUsers = (token, skip, limit, baseUrl) => {
    return new RxFetch(baseUrl).get(`/user/list/?skip=${skip}&limit=${limit}`, {
        "X-Token": token
    });
};
const fetchUserCount = (token, baseUrl) => {
    return new RxFetch(baseUrl).get("/user/count/", {
        "X-Token": token
    });
};
export { fetchUsers, fetchUserCount };
