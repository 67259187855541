import React, {
  useState
} from "react";

import {
  HasChildren, WithClasses, WidthBreakpoint
} from "../../shared/Props";
import {
  Dialog,
  withStyles,
  withWidth,
  Toolbar,
  IconButton,
  Icon,
  Typography,
  Theme
} from "@material-ui/core";
import {
  isWidthDown
} from "@material-ui/core/withWidth";

interface ContentDialogProps {
  isOpen: boolean;
  title: string;
}

interface CloseButtonProps {
  onClose: () => void;
}

const styles = (theme: Theme) => ({
  parent: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: 680
    },
    [theme.breakpoints.up("lg")]: {
      width: 820
    }
  }
});

const ContentDialog = (
  props: HasChildren &
    ContentDialogProps &
    CloseButtonProps &
    WithClasses &
    WidthBreakpoint
) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Dialog
        open={isOpen || props.isOpen}
        onClose={() => {
          props.onClose();
          setOpen(false);
        }}
        maxWidth="md"
        fullScreen={isWidthDown("sm", props.width)}
      >
        <div className={props.classes.parent}>
          <CloseButton {...props} onClose={() => setOpen(false)} />
          {props.children}
        </div>
      </Dialog>
    </>
  );
};

const CloseButton = (
  props: CloseButtonProps & ContentDialogProps & WidthBreakpoint
) => {
  if (isWidthDown("sm", props.width)) {
    return (
      <Toolbar>
        <IconButton
          style={{
            outline: "none"
          }}
          edge="start"
          color="inherit"
          onClick={props.onClose}
          aria-label="close"
        >
          <Icon>close</Icon>
        </IconButton>
        <Typography variant="h6">{props.title}</Typography>
      </Toolbar>
    );
  } else {
    return (
      <Toolbar>
        <Typography variant="h6">{props.title}</Typography>
      </Toolbar>
    );
  }
};

export default withWidth()(
  withStyles(styles, {
    withTheme: true
  })(ContentDialog)
);
