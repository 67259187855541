import React from "react"
import {
  TableCell, Typography, TableCellProps
} from "@material-ui/core"

interface TableHeaderCellProps {
  title: string
}

const TableHeaderCell = (props: TableHeaderCellProps & TableCellProps) => (
  <TableCell {...props}>
    <Typography variant="subtitle1">{props.title}</Typography>
  </TableCell>
)

export default TableHeaderCell