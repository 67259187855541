import { DomainResponse } from "../DomainResponse";
import { fetchCategories, fetchCategory, fetchCategoryById, subscriptionExists, postCategory, putCategory, removeCategory } from "@rewindit/data";
import { of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import CategoryParentMapper from "./mapper/CategoryParentMapper";
import CategoryMapper from "./mapper/CategoryMapper";
import { podcastsResponseWithStars } from "../podcast/PodcastWithStars";
import { getPodcastsForCategoryByAppreciation, getPodcastsForCategory } from "../podcast/PodcastRepository";
import { getToken } from "../user/UserSessionRepository";
import VoidMapper from "../VoidMapper";
import { getTags } from "../tags/TagsRepository";
const categoryMapper = new CategoryMapper();
const categoryParentMapper = new CategoryParentMapper();
const voidMapper = new VoidMapper();
const getCategories = (domainConfig) => {
    return fetchCategories(domainConfig.baseUrl).pipe(map((categoryApi) => categoryParentMapper.mapFrom(categoryApi)), catchError((error) => of(DomainResponse.createError(error))));
};
const getCategoriesWithTags = (domainConfig) => {
    return fetchCategories(domainConfig.baseUrl).pipe(map((categoriesApi) => categoryParentMapper.mapFrom(categoriesApi)), mergeMap((categoriesDomain) => getTags(domainConfig).pipe(map((tagsDomain) => {
        if (categoriesDomain.entity === undefined)
            return DomainResponse.createFatalError();
        if (tagsDomain.entity === undefined)
            return DomainResponse.createFatalError();
        return DomainResponse.createSuccess({
            categories: categoriesDomain.entity,
            tags: tagsDomain.entity
        });
    }), catchError((error) => of(DomainResponse.createError(error))))));
};
const getCategoryWithPodcasts = (categoryName, domainConfig) => {
    return fetchCategory(categoryName, domainConfig.baseUrl).pipe(map((categoryApi) => categoryMapper.mapFrom(categoryApi)), map((response) => response.entity), mergeMap((category) => {
        if (category === undefined)
            return of(DomainResponse.createFatalError());
        return subscriptionExists(category.id).pipe(map((subscribed) => {
            return {
                category: category,
                subscribed: subscribed
            };
        }), mergeMap((categoryWithSubscription) => getPodcastsForCategory(categoryWithSubscription.category.id, -1, 40, domainConfig).pipe(mergeMap((podcasts) => {
            if (podcasts.entity === undefined)
                return of(DomainResponse.createFatalError());
            return podcastsResponseWithStars(podcasts.entity).pipe(mergeMap((podcastResponse) => getPodcastsForCategoryByAppreciation(categoryWithSubscription.category.id, domainConfig).pipe(map((podcastsByAppreciationResponse) => {
                if (podcastResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                if (podcastsByAppreciationResponse.entity === undefined)
                    return DomainResponse.createFatalError();
                return DomainResponse.createSuccess({
                    category: categoryWithSubscription.category,
                    podcasts: podcastResponse.entity,
                    mostAppreciatedPodcasts: podcastsByAppreciationResponse.entity,
                    subscribed: categoryWithSubscription.subscribed
                });
            }))));
        }))));
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getCategoryById = (id, domainConfig) => {
    return fetchCategoryById(id, domainConfig.baseUrl).pipe(map((api) => categoryMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const createCategory = (values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postCategory(values.name, values.description, values.imgUrl, values.tags, values.order, token, domainConfig.baseUrl).pipe(map((response) => voidMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const updateCategory = (id, values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putCategory(id, values.name, values.description, values.imgUrl, values.tags, values.order, token, domainConfig.baseUrl).pipe(map((response) => voidMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const deleteCategory = (id, domainConfig) => {
    return getToken().pipe(mergeMap((token) => removeCategory(id, token, domainConfig.baseUrl).pipe(map((response) => voidMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
export { getCategories, getCategoriesWithTags, getCategoryWithPodcasts, getCategoryById, createCategory, updateCategory, deleteCategory };
