import React from "react";
import {
  TextField
} from "@material-ui/core";

interface AppTextFieldProps {
  id: string;
  label: string;
  type: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
}

const AppTextField = (props: AppTextFieldProps) => (
  <TextField
    autoFocus={props.autoFocus}
    margin="dense"
    id={props.id}
    label={props.label}
    type={props.type}
    variant="outlined"
    fullWidth
    onChange={(event) => props.onChange(event.target.value)}
    InputLabelProps={{
      shrink: true
    }}
    color="secondary"
  />
);

export {
  AppTextField
};