import { RxFetch } from "../RxFetch";
const fetchArtists = (baseUrl) => {
    return new RxFetch(baseUrl).get("/artist/");
};
const fetchAllArtists = (baseUrl) => {
    return new RxFetch(baseUrl).get("/artist/all/");
};
const fetchArtistByName = (niceLink, baseUrl) => {
    return new RxFetch(baseUrl).get(`/artist/name/${niceLink}`);
};
const fetchArtistById = (id, baseUrl) => {
    return new RxFetch(baseUrl).get(`/artist/${id}`);
};
const postArtist = (name, quote, description, userId, websiteUrl, order, imgUrl, tags, token, baseUrl) => {
    return new RxFetch(baseUrl).post("/artist/", {
        name: name,
        quote: quote,
        description: description,
        userId: userId,
        websiteUrl: websiteUrl,
        order: order,
        imgUrl: imgUrl,
        tags: tags
    }, {
        "X-Token": token
    });
};
const putArtist = (id, name, quote, description, userId, websiteUrl, order, imgUrl, tags, token, baseUrl) => {
    return new RxFetch(baseUrl).put(`/artist/${id}`, {
        name: name,
        quote: quote,
        description: description,
        userId: userId,
        websiteUrl: websiteUrl,
        order: order,
        imgUrl: imgUrl,
        tags: tags
    }, {
        "X-Token": token
    });
};
export { fetchArtists, fetchAllArtists, fetchArtistByName, fetchArtistById, postArtist, putArtist };
