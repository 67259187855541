import { RxStorage } from "../../RxStorage";
import { map } from "rxjs/operators";
const storage = new RxStorage("StarListEntity");
const insertLocalStars = (entities) => {
    return storage.insertAll(entities);
};
const deleteLocalStar = (podcastId) => {
    return storage.filterReplace((star) => star.podcastId !== podcastId);
};
const starExists = (podcastId) => {
    return storage.exists((star) => star.podcastId === podcastId);
};
const getStarPodcastIds = () => {
    return storage
        .getItems()
        .pipe(map((stars) => stars.map((star) => star.podcastId)));
};
const getAllLocalStars = () => {
    return storage.getItems();
};
const podcastIsStarred = (podcastId) => {
    return storage.exists((item) => item.podcastId === podcastId);
};
const podcastIdsWithStar = (podcastIds) => {
    return storage
        .filter((item) => podcastIds.includes(item.podcastId))
        .pipe(map((stars) => {
        if (stars != null) {
            return stars.map((star) => star.podcastId);
        }
        else {
            return [];
        }
    }));
};
export { insertLocalStars, deleteLocalStar, starExists, getStarPodcastIds, getAllLocalStars, podcastIsStarred, podcastIdsWithStar };
