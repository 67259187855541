import {
  AnyAction
} from "redux";
import {
  Optional
} from "../../../../shared/Optional";
import {
  DomainErrorValue,
  PodcastSchedule,
  Artist,
  PodcastArtistParent,
  Asset
} from "@rewindit/domain";
import {
  asStarted,
  asSuccess,
  asError,
  startLoading,
  stopLoading,
  withError,
  withClearForm,
  startFormLoading,
  stopFormLoading,
  asModalError,
  withFormUpdated,
  withScrollPosition,
  resetScrollPosition
} from "../../../../shared/Actions";
import {
  ScheduleActionType
} from "./ScheduleActions";
import {
  AppRootActionType
} from "../../../root/model/AppRootActions";

export interface ScheduleState {
  lazyPodcast: Optional<PodcastSchedule>;
  podcasts: Optional<PodcastSchedule[]>;
  artists: Optional<Artist[]>;
  existingTags: Optional<string[]>;
  isLoading: boolean;
  error: Optional<string>;
  isLoadingMore: boolean;
  hasMoreItems: boolean;
  isLoadingList: boolean;
  errorList: Optional<string>;
  isDeleteLoading: boolean;
  deleteError: Optional<string>;
  onChangeRedirect: boolean;
  isFormLoading: boolean;
  formUpdated: boolean;
  viewPodcast: Optional<PodcastSchedule>;
  audioFileUrlsLoading: boolean;
  audioFileUrlsError: Optional<string>;
  audioFileUrls: Optional<Asset[]>;
  coverImageIsLoading: boolean;
  coverImageError: Optional<string>;
  coverImages: Optional<Asset[]>;
  signingUrlIsLoading: boolean;
  signingUrlError: Optional<string>;
  signingUrl: Optional<string>;
  audioSigningUrlError: Optional<string>;
  audioSigningUrl: Optional<string>;
  audioSigningUrlIsLoading: boolean;
  scrollPosition: number;
  searchTerms: Optional<string>;
  selectedArtistId: Optional<string>;
}

const initialState: ScheduleState = {
  lazyPodcast: undefined,
  podcasts: undefined,
  artists: undefined,
  existingTags: undefined,
  isLoading: false,
  error: undefined,
  isLoadingMore: false,
  errorList: undefined,
  hasMoreItems: true,
  isLoadingList: false,
  isDeleteLoading: false,
  deleteError: undefined,
  onChangeRedirect: false,
  isFormLoading: false,
  formUpdated: false,
  viewPodcast: undefined,
  audioFileUrlsLoading: false,
  audioFileUrlsError: undefined,
  audioFileUrls: undefined,
  coverImageIsLoading: false,
  coverImageError: undefined,
  coverImages: undefined,
  signingUrlIsLoading: false,
  signingUrlError: undefined,
  signingUrl: undefined,
  audioSigningUrlError: undefined,
  audioSigningUrl: undefined,
  audioSigningUrlIsLoading: false,
  scrollPosition: 0,
  searchTerms: undefined,
  selectedArtistId: undefined
};

const scheduleReducer = (
  state: ScheduleState = initialState,
  action: AnyAction
): ScheduleState => {
  switch (action.type) {
  case asStarted(ScheduleActionType.FETCH_INITIAL_PLAYLISTS):
    return startLoading(withoutChangeRedirect(state), action.skip);
  case asSuccess(ScheduleActionType.FETCH_INITIAL_PLAYLISTS):
    return stopLoading(withParent(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_INITIAL_PLAYLISTS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_INITIAL_SCHEDULE):
    return startLoading(withoutChangeRedirect(state), action.skip);
  case asSuccess(ScheduleActionType.FETCH_INITIAL_SCHEDULE):
    return stopLoading(withParent(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_INITIAL_SCHEDULE):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.RESET_INITIAL_SCHEDULE_LIST):
    return startLoadingList(state);
  case asSuccess(ScheduleActionType.RESET_INITIAL_SCHEDULE_LIST):
    return stopLoadingList(withInitialPodcasts(state, action.result.entity));
  case asError(ScheduleActionType.RESET_INITIAL_SCHEDULE_LIST):
    return stopLoadingList(withListError(state, action.result.error));

  case asStarted(ScheduleActionType.SEARCH_SCHEDULE_BY_TITLE):
    return startLoadingList(state);
  case asSuccess(ScheduleActionType.SEARCH_SCHEDULE_BY_TITLE):
    return stopLoadingList(withInitialPodcasts(state, action.result.entity));
  case asError(ScheduleActionType.SEARCH_SCHEDULE_BY_TITLE):
    return stopLoadingList(withListError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST):
    return startLoadingList(state);
  case asSuccess(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST):
    return stopLoadingList(withInitialPodcasts(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST):
    return stopLoadingList(withListError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_PODCAST_BY_ID):
    return startLoading(state, action.skip);
  case asSuccess(ScheduleActionType.FETCH_PODCAST_BY_ID):
    return stopLoading(withLazyPodcast(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_PODCAST_BY_ID):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_PODCASTS):
    return startLoading(state, action.skip);
  case asSuccess(ScheduleActionType.FETCH_PODCASTS):
    return stopLoading(withPodcasts(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_PODCASTS):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST_MORE):
    return startLoading(state, action.skip);
  case asSuccess(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST_MORE):
    return stopLoading(withPodcasts(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_PODCASTS_BY_ARTIST_MORE):
    return stopLoading(withError(state, action.result.error));

  case asStarted(ScheduleActionType.DELETE_SCHEDULE):
    return startDeleteLoading(state);
  case asSuccess(ScheduleActionType.DELETE_SCHEDULE):
    return withChangeRedirect(state);
  case asError(ScheduleActionType.DELETE_SCHEDULE):
    return withDeleteError(state, action.result.error);

  case asStarted(ScheduleActionType.CREATE_SCHEDULE):
    return startFormLoading(state);
  case asSuccess(ScheduleActionType.CREATE_SCHEDULE):
    return stopFormLoading(withChangeRedirect(resetScrollPosition(state)));
  case asModalError(ScheduleActionType.CREATE_SCHEDULE):
    return stopFormLoading(state);

  case asStarted(ScheduleActionType.UPDATE_SCHEDULE):
    return startFormLoading(state);
  case asSuccess(ScheduleActionType.UPDATE_SCHEDULE):
    return stopFormLoading(
      withFormUpdated(withUpdatedPodcast(state, action.result.entity))
    );
  case asModalError(ScheduleActionType.UPDATE_SCHEDULE):
    return stopFormLoading(state);

  case asStarted(ScheduleActionType.CREATE_WEEKLY_SCHEDULE):
    return startFormLoading(state);
  case asSuccess(ScheduleActionType.CREATE_WEEKLY_SCHEDULE):
    return stopFormLoading(withChangeRedirect(resetScrollPosition(state)));
  case asModalError(ScheduleActionType.CREATE_WEEKLY_SCHEDULE):
    return stopFormLoading(state);

  case asStarted(ScheduleActionType.UPDATE_WEEKLY_SCHEDULE):
    return startFormLoading(state);
  case asSuccess(ScheduleActionType.UPDATE_WEEKLY_SCHEDULE):
    return stopFormLoading(
      withFormUpdated(withUpdatedPodcast(state, action.result.entity))
    );
  case asModalError(ScheduleActionType.UPDATE_WEEKLY_SCHEDULE):
    return stopFormLoading(state);

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return startAudioFileLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return stopAudioFileLoading(withAudioFiles(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_SCHEDULE_AUDIO_FILES):
    return stopAudioFileLoading(
      withAudioFileUrlError(state, action.result.error)
    );

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return startCoverImageLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImages(state, action.result.entity)
    );
  case asError(ScheduleActionType.FETCH_SCHEDULE_COVER_IMAGES):
    return stopCoverImageLoading(
      withCoverImageError(state, action.result.error)
    );

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return startSigningUrlLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return stopSigningUrlLoading(withSigningUrl(state, action.result.entity));
  case asError(ScheduleActionType.FETCH_SCHEDULE_SIGNING_URL):
    return stopSigningUrlLoading(
      withSigningUrlError(state, action.result.error)
    );

  case asStarted(ScheduleActionType.FETCH_SCHEDULE_AUDIO_SIGNING_URL):
    return startAudioSigningUrlLoading(state);
  case asSuccess(ScheduleActionType.FETCH_SCHEDULE_AUDIO_SIGNING_URL):
    return stopAudioSigningUrlLoading(
      withAudioSigningUrl(state, action.result.entity)
    );
  case asError(ScheduleActionType.FETCH_SCHEDULE_AUDIO_SIGNING_URL):
    return stopAudioSigningUrlLoading(
      withAudioSigningUrlError(state, action.result.error)
    );

  case AppRootActionType.CLEAR_FORM:
    return withClearForm(state);

  case ScheduleActionType.SET_SCROLL_POSITION:
    return withScrollPosition(state, action.position);

  case ScheduleActionType.SET_SEARCH_TERMS:
    return {
      ...state, searchTerms: action.searchTerms
    };

  case ScheduleActionType.SET_SELECTED_ARTIST_ID:
    return {
      ...state, selectedArtistId: action.selectedArtistId
    };

  default:
    return {
      ...state
    };
  }
};

const startLoadingList = (state: ScheduleState) => {
  return {
    ...state,
    startLoadingList: true
  };
};

const withListError = (state: ScheduleState, error: string) => {
  return {
    ...state,
    errorList: error
  };
};

const stopLoadingList = (state: ScheduleState) => {
  return {
    ...state,
    startLoadingList: false
  };
};

const withPodcasts = (state: ScheduleState, podcasts: PodcastSchedule[]) => {
  if (podcasts.length === 0) return {
    ...state, hasMoreItems: false
  };
  if (state.podcasts === undefined) return {
    ...state, podcasts: podcasts
  };
  return {
    ...state,
    podcasts: state.podcasts.concat(podcasts),
    hasMoreItems: true
  };
};

const withInitialPodcasts = (state: ScheduleState, podcasts: PodcastSchedule[]) => {
  return {
    ...state,
    podcasts: podcasts,
    hasMoreItems: true
  };
};

const withLazyPodcast = (state: ScheduleState, podcast: PodcastSchedule) => {
  return {
    ...state,
    lazyPodcast: podcast
  };
};

const withParent = (state: ScheduleState, parent: PodcastArtistParent) => {
  return {
    ...state,
    podcasts: parent.podcasts,
    artists: parent.artists,
    existingTags: parent.tags
  };
};

const withUpdatedPodcast = (state: ScheduleState, newPodcast: PodcastSchedule) => {
  const updatePodcasts = state.podcasts ?? [];
  const replaceIndex = updatePodcasts.findIndex(
    (podcast) => podcast.id === newPodcast.id
  );
  if (replaceIndex === -1) return {
    ...state
  };
  updatePodcasts[replaceIndex] = newPodcast;
  const existingTags = state.existingTags ?? [];

  const difference = newPodcast.tags.filter((x) => !existingTags.includes(x));

  return {
    ...state,
    podcasts: updatePodcasts,
    existingTags: state.existingTags?.concat(difference)
  };
};

const withAudioFiles = (state: ScheduleState, urls: Asset[]) => {
  return {
    ...state,
    audioFileUrls: urls
  };
};

const startCoverImageLoading = (state: ScheduleState) => {
  return {
    ...state,
    coverImageIsLoading: true,
    coverImageError: undefined
  };
};

const stopCoverImageLoading = (state: ScheduleState) => {
  return {
    ...state,
    coverImageIsLoading: false
  };
};

const withCoverImageError = (state: ScheduleState, error: DomainErrorValue) => {
  return {
    ...state,
    coverImageError: error.body
  };
};

const withCoverImages = (state: ScheduleState, coverImages: Asset[]) => {
  return {
    ...state,
    coverImages: coverImages
  };
};

const startAudioFileLoading = (state: ScheduleState) => {
  return {
    ...state,
    audioFileUrlsLoading: true,
    audioFileUrlsError: undefined
  };
};

const stopAudioFileLoading = (state: ScheduleState) => {
  return {
    ...state,
    audioFileUrlsLoading: false
  };
};

const withAudioFileUrlError = (
  state: ScheduleState,
  error: DomainErrorValue
) => {
  return {
    ...state,
    audioFileUrlsError: error.body
  };
};

const startSigningUrlLoading = (state: ScheduleState) => {
  return {
    ...state,
    signingUrlIsLoading: true,
    signingUrlError: undefined
  };
};

const stopSigningUrlLoading = (state: ScheduleState) => {
  return {
    ...state,
    signingUrlIsLoading: false
  };
};

const withSigningUrl = (state: ScheduleState, signingUrl: string) => {
  return {
    ...state,
    signingUrl: signingUrl
  };
};

const withSigningUrlError = (state: ScheduleState, error: DomainErrorValue) => {
  return {
    ...state,
    signingUrlError: error.body
  };
};

const startAudioSigningUrlLoading = (state: ScheduleState) => {
  return {
    ...state,
    audioSigningUrlIsLoading: true,
    audioSigningUrlError: undefined
  };
};

const stopAudioSigningUrlLoading = (state: ScheduleState) => {
  return {
    ...state,
    audioSigningUrlIsLoading: false
  };
};

const withAudioSigningUrl = (state: ScheduleState, signingUrl: string) => {
  return {
    ...state,
    audioSigningUrl: signingUrl
  };
};

const withAudioSigningUrlError = (
  state: ScheduleState,
  error: DomainErrorValue
) => {
  return {
    ...state,
    audioSigningUrlError: error.body
  };
};

const startDeleteLoading = (state: ScheduleState) => {
  return {
    ...state,
    isDeleteLoading: true,
    deleteError: undefined
  };
};

const withDeleteError = (state: ScheduleState, error: DomainErrorValue) => {
  return {
    ...state,
    isDeleteLoading: false,
    deleteError: error.body
  };
};

const withoutChangeRedirect = (state: ScheduleState) => {
  return {
    ...state,
    onChangeRedirect: false
  };
};

const withChangeRedirect = (state: ScheduleState) => {
  return {
    ...state,
    isDeleteLoading: false,
    onChangeRedirect: true
  };
};

export {
  scheduleReducer
};
