import Mapper from "../../../Mapper";
export default class StarMapper extends Mapper {
    fromLocal(entity) {
        return {
            id: entity.id,
            podcastId: entity.podcastId,
            podcastTitle: entity.podcastTitle,
            podcastImgUrl: entity.podcastImgUrl,
            authorId: entity.authorId,
            authorName: entity.authorName,
            dateCreated: entity.dateCreated
        };
    }
    from(entity) {
        return {
            id: entity.id,
            podcastId: entity.podcastId,
            podcastTitle: entity.podcastDetails.title,
            podcastImgUrl: entity.podcastDetails.imgUrl,
            authorId: entity.author.id,
            authorName: entity.author.name,
            dateCreated: entity.dateCreated
        };
    }
}
