import {
  UsersActionType
} from "./UsersActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getInitialUsersWithCount,
  getUsers
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const usersInitialFetchEpic = createEpic(
  UsersActionType.FETCH_INITIAL_USERS,
  ErrorType.INLINE,
  () => getInitialUsersWithCount(apiConfig)
);

const usersFetchEpic = createEpic(
  UsersActionType.FETCH_USERS,
  ErrorType.INLINE,
  (action) => getUsers(action.skip, 100, apiConfig)
);

export {
  usersInitialFetchEpic, usersFetchEpic
};
