import {
  connectRouter,
  RouterState,
  routerMiddleware
} from "connected-react-router";

import {
  combineReducers
} from "redux";
import {
  createBrowserHistory
} from "history";

import {
  appRootReducer, AppRootState
} from "./root/model/AppRootReducer";
import {
  LoginState, loginReducer
} from "./user/login/model/LoginReducer";
import {
  ArtistsState,
  artistsReducer
} from "./portal/artists/model/ArtistsReducer";
import {
  CategoriesState,
  categoriesReducer
} from "./portal/categories/model/CategoriesReducer";
import {
  CommentsState,
  commentsReducer
} from "./portal/comments/model/CommentsReducer";
import {
  ScheduleState,
  scheduleReducer
} from "./portal/schedule/model/ScheduleReducer";
import {
  TagsState, tagsReducer
} from "./portal/tags/model/TagsReducer";
import {
  UsersState, usersReducer
} from "./portal/users/model/UsersReducer";
import {
  AssetsState,
  assetsReducer
} from "./portal/assets/model/AssetsReducer";

export interface RootState {
  router: RouterState;
  appRoot: AppRootState;
  login: LoginState;
  artists: ArtistsState;
  categories: CategoriesState;
  comments: CommentsState;
  schedule: ScheduleState;
  tags: TagsState;
  users: UsersState;
  assets: AssetsState;
}

const history = createBrowserHistory({
  basename: "/"
});

history.listen((_) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
});

const historyMiddleWare = routerMiddleware(history);

const createRootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  appRoot: appRootReducer,
  login: loginReducer,
  artists: artistsReducer,
  categories: categoriesReducer,
  comments: commentsReducer,
  schedule: scheduleReducer,
  tags: tagsReducer,
  users: usersReducer,
  assets: assetsReducer
});

export {
  createRootReducer, history, historyMiddleWare
};
