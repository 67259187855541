import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableHead
} from "@material-ui/core";
import {
  User
} from "@rewindit/domain";
import {
  SecondaryButton
} from "../../../../uikit/button/SecondaryButton";
import {
  PrimaryButton
} from "../../../../uikit/button/PrimaryButton";
import {
  LoadMore
} from "../../../../shared/Events";
import {
  LoadMoreProgress
} from "../../../../uikit/progress/LoadMoreProgress";
import TableHeaderCell from "../../../../uikit/div/TableHeaderCell";
import {
  IntersectionVisibleWrapper
} from "../../../../uikit/IntersectionVisibleWrapper";

interface UserListViewProps {
  users: User[];
  count: string;
  isLoadingMore: boolean;
  loadMore: LoadMore;
}

const UserListView = (props: UserListViewProps) => (
  <div>
    <div style={{
      width: "100%",
      marginTop: -48,
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <div style={{
        paddingBottom: 12
      }}>
        <Typography variant="h6">Total ({props.count})</Typography>
      </div>
    </div>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell title="Username / E-Mail Address" />
            <TableHeaderCell title="ID" />
            <TableHeaderCell title="Status" align="right" />
            <TableHeaderCell title="Join Date" align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <div>
                  <Typography variant="h6">{user.username}</Typography>
                </div>
                <div>
                  <Typography variant="body1">{user.emailAddress}</Typography>
                </div>
              </TableCell>
              <TableCell style={{
                width: 180
              }}>
                <Typography variant="subtitle2">{user.id}</Typography>
              </TableCell>
              <TableCell align="right" style={{
                width: 180
              }}>
                <ActiveStateView isActivated={user.isActivated} />
              </TableCell>
              <TableCell align="right" style={{
                width: 180
              }}>
                <Typography variant="body1">{user.dateCreated}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <LoadingIndicator {...props} />
  </div>
);

const LoadingIndicator = (props: UserListViewProps) => (
  <IntersectionVisibleWrapper
    onShow={() => props.loadMore(props.users[props.users.length - 1].timestamp)}
  >
    <LoadMoreProgress isLoading={props.isLoadingMore} />
  </IntersectionVisibleWrapper>
);

interface ActiveStateViewProps {
  isActivated: boolean;
}

const ActiveStateView = (props: ActiveStateViewProps) => {
  if (props.isActivated) {
    return (
      <PrimaryButton
        extraStyle={{
          border: "1px solid #999999"
        }}
        disabled
      >
        Active
      </PrimaryButton>
    );
  } else {
    return (
      <SecondaryButton
        color="secondary"
        extraStyle={{
          color: "black"
        }}
        disabled
      >
        Pending
      </SecondaryButton>
    );
  }
};

export default UserListView;
