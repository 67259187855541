import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";
import {
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import {
  Category
} from "@rewindit/domain";
import TagCloudView from "../../../../uikit/div/TagsCloudView";
import {
  SelectedId
} from "../../../../shared/Events";

interface CategoryListViewProps {
  categories: Category[];
  categorySelected: SelectedId;
}

const style: CSSProperties = {
  display: "block",
  width: 120,
  height: 120
};

const CategoryListView = (props: CategoryListViewProps) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {props.categories.map((category) => (
          <TableRow
            key={category.id}
            hover
            onClick={() => {
              props.categorySelected(category.id);
            }}
          >
            <TableCell style={{
              width: 120
            }}>
              <img src={category.imgUrl} alt={category.name} style={style} />
            </TableCell>
            <TableCell>
              <div>
                <Typography variant="h6">{category.name}</Typography>
              </div>
              <div>
                <TagCloudView tags={category.tags} />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CategoryListView;
