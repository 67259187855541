import Mapper from "../../Mapper";
import PodcastScheduleMapper from "./PodcastScheduleMapper";
export default class PodcastScheduleParentMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.podcastScheduleMapper = new PodcastScheduleMapper();
    }
    from(entity) {
        return entity.podcasts.map((podcast) => this.podcastScheduleMapper.from(podcast));
    }
}
