import Mapper from "../Mapper";
import StarMapper from "../social/star/mapper/StarMapper";
import SubscriptionMapper from "./subscription/mapper/SubscriptionMapper";
export default class UserSessionMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.starMapper = new StarMapper();
        this.subscriptionMapper = new SubscriptionMapper();
    }
    from(entity) {
        return {
            id: entity.id,
            username: entity.username,
            token: entity.token,
            userPushPreferences: {
                weekly: entity.preferences.email_weekly
            },
            stars: this.mapStars(entity.stars),
            subscriptions: this.mapSubscriptions(entity.subscriptions)
        };
    }
    mapStars(stars) {
        if (stars != null) {
            return stars.map((star) => this.starMapper.from(star));
        }
        else {
            return [];
        }
    }
    mapSubscriptions(subscriptions) {
        if (subscriptions != null) {
            return subscriptions.map((subscription) => this.subscriptionMapper.from(subscription));
        }
        else {
            return [];
        }
    }
}
