export var PodcastStatus;
(function (PodcastStatus) {
    PodcastStatus[PodcastStatus["RELEASED"] = 0] = "RELEASED";
    PodcastStatus[PodcastStatus["UPCOMING"] = 1] = "UPCOMING";
    PodcastStatus[PodcastStatus["LIVE"] = 2] = "LIVE";
    PodcastStatus[PodcastStatus["TERMINATED"] = 3] = "TERMINATED";
})(PodcastStatus || (PodcastStatus = {}));
const markAsStarredByUser = (podcast, starredByUser, adjustCounter = 0) => {
    return {
        id: podcast.id,
        title: podcast.title,
        description: podcast.description,
        streamUrl: podcast.streamUrl,
        imgUrl: podcast.imgUrl,
        liveScheduleStartTime: podcast.liveScheduleStartTime,
        liveScheduleStartDate: podcast.liveScheduleStartDate,
        liveScheduleEndTime: podcast.liveScheduleEndTime,
        liveScheduleEndDate: podcast.liveScheduleEndDate,
        liveScheduleTimeZone: podcast.liveScheduleTimeZone,
        niceLink: podcast.niceLink,
        commentCount: podcast.commentCount,
        starCount: podcast.starCount + adjustCounter,
        genre: podcast.genre,
        starredByUser: starredByUser,
        status: podcast.status,
        artist: podcast.artist,
        parts: podcast.parts,
        tags: podcast.tags,
        prettyDate: podcast.prettyDate,
        recentlyPlayed: podcast.recentlyPlayed
    };
};
export { markAsStarredByUser };
