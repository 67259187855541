import { DomainResponse } from "../DomainResponse";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { fetchCovers, fetchAudio, getSignedUrl } from "@rewindit/data";
import AssetMapper from "./mapper/AssetMapper";
import SignedUrlMapper from "./mapper/SignedUrlMapper";
import { getToken } from "../user/UserSessionRepository";
const assetMapper = new AssetMapper();
const signedUrlMapper = new SignedUrlMapper();
const getCovers = (artistName, domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchCovers(artistName, token, domainConfig.baseUrl).pipe(map((response) => assetMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const getAudio = (artistName, domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchAudio(artistName, token, domainConfig.baseUrl).pipe(map((response) => assetMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const getSigningUrl = (name, type, domainConfig) => {
    if (type === "cover") {
        return getPodcastCoverSignedUrl(name, domainConfig);
    }
    else {
        return getPodcastAudioSignedUrl(name, domainConfig);
    }
};
const getPodcastCoverSignedUrl = (name, domainConfig) => {
    return getToken().pipe(mergeMap((token) => {
        var _a;
        return getSignedUrl((_a = process.env.REACT_APP_S3_IMAGE_BUCKET_NAME) !== null && _a !== void 0 ? _a : "", "image/jpg", name, "jpg", token, domainConfig.baseUrl).pipe(map((response) => signedUrlMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))));
    }));
};
const getPodcastAudioSignedUrl = (name, domainConfig) => {
    return getToken().pipe(mergeMap((token) => {
        var _a;
        return getSignedUrl((_a = process.env.REACT_APP_S3_AUDIO_BUCKET_NAME) !== null && _a !== void 0 ? _a : "", "audio/mpeg", name, "mp3", token, domainConfig.baseUrl).pipe(map((response) => signedUrlMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))));
    }));
};
export { getCovers, getAudio, getSigningUrl };
