import { DomainResponse } from "../../DomainResponse";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import StarMapper from "./mapper/StarMapper";
import StarPodcastMapper from "./mapper/StarPodcastMapper";
import VoidMapper from "../../VoidMapper";
import { getAllLocalStars, insertLocalStars, deleteLocalStar, fetchStarsForAuth, postStar, deleteStar } from "@rewindit/data";
import { getToken } from "../../user/UserSessionRepository";
import { DomainError } from "../../DomainError";
const starMapper = new StarMapper();
const starPodcastMapper = new StarPodcastMapper();
const voidMapper = new VoidMapper();
const getStars = () => {
    return getAllLocalStars().pipe(map((starEntities) => DomainResponse.createSuccess(starEntities.map((entity) => starMapper.fromLocal(entity)))));
};
const getStarPodcastsForAuth = (skip, limit, domainConfig) => {
    return getToken().pipe(mergeMap((token) => fetchStarsForAuth(skip, limit, token, domainConfig.baseUrl)), map((api) => starPodcastMapper.mapFrom(api)), catchError((error) => {
        const domainError = DomainError.parse(error);
        return of(DomainResponse.fromError(domainError));
    }));
};
const createStar = (podcastId, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postStar(podcastId, token, domainConfig.baseUrl)), map((api) => starMapper.mapFrom(api)), mergeMap((response) => {
        const star = response.entity;
        if (star === undefined)
            return of(DomainResponse.createFatalError());
        return insertLocalStars([
            {
                id: star.id,
                podcastId: star.podcastId,
                podcastImgUrl: star.podcastImgUrl,
                podcastTitle: star.podcastTitle,
                authorId: star.authorId,
                authorName: star.authorName,
                dateCreated: star.dateCreated
            }
        ]).pipe(map(() => response));
    }), catchError((error) => {
        const domainError = DomainError.parse(error);
        return of(DomainResponse.fromError(domainError));
    }));
};
const removeStar = (podcastId, domainConfig) => {
    return getToken().pipe(mergeMap((token) => deleteStar(podcastId, token, domainConfig.baseUrl)), map((api) => voidMapper.mapFrom(api)), mergeMap((response) => deleteLocalStar(podcastId).pipe(map(() => response))), catchError((error) => {
        const domainError = DomainError.parse(error);
        if (domainError.action === "ALREADY_UNAPPRECIATED") {
            return deleteLocalStar(podcastId).pipe(map(() => DomainResponse.createSuccess(void 0)));
        }
        else {
            return of(DomainResponse.fromError(domainError));
        }
    }));
};
const toggleStar = (podcastId, starredByUser, domainConfig) => {
    return (starredByUser ? removeStar(podcastId, domainConfig) : createStar(podcastId, domainConfig).pipe(map((response) => {
        if (response.isSuccessful()) {
            return DomainResponse.createSuccess(void 0);
        }
        else {
            if (response.error === undefined)
                return DomainResponse.createFatalError();
            return DomainResponse.fromError(response.error);
        }
    }))).pipe(map((response) => {
        if (response.isSuccessful())
            return DomainResponse.createSuccess({
                podcastId: podcastId,
                removeStar: starredByUser
            });
        if (response.error === undefined)
            return DomainResponse.createFatalError();
        return DomainResponse.fromError(response.error);
    }));
};
export { getStars, getStarPodcastsForAuth, createStar, removeStar, toggleStar };
