import {
  CommentsActionType
} from "./CommentsActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getComments, deleteComment
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const commentsFetchEpic = createEpic(
  CommentsActionType.FETCH_COMMENTS,
  ErrorType.INLINE,
  () => getComments(-1, 100, apiConfig)
);

const commentsFetchMoreEpic = createEpic(
  CommentsActionType.FETCH_MORE_COMMENTS,
  ErrorType.INLINE,
  (action) => getComments(action.skip, 100, apiConfig)
);

const commentDeleteEpic = createEpic(
  CommentsActionType.DELETE_COMMENT,
  ErrorType.INLINE,
  (action) => deleteComment(action.id, apiConfig)
);

export {
  commentsFetchEpic, commentsFetchMoreEpic, commentDeleteEpic
};
