import { UserSessionInvalid } from "./user/UserSessionInvalid";
export var DomainErrorType;
(function (DomainErrorType) {
    DomainErrorType["message"] = "message";
    DomainErrorType["networkError"] = "networkError";
    DomainErrorType["sessionExpired"] = "sessionExpired";
    DomainErrorType["adminRequired"] = "adminRequired";
    DomainErrorType["fatal"] = "fatal";
})(DomainErrorType || (DomainErrorType = {}));
export class DomainError {
    constructor(type, body, action) {
        this.type = type;
        this.body = body;
        this.action = action;
    }
    static message(error, action) {
        if (action != null) {
            return new Error(`{"type": "${DomainErrorType.message}", "body": "${error}", "action": "${action}"}`);
        }
        else {
            return new Error(`{"type": "${DomainErrorType.message}", "body": "${error}"}`);
        }
    }
    static networkError() {
        return new Error(`{"type": "${DomainErrorType.networkError}"}`);
    }
    static sessionExpired() {
        return new Error(`{"type": "${DomainErrorType.sessionExpired}"}`);
    }
    static fatal() {
        return new Error(`{"type": "${DomainErrorType.fatal}}"}`);
    }
    static parse(error) {
        if (error instanceof UserSessionInvalid) {
            return new DomainError(DomainErrorType.sessionExpired, undefined, undefined);
        }
        else {
            return JSON.parse(error.message);
        }
    }
}
