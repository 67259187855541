import Mapper from "../../Mapper";
export default class CategoryMapper extends Mapper {
    from(entity) {
        return {
            id: entity.id,
            name: entity.name,
            description: entity.description,
            imgUrl: entity.imgUrl,
            tags: entity.tags,
            order: entity.order ? `${entity.order}` : undefined
        };
    }
}
