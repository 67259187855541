enum AppRootActionType {
  USER_AUTHENTICATED = "USER_AUTHENTICATED",
  CLEAR_SESSION = "CLEAR_SESSION",
  CLOSE_ADMIN_REQUIRED_DIALOG = "CLOSE_ADMIN_REQUIRED_DIALOG",
  CLOSE_ERROR_DIALOG = "CLOSE_ERROR_DIALOG",
  LOGOUT = "LOGOUT",
  SHOW_MENU_DIALOG = "SHOW_MENU_DIALOG",
  CLOSE_MENU_DIALOG = "CLOSE_MENU_DIALOG",
  CLEAR_FORM = "CLEAR_FORM",
}

export {
  AppRootActionType
};
