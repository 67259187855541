import {
  ArtistsActionType
} from "./ArtistsActions";
import {
  ErrorType
} from "../../../../shared/Actions";
import {
  getCovers,
  getSigningUrl,
  getArtistsWithTags,
  updateArtist,
  createArtist,
  getAllArtists
} from "@rewindit/domain";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const artistFetchEpic = createEpic(
  ArtistsActionType.FETCH_ARTISTS,
  ErrorType.INLINE,
  () => getAllArtists(apiConfig)
);

const artistsAndTagsFetchEpic = createEpic(
  ArtistsActionType.FETCH_ARTISTS_FOR_TAGS,
  ErrorType.INLINE,
  () => getArtistsWithTags(apiConfig)
);

const artistsCreateEpic = createEpic(
  ArtistsActionType.CREATE_ARTIST,
  ErrorType.MODAL,
  (action) => createArtist(action.values, apiConfig)
);

const artistsUpdateEpic = createEpic(
  ArtistsActionType.UPDATE_ARTIST,
  ErrorType.MODAL,
  (action) => updateArtist(action.id, action.values, apiConfig)
);

const artistCoverImageEpic = createEpic(
  ArtistsActionType.FETCH_ARTIST_COVER_IMAGES,
  ErrorType.INLINE,
  (action) => getCovers(action.name, apiConfig)
);

const artistSignedUrlEpic = createEpic(
  ArtistsActionType.FETCH_ARTIST_SIGNING_URL,
  ErrorType.INLINE,
  (action) => getSigningUrl(action.name, action.assetType, apiConfig)
);

export {
  artistFetchEpic,
  artistsAndTagsFetchEpic,
  artistsCreateEpic,
  artistsUpdateEpic,
  artistCoverImageEpic,
  artistSignedUrlEpic
};
