import React, {
  useState, useEffect
} from "react";
import {
  Paper,
  Chip
} from "@material-ui/core";
import {
  SelectedId
} from "../../../../shared/Events";
import ConfirmDialog from "../../../..//uikit/dialog/ConfirmDialog";
import {
  ProgressOrError
} from "../../../..//uikit/progress/ProgressOrError";
import {
  Optional
} from "../../../../shared/Optional";
import unwrapValue from "../../../../shared/UnwrapValue";

interface TagListViewProps {
  isLoading: boolean;
  error: Optional<string>;
  tags: Optional<string[]>;
  tagSelected: SelectedId;
  deleteTagSelected: SelectedId;
  isDeleteLoading: boolean;
  isDeleteTagDialogReady: boolean;
  deleteError: Optional<string>;
  resetDeleteTagDialog: () => void;
  loadTags: () => void;
}

const TagListView = (props: TagListViewProps) => {
  const {
    isDeleteLoading,
    deleteError,
    deleteTagSelected,
    tags,
    resetDeleteTagDialog,
    isDeleteTagDialogReady,
    tagSelected,
    loadTags
  } = props;
  const [pendingTag, setPendingTag] = useState<string | undefined>(undefined);
  const [isDeleteDialogOpen, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (!tags) loadTags();
  }, [tags, loadTags]);

  return <>
    <ConfirmDialog
      title={`Delete "${pendingTag}" tag`}
      body="Are you sure you want to delete this tag? (It will also be removed from all podcasts)"
      isOpen={isDeleteDialogOpen && isDeleteTagDialogReady}
      isLoading={isDeleteLoading}
      error={deleteError}
      handleAgree={() => {
        if (pendingTag) deleteTagSelected(pendingTag);
      }}
      handleDismiss={() => {
        resetDeleteTagDialog();
        setOpenDeleteDialog(false);
        setPendingTag(undefined);
      }}
    />
    <ProgressOrError
      retryClicked={() => {
        loadTags();
      }}
      {...props}
    >
      {unwrapValue(
        (unwrappedTags) => (
          <Paper>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%"
            }}>
              {unwrappedTags.map((tag) =>
                <Chip key={tag} style={{
                  marginTop: 8, marginRight: 4, marginBottom: 8, marginLeft: 4
                }} label={tag} onClick={() => {
                  tagSelected(tag)
                }} onDelete={() => {
                  resetDeleteTagDialog();
                  setPendingTag(tag);
                  setOpenDeleteDialog(true);
                }} />
              )}
            </div>
          </Paper>
        ),
        tags
      )}
    </ProgressOrError>
  </>
};

export default TagListView;
