import React, {
  useEffect, useState
} from "react";
import {
  ScheduleState
} from "./model/ScheduleReducer";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import ScheduleListView from "./view/ScheduleListView";
import unwrapValue from "../../../shared/UnwrapValue";
import {
  LoadMore
} from "../../../shared/Events";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import ScheduleForm from "./form/ScheduleForm";
import {
  ScheduleFormValues, ScheduleWeeklyFormValues
} from "@rewindit/domain";
import {
  Optional
} from "../../..//shared/Optional";
import {
  AssetType
} from "../assets/model/AssetType";

interface ScheduleComponentProps extends ScheduleState, RouteComponentProps {
  loadPlaylists: () => void;
  loadPodcast: (id: string) => void;
  loadPodcasts: () => void;
  loadPodcastsByArtist: (artistId: string) => void;
  loadMore: LoadMore;
  loadMoreArtistId: (artistId: string, skip: number) => void;
  podcastSelected: (niceLink: string, path: SchedulePath) => void;
  loadAudioFiles: (name: string) => void;
  loadCoverImages: (name: string) => void;
  loadSigningUrl: (name: string, assetType: AssetType) => void;
  deleteSchedule: (id: string) => void;
  reloadPodcasts: () => void;
  reloadPlaylists: () => void;
  createSchedule: (values: ScheduleFormValues) => void;
  updateSchedule: (id: string, values: ScheduleFormValues) => void;
  createWeeklySchedule: (values: ScheduleWeeklyFormValues) => void;
  updateWeeklySchedule: (id: string, values: ScheduleWeeklyFormValues) => void;
  loadAudioSigningUrl: (name: string) => void;
  setScrollPosition: (position: number) => void;
  searchByTitle: (terms: string) => void;
  resetInitialScheduleList: () => void;
  setSelectedArtistId: (artistId: Optional<string>) => void;
  setSearchTerms: (terms: Optional<string>) => void;
}

export type SchedulePath = "playlists" | "schedule";

const ScheduleComponent = (props: ScheduleComponentProps) => {
  const {
    podcasts,
    loadPodcasts,
    loadPlaylists,
    onChangeRedirect,
    location
  } = props;

  const [lastLoadedPath, setLastLoadedPath] = useState("");
  const [searchActive, setSearchActive] = useState(false);

  const pathName: SchedulePath =
    location !== undefined &&
      location.pathname !== null &&
      location.pathname.includes("playlists")
      ? "playlists"
      : "schedule";

  useEffect(() => {
    if (
      podcasts === undefined ||
      onChangeRedirect ||
      lastLoadedPath !== pathName
    ) {
      if (pathName === "playlists") {
        loadPlaylists();
      } else {
        loadPodcasts();
      }
      setLastLoadedPath(pathName);
    }
  }, [
    podcasts,
    loadPodcasts,
    onChangeRedirect,
    location,
    loadPlaylists,
    pathName,
    lastLoadedPath,
    setLastLoadedPath
  ]);

  return (
    <ProgressOrError
      {...props}
      retryClicked={() => {
        props.loadPodcasts();
      }}
    >
      <Switch>
        <Route
          path={[
            "/schedule/edit/:id",
            "/schedule/add",
            "/playlists/edit/:id",
            "/playlists/add"
          ]}
        >
          {unwrapValue(
            (podcasts) => unwrapValue(
              (artists) => (
                <ScheduleForm {...props} artists={artists} podcasts={podcasts} />
              ),
              props.artists
            ),
            props.podcasts
          )}
        </Route>
        <Route path={"/"}>
          {unwrapValue(
            (podcasts) => unwrapValue(
              (artists) => (
                <ScheduleListView
                  {...props}
                  searchActive={searchActive}
                  searchByTitle={(terms: string) => {
                    setSearchActive(true);
                    props.setSelectedArtistId(undefined);
                    props.searchByTitle(terms);
                  }}
                  termsChanged={props.setSearchTerms}
                  resetInitialScheduleList={() => {
                    setSearchActive(false);
                    props.resetInitialScheduleList();
                  }}
                  searchTerms={props.searchTerms}
                  hasMoreItems={
                    pathName === "schedule" ? props.hasMoreItems : false
                  }
                  showSearch={pathName === "schedule"}
                  podcasts={podcasts}
                  artists={artists}
                  retryClicked={() => {
                    if (props.searchTerms !== undefined) {
                      props.searchByTitle(props.searchTerms);
                    } else if (props.selectedArtistId !== undefined) {
                      props.loadPodcastsByArtist(props.selectedArtistId);
                    } else {
                      if (pathName === "schedule") {
                        props.loadPodcasts();
                      } else {
                        props.loadPlaylists();
                      }
                    }
                  }}
                  podcastSelected={(niceLink) => {
                    props.podcastSelected(niceLink, pathName);
                  }}
                  selectedArtist={props.selectedArtistId}
                  filterByArtist={(artistId) => {
                    if (artistId !== undefined) {
                      props.loadPodcastsByArtist(artistId);
                    } else {
                      props.resetInitialScheduleList();
                    }
                    props.setSelectedArtistId(artistId);
                    props.setSearchTerms(undefined);
                  }}
                  setScrollPosition={props.setScrollPosition}
                  scrollPosition={props.scrollPosition}
                />
              ),
              props.artists
            ),
            props.podcasts
          )}
        </Route>
      </Switch>
    </ProgressOrError>
  );
};

export default withRouter(ScheduleComponent);
