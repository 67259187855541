import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Grid,
  Paper
} from "@material-ui/core";
import {
  upcomingDate,
  dayOfWeek,
  timestampFromMinutesHours,
  descriptiveMoment,
  upcomingMoment
} from "../../../../date/DateTimeFormatter";
import StatusView from "./StatusView";
import {
  Artist, PodcastSchedule, isPodcastIncomplete
} from "@rewindit/domain";
import TagCloudView from "../../../../uikit/div/TagsCloudView";
import {
  LoadMore, SelectedId
} from "../../../../shared/Events";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import LazyLoadingWindow, {
  renderItem,
  RowData
} from "../../../../uikit/div/LazyLoadingWindow";
import {
  ListChildComponentProps
} from "react-window";
import {
  Optional
} from "../../../../shared/Optional";
import SearchTextView from "./SearchTextView";
import unwrapValue from "../../../../shared/UnwrapValue";
import ArtistDropdown from "../../../../uikit/form/ArtistDropdown";
import {
  encodeCloudFrontUrl
} from "../../../../utils/CloudFront";

interface ScheduleListViewProps {
  showSearch: boolean;
  searchActive: boolean;
  isLoadingList: boolean;
  errorList: Optional<string>;
  podcasts: PodcastSchedule[];
  artists: Artist[];
  loadMore?: LoadMore;
  loadMoreArtistId?: (artistId: string, skip: number) => void;
  isLoadingMore: boolean;
  hasMoreItems: boolean;
  scrollPosition: number;
  searchTerms?: string;
  selectedArtist?: string;
  podcastSelected: SelectedId;
  retryClicked: () => void;
  setScrollPosition: (position: number) => void;
  searchByTitle?: (terms: string) => void;
  termsChanged?: (terms: string) => void;
  resetInitialScheduleList?: () => void;
  filterByArtist?: (artistId: string) => void;
}

const ScheduleListView = (props: ScheduleListViewProps) => {
  const dataItem: RowData<PodcastSchedule> = {
    items: props.podcasts,
    selected: props.podcastSelected
  };

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: props.showSearch ? "flex" : "none"
        }}
      >
        <div style={{
          flex: 1, marginRight: 16
        }}>
          <SearchTextView
            searchTerms={props.searchTerms}
            resetSearch={() => {
              if (props.resetInitialScheduleList !== undefined) {
                props.resetInitialScheduleList();
              }
            }}
            triggerSearch={(terms) => {
              if (props.searchByTitle !== undefined) {
                props.searchByTitle(terms);
              }
            }}
            termsChanged={props.termsChanged}
          />
        </div>
        <div style={{
          width: 380, backgroundColor: "#FFFFFF"
        }}>
          <ArtistDropdown
            artists={props.artists}
            name="artist"
            value={props.artists.find(
              (artist) => artist.id === props.selectedArtist
            )}
            onValueChanged={(artist) => {
              if (props.filterByArtist !== undefined) {
                props.filterByArtist(artist?.id ?? undefined)
              }
            }}
          />
        </div>
      </div>
      <ProgressOrError
        isLoading={props.isLoadingList}
        error={props.errorList}
        retryClicked={props.retryClicked}
      >
        {unwrapValue(
          (podcasts) => (
            <div>
              <Table component={Paper}>
                <TableBody component="div" style={{
                  width: "100%"
                }}>
                  <LazyLoadingWindow<PodcastSchedule>
                    itemHeight={148}
                    hasNextPage={props.hasMoreItems}
                    isNextPageLoading={props.isLoadingMore}
                    rowData={dataItem}
                    loadNextPage={() => {
                      if (podcasts.length > 0 && !props.searchActive) {
                        const selectedArtist = props.selectedArtist
                        if (selectedArtist !== undefined) {
                          return new Promise(() => {
                            if (props.loadMoreArtistId !== undefined) {
                              props.loadMoreArtistId(
                                selectedArtist,
                                podcasts[podcasts.length - 1].liveScheduleStartTime
                              )
                            }
                          });
                        } else {
                          return new Promise(() => {
                            if (props.loadMore !== undefined) {
                              props.loadMore(
                                podcasts[podcasts.length - 1].liveScheduleStartTime
                              )
                            }
                          });
                        }
                      } else {
                        return null;
                      }
                    }}
                    setScrollPosition={props.setScrollPosition}
                    scrollPosition={props.scrollPosition}
                    items={(itemProps: ListChildComponentProps) => renderItem<PodcastSchedule>(itemProps, (props) => {
                      const podcast = props.dataItem.items[props.index];
                      return (
                        <TableRow
                          key={podcast.id}
                          onClick={() => {
                            props.dataItem.selected(podcast.id);
                          }}
                          style={{
                            ...props.style,
                            opacity: isPodcastIncomplete(podcast) ? 0.5 : 1,
                            width: "100%",
                            minWidth: "100%"
                          }}
                          component="div"
                        >
                          <TableCell component="div" scope="row">
                            <img
                              src={
                                podcast.imgUrl ? encodeCloudFrontUrl(podcast.imgUrl, 120) : process.env.PUBLIC_URL + "/app_icon.png"
                              }
                              alt={podcast.title}
                              style={{
                                width: 120, height: 120
                              }}
                            />
                          </TableCell>
                          <TableCell component="div" style={{
                            width: 440
                          }}>
                            <Typography variant="h6">
                              {podcast.title}
                            </Typography>
                            <Grid
                              container
                              style={{
                                marginTop: 8
                              }}
                              alignItems="center"
                            >
                              <Button variant="contained" color="secondary">
                                {podcast.artist.name}
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                  marginLeft: 8, marginRight: 8
                                }}
                              >
                                {podcast.genre}
                              </Button>
                              <StatusView podcast={podcast} />
                            </Grid>
                          </TableCell>
                          <TableCell component="div" style={{
                            width: 460
                          }}>
                            <TagCloudView tags={podcast.tags} />
                          </TableCell>
                          <TableCell
                            component="div"
                            align="right"
                            style={{
                              width: 180
                            }}
                          >
                            <PrimaryDate podcast={podcast} />
                            <TimeDate podcast={podcast} />
                            <Typography variant="caption">
                              GMT {podcast.liveScheduleTimeZone}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  />
                </TableBody>
              </Table>
            </div>
          ),
          props.podcasts
        )}
      </ProgressOrError>
    </>
  );
};

interface PrimaryDateProps {
  podcast: PodcastSchedule;
}

const PrimaryDate = (props: PrimaryDateProps) => {
  if (props.podcast.type === "WEEKLY" && props.podcast.recurring !== undefined) {
    return (
      <Typography variant="body1">
        {dayOfWeek(props.podcast.recurring.day)}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1">
        {descriptiveMoment(props.podcast.liveScheduleStartDate)}
      </Typography>
    );
  }
};

interface TimeDateProps {
  podcast: PodcastSchedule;
}

const TimeDate = (props: TimeDateProps) => {
  if (props.podcast.type === "WEEKLY" && props.podcast.recurring !== undefined) {
    return (
      <Typography variant="h6">
        {upcomingDate(
          timestampFromMinutesHours(
            props.podcast.recurring.startMinutes,
            props.podcast.recurring.startHour
          ),
          timestampFromMinutesHours(
            props.podcast.recurring.endMinutes,
            props.podcast.recurring.endHour
          )
        )}
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6">
        {upcomingMoment(
          props.podcast.liveScheduleStartDate,
          props.podcast.liveScheduleEndDate
        )}
      </Typography>
    );
  }
};

export default ScheduleListView;
