import React, {
  useEffect
} from "react";
import {
  CommentsState
} from "./model/CommentsReducer";
import CommentListView from "./view/CommentListView";
import {
  ProgressOrError
} from "../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../shared/UnwrapValue";
import {
  SelectedId, LoadMore
} from "../../../shared/Events";

interface CommentsComponentProps extends CommentsState {
  loadComments: () => void;
  deleteComment: SelectedId;
  loadMore: LoadMore;
  reloadComments: () => void;
}

const CommentsComponent = (props: CommentsComponentProps) => {
  const {
    comments, loadComments
  } = props;

  useEffect(() => {
    if (comments === undefined) {
      loadComments();
    }
  }, [comments, loadComments]);

  return (
    <ProgressOrError
      retryClicked={() => {
        loadComments();
      }}
      {...props}
    >
      {unwrapValue(
        (comments) => (
          <CommentListView {...props} comments={comments} />
        ),
        props.comments
      )}
    </ProgressOrError>
  );
};

export default CommentsComponent;
