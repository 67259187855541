import {
  ErrorType
} from "../../../../shared/Actions";
import {
  login
} from "@rewindit/domain";
import {
  LoginActionType
} from "./LoginActions";
import {
  apiConfig
} from "../../../../data/ApiConfig";
import {
  createEpic
} from "../../../../shared/CreateEpic";

const loginEpic = createEpic(
  LoginActionType.POST_LOGIN,
  ErrorType.INLINE,
  (action) => login(action.username, action.password, apiConfig)
);

export {
  loginEpic
};
