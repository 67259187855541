import React from "react";
import ContentDialog from "../../../uikit/dialog/ContentDialog";
import {
  Typography, DialogActions
} from "@material-ui/core";
import {
  PrimaryButton
} from "../../../uikit/button/PrimaryButton";

interface AdminRequiredViewProps {
  showAdminRequiredModal: boolean;
  closeAdminRequiredDialog: () => void;
}

const AdminRequiredView = (props: AdminRequiredViewProps) => {
  return showDialog(
    () => (
      <ContentDialog
        isOpen={true}
        title="Admin Required"
        onClose={props.closeAdminRequiredDialog}
      >
        <Typography variant="body1">
          You need administrator privileges to perform this action.
        </Typography>
        <DialogActions>
          <PrimaryButton
            onClick={() => {
              props.closeAdminRequiredDialog();
            }}
          >
            OK
          </PrimaryButton>
        </DialogActions>
      </ContentDialog>
    ),
    props.showAdminRequiredModal
  );
};

const showDialog = (view: () => JSX.Element, showAdminRequiredModal: boolean): JSX.Element | null => {
  if (showAdminRequiredModal) {
    return view();
  } else {
    return null;
  }
};

export {
  AdminRequiredView
};
