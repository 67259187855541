import React from "react";
import {
  HasChildren, WithClasses
} from "../../shared/Props";
import {
  Theme, withStyles
} from "@material-ui/core";

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: 248
    },
    [theme.breakpoints.up("lg")]: {
      width: 310
    }
  }
});

const LeftDivMinor = (props: HasChildren & WithClasses) => {
  return <div className={props.classes.root}>{props.children}</div>
}

export default withStyles(styles, {
  withTheme: true
})(LeftDivMinor);
