import {
  connect
} from "react-redux";
import TagsComponent from "./TagsComponent";
import {
  RootState
} from "../../rootReducer";
import {
  AnyAction
} from "redux";
import {
  Dispatch
} from "react";
import {
  TagsActionType
} from "./model/TagsActions";
import {
  push
} from "connected-react-router";

const mapStateToProps = (state: RootState) => {
  return {
    ...state.tags
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loadTags: () => {
      dispatch({
        type: TagsActionType.FETCH_TAGS
      });
    },
    tagSelected: (tag: string) => {
      dispatch(push(`/tags/${tag}`));
    },
    podcastSelected: (id: string) => {
      dispatch(push(`/schedule/edit/${id}`));
    },
    loadAssociatedPodcasts: (tag: string) => {
      dispatch({
        type: TagsActionType.FETCH_TAG_ASSOCIATED_PODCAST,
        tag: tag
      });
    },
    setScrollPosition: (position: number) => {
      dispatch({
        type: TagsActionType.SET_SCROLL_POSITION,
        position: position
      });
    },
    deleteTagSelected: (name: string) => {
      dispatch({
        type: TagsActionType.REMOVE_TAG,
        name: name
      });
    },
    resetDeleteTagDialog: () => {
      dispatch({
        type: TagsActionType.RESET_DELETE_TAG_DILOAG
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsComponent);
