import { DomainResponse } from "../DomainResponse";
import { markAsStarredByUser } from "./Podcast";
import { fetchReleasedPodcasts, fetchPodcastsForArtist, fetchPodcastsForCategory, fetchPodcastsForGenre, fetchRelatedPodcasts, fetchRandomPodcast, fetchPodcast, fetchPodcastsForArtistByGenre, fetchPodcastsForArtistGroupedByGenre, fetchPodcastsForArtistOrderByAppreciation, fetchPodcastsForTag, fetchPodcastsForCategoryByAppreciation, fetchSearchPodcasts, fetchUpcomingPodcastsForArtist, fetchCommentsForPodcast, podcastIsStarred, fetchAllWeeklyPodcasts, postSchedule, postWeeklySchedule, putSchedule, putWeeklySchedule, removeSchedule, fetchPodcasts } from "@rewindit/data";
import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import PodcastParentMapper from "./mapper/PodcastParentMapper";
import PodcastMapper from "./mapper/PodcastMapper";
import PodcastGenreGroupMapper from "./mapper/PodcastGenreGroupMapper";
import CommentParentMapper from "../social/comment/mapper/CommentParentMapper";
import { getTags } from "../tags/TagsRepository";
import UpcomingMapper from "./mapper/UpcomingMapper";
import { podcastsResponseWithStars } from "./PodcastWithStars";
import { getToken } from "../user/UserSessionRepository";
import { getArtists } from "../artist/ArtistRepository";
import VoidMapper from "../VoidMapper";
import PodcastScheduleParentMapper from "./mapper/PodcastScheduleParentMapper";
import PodcastScheduleMapper from "./mapper/PodcastScheduleMapper";
const podcastParentMapper = new PodcastParentMapper();
const podcastMapper = new PodcastMapper();
const podcastScheduleParentMapper = new PodcastScheduleParentMapper();
const podcastScheduleMapper = new PodcastScheduleMapper();
const podcastGenreGroupMapper = new PodcastGenreGroupMapper();
const commentParentMapper = new CommentParentMapper();
const upcomingMapper = new UpcomingMapper();
const voidMapper = new VoidMapper();
const getPodcastSchdules = (skip, limit, domainConfig) => {
    return fetchPodcasts(skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastSchdulesForArtist = (id, skip, limit, domainConfig) => {
    return fetchPodcastsForArtist(id, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastScheduleById = (podcastId, domainConfig) => {
    return fetchPodcast("id", podcastId, domainConfig.baseUrl).pipe(map((api) => podcastScheduleMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getReleasedPodcasts = (skip, limit, domainConfig) => {
    return fetchReleasedPodcasts(skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForArtist = (id, skip, limit, domainConfig) => {
    return fetchPodcastsForArtist(id, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getUpcomingPodcastsForArtist = (id, skip, limit, domainConfig) => {
    return fetchUpcomingPodcastsForArtist(id, skip, limit, domainConfig.baseUrl).pipe(map((api) => upcomingMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForArtistByGenre = (artistId, genreName, skip, domainConfig) => {
    return fetchPodcastsForArtistByGenre(artistId, genreName, skip, 40, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForArtistGroupedByGenre = (artistId, domainConfig) => {
    return fetchPodcastsForArtistGroupedByGenre(artistId, domainConfig.baseUrl).pipe(map((api) => podcastGenreGroupMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForArtistOrderByAppreciation = (artistId, domainConfig) => {
    return fetchPodcastsForArtistOrderByAppreciation(artistId, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForCategory = (id, skip, limit, domainConfig) => {
    return fetchPodcastsForCategory(id, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForCategoryByAppreciation = (id, domainConfig) => {
    return fetchPodcastsForCategoryByAppreciation(id, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForTag = (tag, skip, limit, domainConfig) => {
    return fetchPodcastsForTag(tag, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastsForGenre = (name, skip, limit, domainConfig) => {
    return fetchPodcastsForGenre(name, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getRelatedPodcasts = (podcastId, artistId, genreName, offset, limit, domainConfig) => {
    return fetchRelatedPodcasts(podcastId, artistId, genreName, offset, limit, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getRandomPodcasts = (podcastId, artistId, genreName, domainConfig) => {
    return fetchRandomPodcast(podcastId, artistId, genreName, 1, domainConfig.baseUrl).pipe(map((api) => podcastParentMapper.mapFrom(api)), map((response) => response.entity), mergeMap((podcasts) => {
        if (podcasts === undefined)
            return of(DomainResponse.createFatalError());
        return podcastsResponseWithStars(podcasts);
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const getPodcastByLink = (niceLink, commentLimit, domainConfig) => {
    return getPodcast("niceLink", niceLink, commentLimit, domainConfig);
};
const getPodcastById = (podcastId, commentLimit, domainConfig) => {
    return getPodcast("id", podcastId, commentLimit, domainConfig);
};
const getPodcast = (by, niceLinkOrId, commentLimit, domainConfig) => {
    return fetchPodcast(by, niceLinkOrId, domainConfig.baseUrl).pipe(map((podcastApi) => podcastMapper.mapFrom(podcastApi)), map((response) => response.entity), mergeMap((podcast) => {
        if (podcast === undefined)
            return of(DomainResponse.createFatalError());
        return podcastIsStarred(podcast.id).pipe(map((isStarred) => isStarred ? markAsStarredByUser(podcast, true) : podcast), mergeMap((podcast) => fetchCommentsForPodcast(podcast.id, -1, commentLimit, domainConfig.baseUrl).pipe(map((commentApi) => commentParentMapper.mapFrom(commentApi)), mergeMap((commentParent) => getRelatedPodcasts(podcast.id, podcast.artist.id, podcast.genre, podcast.liveScheduleEndTime, 5, domainConfig).pipe(map((podcastsByGenre) => {
            if (commentParent.entity === undefined)
                return DomainResponse.createFatalError();
            if (podcastsByGenre.entity === undefined)
                return DomainResponse.createFatalError();
            return DomainResponse.createSuccess({
                podcast: podcast,
                comments: commentParent.entity.comments,
                recommendedPodcasts: podcastsByGenre.entity,
                time: upcomingMapper.upcomingDate(podcast.liveScheduleStartTime, podcast.liveScheduleEndTime, podcast.liveScheduleTimeZone)
            });
        }))))));
    }), catchError((error) => of(DomainResponse.createError(error))));
};
const searchPodcastsByArtist = (artistId, skip, limit, domainConfig) => {
    return fetchPodcastsForArtist(artistId, skip, limit, domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const searchPodcastSchedules = (terms, domainConfig) => {
    return fetchSearchPodcasts(terms, domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const getInitialWeeklyPodcastsWithArtists = (domainConfig) => {
    return getWeeklyPodcasts(domainConfig).pipe(mergeMap((weeklyPodcastResponse) => getArtists(domainConfig).pipe(mergeMap((artistsResponse) => getTags(domainConfig).pipe(mergeMap((tagsResponse) => {
        if (weeklyPodcastResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        if (tagsResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        if (artistsResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        return of(DomainResponse.createSuccess({
            podcasts: weeklyPodcastResponse.entity,
            artists: artistsResponse.entity,
            tags: tagsResponse.entity
        }));
    }))))), catchError((error) => of(DomainResponse.createError(error))));
};
const getInitialPodcastsWithArtists = (limit, domainConfig) => {
    return getPodcastSchdules(-1, limit, domainConfig).pipe(mergeMap((podcastsResponse) => getArtists(domainConfig).pipe(mergeMap((artistsResponse) => getTags(domainConfig).pipe(mergeMap((tagsResponse) => {
        if (podcastsResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        if (artistsResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        if (tagsResponse.entity === undefined)
            return of(DomainResponse.createFatalError());
        return of(DomainResponse.createSuccess({
            podcasts: podcastsResponse.entity,
            artists: artistsResponse.entity,
            tags: tagsResponse.entity
        }));
    }))))), catchError((error) => of(DomainResponse.createError(error))));
};
const getWeeklyPodcasts = (domainConfig) => {
    return fetchAllWeeklyPodcasts(domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
const createSchedule = (values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postSchedule(values.title, values.description, values.genre, values.type, values.audioStreamUrl, values.liveStreamUrl, values.imgUrl, values.liveScheduleStartTime, values.liveScheduleEndTime, values.liveScheduleTimeZone, values.artistId, values.tags, values.parts.map((part, index) => {
        return {
            position: index,
            title: part.title,
            description: part.description,
            startSeconds: part.startSeconds,
            endSeconds: part.endSeconds
        };
    }), token, domainConfig.baseUrl).pipe(map((response) => podcastScheduleMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const updateSchedule = (id, values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putSchedule(id, values.title, values.description, values.genre, values.type, values.audioStreamUrl, values.liveStreamUrl, values.imgUrl, values.liveScheduleStartTime, values.liveScheduleEndTime, values.liveScheduleTimeZone, values.artistId, values.tags, values.parts.map((part, index) => {
        return {
            position: index,
            title: part.title,
            description: part.description,
            startSeconds: part.startSeconds,
            endSeconds: part.endSeconds
        };
    }), token, domainConfig.baseUrl).pipe(map((response) => podcastScheduleMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const createWeeklySchedule = (values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => postWeeklySchedule(values.title, values.description, values.liveStreamUrl, values.imgUrl, values.liveScheduleTimeZone, values.artistId, values.genre, values.tags, values.recurring.day, values.recurring.startHour, values.recurring.startMinutes, values.recurring.endHour, values.recurring.endMinutes, token, domainConfig.baseUrl).pipe(map((response) => podcastScheduleMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const updateWeeklySchedule = (id, values, domainConfig) => {
    return getToken().pipe(mergeMap((token) => putWeeklySchedule(id, values.title, values.description, values.liveStreamUrl, values.imgUrl, values.liveScheduleTimeZone, values.artistId, values.genre, values.tags, values.recurring.day, values.recurring.startHour, values.recurring.startMinutes, values.recurring.endHour, values.recurring.endMinutes, token, domainConfig.baseUrl).pipe(map((response) => podcastScheduleMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
const deleteSchedule = (id, domainConfig) => {
    return getToken().pipe(mergeMap((token) => removeSchedule(id, token, domainConfig.baseUrl).pipe(map((response) => voidMapper.mapFrom(response)), catchError((error) => of(DomainResponse.createError(error))))));
};
export { getPodcastSchdules, getPodcastSchdulesForArtist, getPodcastScheduleById, getReleasedPodcasts, getPodcastsForArtist, getUpcomingPodcastsForArtist, getPodcastsForArtistByGenre, getPodcastsForArtistGroupedByGenre, getPodcastsForArtistOrderByAppreciation, getPodcastsForCategory, getPodcastsForCategoryByAppreciation, getPodcastsForTag, getPodcastsForGenre, getRelatedPodcasts, getRandomPodcasts, getPodcastByLink, getPodcastById, searchPodcastsByArtist, searchPodcastSchedules, getInitialWeeklyPodcastsWithArtists, getInitialPodcastsWithArtists, getWeeklyPodcasts, deleteSchedule, createSchedule, updateSchedule, createWeeklySchedule, updateWeeklySchedule };
