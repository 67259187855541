import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import {
  AppWithState
} from "./app/components/App";

ReactDOM.hydrate(<AppWithState />, document.getElementById("root"));
