import React from "react";
import {
  HasChildren
} from "../../shared/Props";

const style = {
  paddingBottom: 16
};

export const HeaderBaselineDiv = (props: HasChildren) => {
  return <div style={style}>{props.children}</div>;
};
