import React from "react";
import {
  HasChildren,
  Clickable,
  Stylable,
  Disablable
} from "../../shared/Props";
import {
  Button
} from "@material-ui/core";
import {
  CSSProperties
} from "@material-ui/styles";

const style: CSSProperties = {
  outline: "none",
  color: "#000000"
};

interface ButtonProps {
  type?: "submit" | "reset" | "button";
  fullWidth?: boolean;
}

const PrimaryButton = (
  props: HasChildren & Clickable & Stylable & Disablable & ButtonProps
) => (
  <Button
    fullWidth={props.fullWidth}
    style={{
      ...style, ...props.extraStyle
    }}
    onClick={props.onClick}
    variant="contained"
    color="primary"
    disabled={props.disabled}
    type={props.type}
  >
    {props.children}
  </Button>
);

export {
  PrimaryButton
};
