import React from "react";
import {
  PodcastSchedule,
  PodcastStatus
} from "@rewindit/domain";
import {
  Icon
} from "@material-ui/core";

interface StatusViewProps {
  podcast: PodcastSchedule;
}

const StatusView = (props: StatusViewProps) => {
  switch (props.podcast.status) {
  case PodcastStatus.LIVE:
    return <Icon>rss_feed</Icon>;
  case PodcastStatus.RELEASED:
    return <Icon>check_circle_outline</Icon>;
  case PodcastStatus.TERMINATED:
    return <Icon>remove_circle_outline</Icon>;
  case PodcastStatus.UPCOMING:
    return <Icon>schedule</Icon>;
  default:
    return <></>
  }
};

export default StatusView;
