import React from "react";
import InfiniteLoader from "react-window-infinite-loader";
import {
  FixedSizeList as List,
  ListChildComponentProps,
  ListOnItemsRenderedProps
} from "react-window";
import {
  SelectedId
} from "../../shared/Events";
import {
  useWindowDimensions
} from "../WindowDimensions";

export interface RowData<T> {
  items: T[];
  selected: SelectedId;
}

export interface RenderChildComponentProps<T> extends ListChildComponentProps {
  dataItem: RowData<T>
}

export function renderItem<T>(
  props: ListChildComponentProps,
  render: (props: RenderChildComponentProps<T>
  ) => JSX.Element): JSX.Element {
  return render({
    ...props,
    dataItem: props.data as RowData<T>
  });
}

interface LazyLoadingWindowProps<T> {
  itemHeight: number;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  rowData: RowData<T>;
  setScrollPosition: (position: number) => void;
  scrollPosition: number;
  loadNextPage: () => Promise<void> | null;
  items: (
    data: ListChildComponentProps
  ) => React.ReactNode | React.ReactNode[];
}

function LazyLoadingWindow<T>(props: LazyLoadingWindowProps<T>) {
  const itemCount = props.hasNextPage
    ? props.rowData.items.length + 1
    : props.rowData.items.length;

  const loadMoreItems = props.isNextPageLoading
    ? (_startIndex: number, _stopIndex: number) => null
    : props.loadNextPage;

  const isItemLoaded = (index: number) =>
    !props.hasNextPage || index < props.rowData.items.length;

  const Item = (childProps: ListChildComponentProps) => {
    if (!isItemLoaded(childProps.index)) return <></>;
    return <>{props.items(childProps)}</>;
  };

  const {
    height
  } = useWindowDimensions();

  const headerHeight = 148; // height of the portal header, this could be resolved programatically

  const initialScrollOffset =
    props.scrollPosition === 0
      ? 0
      : props.itemHeight * props.scrollPosition + props.itemHeight / 3;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({
        onItemsRendered, ref
      }) => {
        return (
          <List
            height={height - headerHeight}
            itemCount={itemCount}
            itemSize={props.itemHeight}
            initialScrollOffset={initialScrollOffset}
            onItemsRendered={(renderProps: ListOnItemsRenderedProps) => {
              props.setScrollPosition(renderProps.visibleStartIndex);
              onItemsRendered(renderProps);
            }}
            itemData={props.rowData}
            ref={ref}
            width="100%"
          >
            {Item}
          </List>
        );
      }}
    </InfiniteLoader>
  );
}

export default LazyLoadingWindow;
