import { of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { DomainResponse } from "../DomainResponse";
import TagsMapper from "./mapper/TagsMapper";
import { fetchArtistTags, fetchTagAutocomplete, fetchTags, fetchPodcastsForTag, deleteTag } from "@rewindit/data";
import TagParentMapper from "./mapper/TagParentMapper";
import PodcastScheduleParentMapper from "../podcast/mapper/PodcastScheduleParentMapper";
import VoidMapper from "../VoidMapper";
import { getToken } from "../user/UserSessionRepository";
const tagsMapper = new TagsMapper();
const tagParentMapper = new TagParentMapper();
const podcastScheduleParentMapper = new PodcastScheduleParentMapper();
const voidMapper = new VoidMapper();
export const getArtistTags = (artistId, domainConfig) => {
    return fetchArtistTags(artistId, domainConfig.baseUrl).pipe(map((api) => tagsMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export const getTagAutocomplete = (hint, domainConfig) => {
    return fetchTagAutocomplete(hint, domainConfig.baseUrl).pipe(map((api) => tagsMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))));
};
export const getTags = (domainConfig) => {
    return fetchTags(domainConfig.baseUrl).pipe(map((tagsApi) => tagParentMapper.mapFrom(tagsApi)), catchError((error) => of(DomainResponse.createError(error))));
};
export const getTagAssociation = (tag, domainConfig) => {
    return fetchPodcastsForTag(tag, -1, 100, domainConfig.baseUrl).pipe(map((api) => podcastScheduleParentMapper.mapFrom(api)), map((response) => {
        if (response.entity === undefined)
            return DomainResponse.createFatalError();
        return DomainResponse.createSuccess({
            tagName: tag,
            podcasts: response.entity
        });
    }), catchError((error) => of(DomainResponse.createError(error))));
};
export const removeTag = (name, domainConfig) => {
    return getToken().pipe(mergeMap((token) => deleteTag(name, token, domainConfig.baseUrl).pipe(map((api) => voidMapper.mapFrom(api)), catchError((error) => of(DomainResponse.createError(error))))));
};
