import { __awaiter } from "tslib";
import { Observable } from "rxjs";
import { ApiResponse } from "./ApiResponse";
class RxFetch {
    constructor(rootUrl) {
        this.get = (route, headers) => {
            return this.send(fetch(this.rootUrl + route, {
                method: "GET",
                headers: Object.assign({ Accept: "application/json" }, headers)
            }));
        };
        this.post = (route, body, headers) => {
            return this.send(fetch(this.rootUrl + route, {
                method: "POST",
                headers: Object.assign({ Accept: "application/json", "Content-Type": "application/json" }, headers),
                body: this.jsonBody(body)
            }));
        };
        this.put = (route, body, headers) => {
            return this.send(fetch(this.rootUrl + route, {
                method: "PUT",
                headers: Object.assign({ Accept: "application/json", "Content-Type": "application/json" }, headers),
                body: this.jsonBody(body)
            }));
        };
        this.delete = (route, headers) => {
            return this.send(fetch(this.rootUrl + route, {
                method: "DELETE",
                headers: Object.assign({ Accept: "application/json" }, headers)
            }));
        };
        this.jsonBody = (body) => {
            if (body != null) {
                return JSON.stringify(body);
            }
            else {
                return null;
            }
        };
        this.send = (fetch) => {
            return new Observable((observer) => {
                fetch
                    .then((response) => this.parseResponse(response))
                    .then((apiResponse) => {
                    observer.next(apiResponse);
                    observer.complete();
                })
                    .catch((err) => observer.error(err));
            });
        };
        this.rootUrl = rootUrl;
    }
    parseResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (response.status >= 200 && response.status < 300) {
                let entity;
                try {
                    entity = yield response.json();
                }
                catch (_a) {
                    entity = undefined;
                }
                return new ApiResponse(response.status, entity, undefined);
            }
            else {
                let apiError;
                try {
                    apiError = yield response.json();
                }
                catch (_b) {
                    apiError = undefined;
                }
                return new ApiResponse(response.status, undefined, apiError);
            }
        });
    }
}
export { RxFetch };
