import { RxFetch } from "../RxFetch";
const fetchCategory = (name, baseUrl) => {
    return new RxFetch(baseUrl).get(`/category/name/${name}`);
};
const fetchCategories = (baseUrl) => {
    return new RxFetch(baseUrl).get("/category/");
};
const fetchCategoryById = (id, baseUrl) => {
    return new RxFetch(baseUrl).get(`/category/${id}`);
};
const postCategory = (name, description, imgUrl, tags, order, token, baseUrl) => {
    return new RxFetch(baseUrl).post("/category/", {
        name: name,
        description: description,
        imgUrl: imgUrl,
        tags: tags,
        order: order
    }, {
        "X-Token": token
    });
};
const putCategory = (id, name, description, imgUrl, tags, order, token, baseUrl) => {
    return new RxFetch(baseUrl).put(`/category/${id}`, {
        name: name,
        description: description,
        imgUrl: imgUrl,
        tags: tags,
        order: order
    }, {
        "X-Token": token
    });
};
const removeCategory = (id, token, baseUrl) => {
    return new RxFetch(baseUrl).delete(`/category/${id}`, {
        "X-Token": token
    });
};
export { fetchCategory, fetchCategories, fetchCategoryById, postCategory, putCategory, removeCategory };
