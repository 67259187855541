import Mapper from "../../Mapper";
import UserSessionMapper from "../UserSessionMapper";
export class UserFacebookSessionMapper extends Mapper {
    constructor() {
        super(...arguments);
        this.userSessionMapper = new UserSessionMapper();
    }
    from(entity) {
        if (entity.isActivated) {
            if (entity.user != null) {
                return {
                    userSession: this.userSessionMapper.from(entity.user)
                };
            }
            else {
                throw new Error();
            }
        }
        else {
            if (entity.facebook != null) {
                return {
                    userUnassociated: {
                        userId: entity.facebook.userId,
                        accessToken: entity.facebook.accessToken
                    }
                };
            }
            else {
                throw new Error();
            }
        }
    }
}
