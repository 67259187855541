import React from "react";
import {
  Provider, ReactReduxContext
} from "react-redux";
import {
  ConnectedRouter
} from "connected-react-router";
import {
  store
} from "./rootStore";
import {
  history
} from "./rootReducer";
import {
  ThemeProvider
  ,
  createMuiTheme
} from "@material-ui/core/styles";

import {
  green, blueGrey
} from "@material-ui/core/colors";
import AppRootConnect from "./root/AppRootConnect";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green.A400
    },
    secondary: {
      main: blueGrey[900]
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  }
});

const AppWithState = () => (
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <ThemeProvider theme={theme}>
        <AppRootConnect />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

export {
  AppWithState
};
