import {
  ActionsObservable, ofType
} from "redux-observable";
import {
  mergeMap, map, catchError, startWith
} from "rxjs/operators";
import {
  responseAction, ErrorType, genericErrorAction, startedAction
} from "../shared/Actions";
import {
  AnyAction
} from "redux";
import {
  Observable
} from "rxjs";
import {
  DomainResponse
} from "@rewindit/domain";

function createEpic<R>(
  type: string,
  errorType: ErrorType,
  observable: (action: AnyAction) => Observable<DomainResponse<R>>
) {
  return (action$: ActionsObservable<AnyAction>) => action$.pipe(
    ofType(type),
    mergeMap((action) =>
      observable(action).pipe(
        map<DomainResponse<R>, AnyAction>((response) =>
          responseAction(type, response, errorType)
        ),
        map((successAction) => {
          return {
            ...action,
            ...successAction
          };
        }),
        catchError(() =>
          genericErrorAction(action.type, errorType).pipe(
            map((errorAction) => {
              return {
                ...action,
                ...errorAction
              };
            })
          )
        ),
        startWith({
          ...action,
          ...startedAction(type)
        })
      )
    )
  );
}

export {
  createEpic
};