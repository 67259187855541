import {
  SelectInputProps
} from "@material-ui/core/Select/SelectInput";
import React from "react";
import DropdownView from "./DropdownView";

interface DayOption {
  value: number
  label: string
}

const days: DayOption[] = [
  {
    value: 1,
    label: "Sunday"
  },
  {
    value: 2,
    label: "Monday"
  },
  {
    value: 3,
    label: "Tuesday"
  },
  {
    value: 4,
    label: "Wednesday"
  },
  {
    value: 5,
    label: "Thursday"
  },
  {
    value: 6,
    label: "Friday"
  },
  {
    value: 7,
    label: "Saturday"
  }
];

interface DayDropdownProps {
  name?: string;
  value?: number;
  onChange: SelectInputProps["onChange"];
}

function DayDropdown(props: DayDropdownProps) {
  return <DropdownView<number>
    label="Day"
    name={props.name}
    value={props.value}
    options={days}
    onChange={props.onChange}
    required
  />
}

export default DayDropdown;
