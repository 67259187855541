import MomentUtils from "@date-io/moment";
import {
  Moment, MomentInput
} from "moment";

class UTCUtils extends MomentUtils {
  format(date: Moment, formatKey: string): string {
    return this.moment.utc(date).format(formatKey);
  }

  parse(value: string, format: string): Moment {
    return this.moment.utc(value, format, true);
  }

  date(value?: MomentInput): Moment {
    const moment = this.moment.utc(value);
    if (this.locale !== undefined) moment.locale(this.locale);
    return moment;
  }
}

export default UTCUtils;
