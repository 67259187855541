import React from "react";
import {
  Typography, Paper, Grid, Icon
} from "@material-ui/core";

interface InfoDivProps {
  title: string;
  body: string;
  visible?: boolean;
}
export const InfoDiv = (props: InfoDivProps) => {
  if (props.visible) {
    return (
      <Paper style={{
        backgroundColor: "#CDE0F4", marginBottom: 12
      }}>
        <Grid container alignItems="center">
          <div style={{
            padding: 16
          }}>
            <Icon>info</Icon>
          </div>
          <Grid style={{
            paddingTop: 12, paddingBottom: 12
          }}>
            <Typography variant="subtitle1" style={{
              fontWeight: "bold"
            }}>
              {props.title}
            </Typography>
            <Typography variant="body1" style={{
              color: "#333333"
            }}>
              {props.body}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return <></>;
  }
};
