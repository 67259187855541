import React from "react";
import {
  HasChildren, Hidden
} from "../../shared/Props";
import {
  Grid
} from "@material-ui/core";

const FormRow = (props: HasChildren & Hidden) => (
  <Grid container style={{
    marginTop: 8, marginBottom: 8
  }}>
    {props.children}
  </Grid>
);

export default FormRow;
