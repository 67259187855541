import {
  combineEpics
} from "redux-observable";

import {
  rootLogoutEpic
} from "./root/model/AppRootEpic";

import {
  loginEpic
} from "./user/login/model/LoginEpic";

import {
  artistFetchEpic,
  artistsAndTagsFetchEpic,
  artistsCreateEpic,
  artistsUpdateEpic,
  artistCoverImageEpic,
  artistSignedUrlEpic
} from "./portal/artists/model/ArtistsEpic";

import {
  categoriesFetchEpic,
  categoriesFetchCoverImageEpic,
  categoriesFetchCoverImageSignedUrlEpic,
  categoryCreateEpic,
  categoryUpdateEpic,
  categoryDeleteEpic
} from "./portal/categories/model/CategoriesEpic";

import {
  commentsFetchEpic,
  commentsFetchMoreEpic,
  commentDeleteEpic
} from "./portal/comments/model/CommentsEpic";

import {
  schedulFetchByIdEpic,
  scheduleFetchEpic,
  scheduleByArtistFetchEpic,
  scheduleByArtistMoreFetchEpic,
  scheduleDeleteEpic,
  scheduleCreateEpic,
  scheduleUpdateEpic,
  scheduleCreateWeeklyEpic,
  scheduleUpdateWeeklyEpic,
  scheduleFetchInitialEpic,
  schedulePlaylistsFetchEpic,
  scheduleFetchInitialScheduleListEpic,
  scheduleSearchByPodcastEpic,
  scheduleFetchAudioFilesEpic,
  scheduleFetchCoverImageEpic,
  scheduleFetchSignedUrlEpic
} from "./portal/schedule/model/ScheduleEpic";

import {
  tagsFetchEpic,
  tagsAssociationFetchEpic,
  removeTagEpic
} from "./portal/tags/model/TagsEpic";

import {
  usersInitialFetchEpic,
  usersFetchEpic
} from "./portal/users/model/UsersEpic";

export const rootEpic = combineEpics(
  rootLogoutEpic,
  loginEpic,
  artistFetchEpic,
  artistsAndTagsFetchEpic,
  artistsCreateEpic,
  artistsUpdateEpic,
  artistCoverImageEpic,
  artistSignedUrlEpic,
  categoriesFetchEpic,
  categoryDeleteEpic,
  categoryCreateEpic,
  categoryUpdateEpic,
  categoriesFetchCoverImageEpic,
  categoriesFetchCoverImageSignedUrlEpic,
  commentsFetchEpic,
  commentsFetchMoreEpic,
  commentDeleteEpic,
  schedulFetchByIdEpic,
  scheduleFetchEpic,
  scheduleByArtistFetchEpic,
  scheduleByArtistMoreFetchEpic,
  scheduleDeleteEpic,
  scheduleCreateEpic,
  scheduleUpdateEpic,
  scheduleCreateWeeklyEpic,
  scheduleUpdateWeeklyEpic,
  schedulePlaylistsFetchEpic,
  scheduleFetchInitialEpic,
  scheduleFetchInitialScheduleListEpic,
  scheduleSearchByPodcastEpic,
  scheduleFetchAudioFilesEpic,
  scheduleFetchCoverImageEpic,
  tagsFetchEpic,
  tagsAssociationFetchEpic,
  removeTagEpic,
  usersInitialFetchEpic,
  usersFetchEpic,
  scheduleFetchSignedUrlEpic
);