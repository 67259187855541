import React, {
  memo, useState
  ,
  useEffect
} from "react";
import {
  ButtonBase,
  isWidthDown,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {
  Optional
} from "../../../../shared/Optional";
import {
  ProgressOrError
} from "../../../../uikit/progress/ProgressOrError";
import unwrapValue from "../../../../shared/UnwrapValue";
import {
  Asset
} from "@rewindit/domain";

import {
  Breakpoint
} from "@material-ui/core/styles/createBreakpoints";
import {
  FixedSizeGrid as List,
  areEqual,
  GridChildComponentProps
} from "react-window";
import memoize from "memoize-one";
import {
  InfoDiv
} from "../../../../uikit/div/InfoDiv";
import {
  encodeCloudFrontUrl
} from "../../../../utils/CloudFront";

interface CoverImageGridViewProps {
  groupName: string;
  assets: Optional<Asset[]>;
  error: Optional<string>;
  isLoading: boolean;
  itemSelected: (url: Asset) => void;
  loadAssets: (name: string) => void;
}

interface RowData {
  itemSelected: (url: Asset) => void;
  items: Asset[];
}

const COLUMN_COUNT = 4;

// eslint-disable-next-line react/display-name
const Cell = memo((props: GridChildComponentProps) => {
  const rowItem = props.data as RowData
  const itemIndex = props.rowIndex * COLUMN_COUNT + props.columnIndex;
  if (itemIndex > rowItem.items.length - 1) return <></>;
  const item = rowItem.items[itemIndex];
  return (
    <div key={item.url} style={props.style}>
      <ButtonBase
        style={{
          margin: 4
        }}
        onClick={() => {
          rowItem.itemSelected(item);
        }}
      >
        <img
          src={encodeCloudFrontUrl(item.url, 188)}
          alt={item.name}
          style={{
            margin: "auto",
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%"
          }}
        />
      </ButtonBase>
    </div>
  );
}, areEqual);

const widthValue = (width: Breakpoint) => {
  if (isWidthDown("sm", width)) {
    return 580;
  } else if (isWidthDown("md", width)) {
    return 672;
  }
  return 820;
};

const createItemData = memoize((items, itemSelected) => ({
  items,
  itemSelected
}));

const CoverImageGridView = (props: CoverImageGridViewProps & WithWidth) => {
  const {
    assets, loadAssets, groupName
  } = props;

  const [currentGroupName, setCurrentGroupName] = useState<Optional<string>>(undefined);

  useEffect(() => {
    if (assets === undefined || groupName !== currentGroupName) {
      loadAssets(groupName);
      setCurrentGroupName(groupName);
    }
  }, [assets, loadAssets, currentGroupName, setCurrentGroupName, groupName]);

  return (
    <ProgressOrError
      isLoading={props.isLoading}
      error={props.error}
      retryClicked={() => {
        if (currentGroupName !== undefined) {
          loadAssets(currentGroupName);
        }
      }}
    >
      {unwrapValue((assets) => {
        if (assets.length === 0)
          return (
            <div style={{
              marginTop: 16
            }}>
              <InfoDiv
                visible={true}
                title="Empty folder"
                body="We could not find any cover images for this artist, use the Upload tab to add content."
              />
            </div>
          );

        const rowData: RowData = createItemData(
          props.assets,
          props.itemSelected
        );

        const rowCount = Math.ceil(assets.length / COLUMN_COUNT);

        return (
          <List
            columnCount={COLUMN_COUNT}
            columnWidth={192}
            height={420}
            rowCount={rowCount}
            rowHeight={192}
            itemData={rowData}
            width={widthValue(props.width)}
          >
            {Cell}
          </List>
        );
      }, props.assets)}
    </ProgressOrError>
  );
};

export default withWidth()(CoverImageGridView);
