import React from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import {
  asTimestamp
} from "../../date/DateTimeFormatter";
import UTCUtils from "../../date/UTCUtils";

interface DatePickerViewProps {
  label: string;
  value?: number | Date;
  required?: boolean;
  disabled?: boolean;
  useUtc?: boolean;
  onValueChanged: (timestamp: number) => void;
}

const DatePickerView = (props: DatePickerViewProps) => {
  const utils = props.useUtc ? UTCUtils : DateFnsUtils;
  return (
    <MuiPickersUtilsProvider utils={utils}>
      <KeyboardDatePicker
        disableToolbar={props.useUtc}
        fullWidth
        autoOk
        variant="inline"
        inputVariant="outlined"
        label={props.label}
        format={props.useUtc ? "DD/MM/yyyy" : "dd/MM/yyyy"}
        value={props.value ?? ""}
        onChange={(v) => props.onValueChanged(asTimestamp(v || ""))}
        disabled={props.disabled}
        InputLabelProps={{
          shrink: true
        }}
        required={props.required}
        color="secondary"
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerView;
