import React from "react";
import {
  FormControl, InputLabel, OutlinedInput
} from "@material-ui/core";

interface AudioFileDropdownProps {
  hidden?: boolean;
  label: string;
  name: string;
  value?: string;
  onClick: () => void;
}

const AudioFileDropdown = (props: AudioFileDropdownProps) => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel.current != null) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);
  return (
    <FormControl
      hidden={props.hidden}
      variant="outlined"
      fullWidth
      color="secondary"
    >
      <InputLabel shrink ref={inputLabel} id={props.label}>
        {props.label + " *"}
      </InputLabel>
      <OutlinedInput
        style={{
          color: "#000000"
        }}
        notched
        labelWidth={labelWidth}
        value={props.value ? decodeURIComponent(props.value) : undefined}
        onClick={props.onClick}
      />
    </FormControl>
  );
};

export default AudioFileDropdown;
