import React from "react";
import {
  Selected
} from "../shared/Events";
import {
  Box, Typography
} from "@material-ui/core";
import {
  PrimaryButton
} from "./button/PrimaryButton";

export interface ErrorCardProps {
  error: string;
  retryClicked: Selected;
}

const ErrorCard = (props: ErrorCardProps) => {
  return (
    <Box style={{
      marginTop: 12, padding: 12, textAlign: "center"
    }}>
      <Typography variant="h5">Oops</Typography>
      <Typography variant="body1" style={{
        marginTop: 4
      }}>
        {props.error}
      </Typography>
      <PrimaryButton extraStyle={{
        marginTop: 8
      }} onClick={props.retryClicked}>
        Retry
      </PrimaryButton>
    </Box>
  );
};

export {
  ErrorCard
};
